import { current } from "@reduxjs/toolkit";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import StatusDropdown from "./components/statusdropdown";
import UserSelectModal from "../Notification/components/userselectmodal";
import UserService from "src/Services/API/user.service";
import { handleError, getUserNameArray, limitString } from "src/Common/function";
import { formatTime } from "src/Common/common";
import NotificationService from "src/Services/API/notification.service";
import Technicians from "./components/technicians";
import DayOfWeekDropdown from "./components/dayofweekdropdown";
import TimePickerModal from "src/Common/timepickermodal";
import DayDropdown from "./components/daydropdown";

const OpenWorkOrders = ({
  userArray,
  recipientIdList,
  setRecipientIdList,
  past7Days,
  setPast7Days,
  dayOfWeek,
  setDayOfWeek,
  timeForDayOfWeek,
  setTimeForDayOfWeek,
  past30Days,
  setPast30Days,
  day,
  setDay,
  timeForDay,
  setTimeForDay,
}) => {
  const [userSelectModalShown, setUserSelectModalShown] = useState(false);
  const [timePickerModalShown, setTimePickerModalShown] = useState(false);
  const [subType, setSubType] = useState(0);
  const [technicianNameList, setTechnicianNameList] = useState([]);

  const saveReport = (users) => {
    let temp = [];
    for (let user of users) {
      temp.push(user.id);
    }
    let recipientList = temp.length > 0 ? temp.join() : null;
    setRecipientIdList(recipientList);
    setTechnicianNameList(getUserNameArray(recipientList, userArray));
    setUserSelectModalShown(false);
  };

  const getSelectedDate = () => {
    return subType === 0 ? timeForDayOfWeek : timeForDay;
  };

  return (
    <div className="w-full">
      <UserSelectModal
        shown={userSelectModalShown}
        userArray={userArray}
        selectedUserIdArray={recipientIdList}
        onCancel={() => setUserSelectModalShown(false)}
        onSave={(userArray) => saveReport(userArray)}
      />
      <TimePickerModal
        shown={timePickerModalShown}
        cancelClicked={() => setTimePickerModalShown(false)}
        pSelectedDate={getSelectedDate()}
        dateSelected={(date) => {
          if (subType === 0) {
            setTimeForDayOfWeek(date);
          } else if (subType === 1) {
            setTimeForDay(date);
          }
        }}
      />

      {/* <p className="font-OpenSansBold text-[15px] text-black mt-2 mb-3">Email Report</p> */}
      <div className="border-gray-100 border-[1px] rounded-md flex flex-col items-center justify-between w-[500px] gap-3 p-3">
        <div className="w-full flex flex-col gap-3">
          <div className="font-OpenSansBold text-[16px] text-black h-[30px] flex items-center">Open Work Orders</div>
        </div>

        <div className="w-full flex flex-col gap-3">
          <Technicians
            recipients={technicianNameList}
            onClick={() => {
              setUserSelectModalShown(true);
            }}
          />
        </div>
        <div className="w-full flex items-center gap-2 h-[26px]">
          <input
            type="checkbox"
            value={past7Days}
            onChange={(event) => {
              setPast7Days(event.target.checked);
            }}
          />
          <p className="font-OpenSansMedium text-[13px] text-black w-[80px]">Past 7 days</p>

          {past7Days && (
            <div className="flex items-center ml-7 gap-3 ">
              <p className="font-OpenSansBold text-[13px] text-black w-[90px]">Day of week:</p>
              <DayOfWeekDropdown dayOfWeek={dayOfWeek} setDayOfWeek={setDayOfWeek} />
              <p className="font-OpenSansBold text-[13px] text-black">Time:</p>
              <p
                onClick={() => {
                  setSubType(0);
                  setTimePickerModalShown(true);
                }}
                className="font-OpenSansMedium text-[13px] text-black cursor-pointer"
              >
                {formatTime(timeForDayOfWeek.toString())}
              </p>
            </div>
          )}
        </div>

        <div className="w-full flex items-center gap-2 h-[26px]">
          <input type="checkbox" value={past30Days} onChange={(event) => setPast30Days(event.target.checked)} />
          <p className="font-OpenSansMedium text-[13px] text-black w-[80px] ">Past 30 days</p>

          {past30Days && (
            <div className="flex items-center ml-7 gap-3 ">
              <p className="font-OpenSansBold text-[13px] text-black w-[90px]">Day:</p>
              <DayDropdown day={day} setDay={setDay} />
              <p className="font-OpenSansBold text-[13px] text-black">Time:</p>
              <p
                onClick={() => {
                  setSubType(1);
                  setTimePickerModalShown(true);
                }}
                className="font-OpenSansMedium text-[13px] text-black cursor-pointer"
              >
                {formatTime(timeForDay.toString())}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OpenWorkOrders;
