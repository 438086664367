import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import BlueButton from "src/Components/Common/bluebutton";
import CancelButton from "src/Components/Common/cancelbutton";
import { getImageUrl, getAvatarUrl } from "src/Common/common";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const DurationPickerModal = ({ shown, initialText, onClose, onSave }) => {
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");

  useEffect(() => {
    setHours(initialText.split(":")[0]);
    setMinutes(initialText.split(":")[1]);
  }, [initialText]);

  const handleHoursChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setHours(e.target.value);
    }
  };

  const handleMinutesChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMinutes(e.target.value);
    }
  };

  const handleSave = () => {
    let hourString = null;
    if (hours.length === 0) {
      hourString = "00";
    } else if (hours.length === 1) {
      hourString = "0" + hours;
    } else {
      hourString = hours;
    }

    let minuteString = null;
    if (minutes.length === 0) {
      minuteString = "00";
    } else if (minutes.length === 1) {
      minuteString = "0" + minutes;
    } else {
      minuteString = minutes;
    }

    const duration = `${hourString}:${minuteString}`;
    onSave(duration);
  };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <div className="bg-white rounded-md w-[250px] pt-7 pb-3 px-3 relative">
          <p className="font-OpenSansRegular text-[18px] font-[700] text-titleColor text-center">
            SELECT DURATION
          </p>

          <div className="flex items-center justify-center mt-5">
            <div className="w-[50px] border-[1px] border-borderColor flex items-center justify-center">
              <input
                value={hours}
                type="text"
                onChange={handleHoursChange}
                className="w-[30px] text-titleColor text-[20px] font-[700] font-OpenSansMedium"
                maxLength="2"
              />
            </div>
            <p className="font-OpenSansRegular text-[20px] font-[700] text-titleColor text-center">
              :
            </p>

            <div className="w-[50px] border-[1px] border-borderColor flex items-center justify-center">
              <input
                value={minutes}
                type="text"
                onChange={handleMinutesChange}
                className="w-[30px] text-titleColor text-[20px] font-[700] font-OpenSansMedium"
                maxLength="2"
              />
            </div>
          </div>
          <div className="w-full flex items-center justify-center mt-5 gap-2">
            <CancelButton onClicked={onClose} title="CANCEL" />
            <BlueButton onClicked={handleSave} title="SAVE" />
          </div>
        </div>
      </div>
    )
  );
};

export default DurationPickerModal;
