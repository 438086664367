import React, { useState, useEffect } from "react";
import { getImageUrl } from "src/Common/common";

function GoogleButton({ title, onButtonClicked }) {
  const [email, setEmail] = useState("");

  useEffect(() => {}, []);

  const onClicked = (e) => {
    onButtonClicked();
  };

  return (
    <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
      <div className="flex items-center justify-center gap-[5px]">
        <img src={getImageUrl("/images/common/google_icon.png")} className="w-[18px] h-[18px]" />
        <span className="text-[#404A6E] text-[10px] text-OpenSansRegular font-[600]">{title}</span>
      </div>
    </div>
  );
}

export default GoogleButton;
