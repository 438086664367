import { useEffect, useState } from "react";
import { limitString } from "src/Common/function";

const Receivers = ({ woRecipients, poRecipients, setUserSelectModalShown, setFlow }) => {
  return (
    <>
      <p className="font-OpenSansMedium text-[13px] text-black w-[30%]">Receivers</p>
      <div
        onClick={() => {
          setUserSelectModalShown(true);
          setFlow(0);
        }}
        className="h-[30px] border-[1px] rounded-md border-gray-200 cursor-pointer flex items-center px-2"
      >
        <p className="font-OpenSansMedium text-[13px] text-black">{limitString(woRecipients, 120)}</p>
      </div>
      <div
        onClick={() => {
          setUserSelectModalShown(true);
          setFlow(1);
        }}
        className="h-[30px] border-[1px] rounded-md border-gray-200 cursor-pointer flex items-center px-2"
      >
        <p className="font-OpenSansMedium text-[13px] text-black">{limitString(poRecipients, 120)}</p>
      </div>
    </>
  );
};

export default Receivers;
