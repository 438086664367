import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import TextInput from "src/Components/Authentication/TextInput";
import PasswordInput from "src/Components/Authentication/PasswordInput";
import ConfirmPasswordInput from "src/Components/Authentication/ConfirmPasswordInput";
import BlueButton from "src/Components/Authentication/BlueButton";
import { validatePassword, API_URL } from "src/Common/function";
import InviteService from "src/Services/API/invite.service";

function SetPassword() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let token = searchParams.get("token");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const blueButtonClicked = () => {
    if (!validatePassword(password)) {
      toast("Please enter strong password");
      return;
    }
    if (password !== confirmPassword) {
      toast("Passwords doesn't match");
      return;
    }

    setIsLoading(true);
    InviteService.accept({ token, password, name })
      .then(function (response) {
        console.log("response: ", response);
        if (response.status == 200) {
          toast("Password has been set");
          navigate("/");
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        toast("An error occurred");
        setIsLoading(false);
      });
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="w-[332px] h-[411px] flex items-center justify-center">
        <div className="w-[296px] h-[400px]">
          <p className="font-OpenSansRegular text-[16px] text-titleColor text-center font-[700]">Set Password</p>
          <p className="font-OpenSansRegular text-[12px] text-subTitleColor text-center font-[600] mt-[5px]">
            Fill in your name and password.
          </p>
          <div className="mt-[20px]" />
          <TextInput onTextChanged={setName} />
          <PasswordInput onPasswordChanged={setPassword} isForgotButton={false} />
          <ConfirmPasswordInput onConfirmpasswordChanged={setConfirmPassword} />
          <BlueButton onButtonClicked={blueButtonClicked} title={"SET PASSWORD"} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
}

export default SetPassword;
