import { current } from "@reduxjs/toolkit";
import api from "./api";
import UserService from "./user.service";
import { useSelector } from "react-redux";
import { isAdmin } from "src/Common/function";

const getAll = (branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branch.id;
  }
  return api.get("/api/customer/get_all", { params: { branchId } });
};

const saveCustomer = (customer, branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branch.id;
  }
  customer["branch"] = branchId;
  return api.post("/api/customer/add_customer", customer);
};

const deleteCustomer = (id) => {
  return api.post("/api/customer/delete_customer", { customerId: id });
};

const updateCustomer = (customer) => {
  return api.post("/api/customer/update_customer", { ...customer });
};

const CustomerService = {
  getAll,
  saveCustomer,
  deleteCustomer,
  updateCustomer,
};

export default CustomerService;
