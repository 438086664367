import React, { useState, useEffect } from "react";
import axios from "axios";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { getImageUrl } from "src/Common/common";
import { paymentItems } from "src/Common/contants";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const PaymentDropdown = ({ itemClicked, defaultValue }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(defaultValue);

  const itemSelected = (index) => {
    itemClicked(index);
    setSelectedIndex(index);
    setDropdownOpen(false);
  };

  useEffect(() => {
    setSelectedIndex(defaultValue);
  }, [defaultValue]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setDropdownOpen(false);
      }}
    >
      <div className="w-ful h-[30px] border-solid border-[1px] rounded-[6px] border-borderColor relative">
        <div
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
          className="w-full h-full flex items-center justify-between cursor-pointer"
        >
          <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor  px-3 ">{paymentItems[selectedIndex]}</p>
          <img src={getImageUrl("/images/common/arrow-down.png")} className="w-[24px]" />
        </div>
        {dropdownOpen && (
          <div className="w-full absolute top-[30px] border-solid border-[1px] rounded-[6px] border-borderColor bg-white">
            {paymentItems.map((item, index) => {
              return (
                <div key={index}>
                  <div className="h-[25px] px-[6px] cursor-pointer" onClick={() => itemSelected(index)}>
                    <span className="text-[12px] font-OpenSansMedium font-[600] text-titleColor">{item}</span>
                  </div>
                  {index < paymentItems.length - 1 && <div className="h-[1px] w-full bg-borderColor" />}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default PaymentDropdown;
