// import moment from "moment";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { FaDownload } from "react-icons/fa";
import Viewer from "react-viewer";
import {
  formatDate,
  getAvatarUrl,
  getImageUrl,
  getPOReceiptUrl,
  getRealFileName,
  getServiceTicketUrl,
  getUploadUrl,
  UtcToMst,
} from "src/Common/common";
import { paymentItems } from "src/Common/contants";
import { downloadImage, downloadPdf, handleError } from "src/Common/function";
import Button from "src/Components/Button";
import ApproveRequestButton from "src/Components/Common/approverequestbutton";
import BlueButton from "src/Components/Common/bluebutton";
import DenyRequestButton from "src/Components/Common/denyrequestbutton";
import PencilButton from "src/Components/Common/pencilbutton";
import WhiteUploadButton from "src/Components/Common/whiteuploadbutton";
import CreateButton from "src/Components/CreateButton";
import { setWONumber } from "src/Redux/Slices/HeaderSlice";
import POService from "src/Services/API/po.service";
import TechnicianService from "src/Services/API/technician.service";
import UserService from "src/Services/API/user.service";
import WOService from "src/Services/API/wo.service";
import POStatusComponent from "../PO/Components/Status/postatuscomponent";
import AssignTechModal from "./Components/AssignTech/assigntechmodal";
import ScheduleModal from "./Components/ScheduleModal";
import WOStatusComponent from "./Components/Status/wostatuscomponent";
import QuotedType from "./Components/Types/quotedtype";
import ServiceCallType from "./Components/Types/servicecalltype";
import BilledDateModal from "./Components/billeddatemodal";
import CreateModal from "./Components/createmodal";
import CreatePOModal from "./Components/createpomodal/createpomodal";
import EditButton from "./Components/editbutton";
import History from "./Components/history";
import PreviewSTModal from "./Components/previewstmodal";
import BlueServiceTicketButton from "./Components/serviceticketbuttons/blueserviceticketbutton";
import ServiceTicketButton from "./Components/serviceticketbuttons/serviceticketbutton";
import PDFPreviewModal from "./Components/pdfpreview";
import { current } from "@reduxjs/toolkit";

function WODetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentUser = UserService.getCurrentUser();
  const [order, setOrder] = useState(location.state.order);
  const [editingPO, setEditingPO] = useState(null);
  const [isAssignTechModalShown, setIsAssignTechModalShown] = useState(false);
  const inputFile = useRef(null);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [cratePOModalShown, setCratePOModalShown] = useState(false);
  const [stPreviewModalShown, setSTPreviewModalShown] = useState(false);
  const [stPreviewLoading, setStPreviewLoading] = useState(false);
  const [isWoCreateOpen, setIsWoCreateOpen] = useState(false);
  //billed date
  // const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [billedDateModalShown, setBilledDateModalShown] = useState(false);

  //image preview
  const [isImageViewerShown, setIsImageViewerShown] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [previewImageActiveIndex, setPreviewImageActiveIndex] = useState(0);

  //pdf preview
  const [isPDFViewerShown, setIsPDFViewerShown] = useState(false);
  const [previewPDFs, setPreviewPDFs] = useState([]);
  const [previewPDFActiveIndex, setPreviewPDFActiveIndex] = useState(0);

  useEffect(() => {
    WOService.getWorkOrderById(location.state.order.id)
      .then((response) => {
        if (response.status == 200) {
          console.log("🚀 ~ .then ~ response.status:", response.data);
          setOrder(response.data);
          dispatch(setWONumber(String(response.data.number)));
        }
      })
      .catch((error) => handleError(error));
  }, []);

  const approveRequest = () => {
    WOService.update({
      id: order.id,
      data: {
        status: 1,
        openDate: new Date(),
        openUser: currentUser,
        openDate: new Date(),
      },
    })
      .then((response) => {
        console.log("approveRequest: ", response.data);
        if (response.status == 201) {
          setOrder(response.data);
          toast("Work order approved successfully");
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const denyRequest = () => {};

  const saveAssigns = async (dataParam) => {
    let data = dataParam.filter((item) => item.user !== -1 && item.roleId !== -1);
    setIsAssignTechModalShown(false);

    if (order.assignedTechs.length > 0) {
      let alreadyAssignedTechs = [];
      for (let item of order.assignedTechs) {
        alreadyAssignedTechs.push({ user: item.user.id, roleId: item.roleId });
      }
      console.log("Old Assigned Techs: ", alreadyAssignedTechs);
      console.log("New Assigned Techs: ", data);

      let newTechArray = [],
        removedTechArray = [],
        updatedTechArray = [];
      for (let item of data) {
        if (!alreadyAssignedTechs.some((obj) => obj.user === item.user)) {
          newTechArray.push(item);
        }
        if (alreadyAssignedTechs.some((obj) => obj.user === item.user && obj.roleId !== item.roleId)) {
          updatedTechArray.push(item);
        }
      }

      for (let item of alreadyAssignedTechs) {
        if (!data.some((obj) => obj.user === item.user)) {
          removedTechArray.push(item);
        }
      }

      await TechnicianService.createTechnician({
        woId: order.id,
        data: newTechArray,
        eventUser: currentUser,
      });
      await TechnicianService.deleteTechnician({
        woId: order.id,
        data: removedTechArray,
        eventUser: currentUser,
      });
      await TechnicianService.updateBulkTechnician({
        woId: order.id,
        data: updatedTechArray,
      });
    } else {
      TechnicianService.createTechnician({ woId: order.id, data, eventUser: currentUser })
        .then((response) => {
          console.log("response: ", response);
          if (response.status == 201) {
            console.log("new order: ", {
              ...order,
              assignedTechs: response.data,
            });
            setOrder({ ...order, assignedTechs: response.data });
            toast("Techs assigned successfully");
          } else {
            toast("An error occured");
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
    getWODetail(order.id);
  };

  const fileSelected = (e) => {
    const files = e.target.files;
    console.log("🚀 ~ fileSelected ~ files:", files);
    for (let file of files) {
      if (file.size > 167772160) {
        toast("File size cannot exceed more than 20MB");
        return;
      }
    }

    if (files) {
      WOService.uploadFiles(files, order.id)
        .then((response) => {
          if (response.status == 201) {
            toast("file uploaded successfully");
            setOrder(response.data);
          } else {
            toast("Something went wrong");
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const saveScheduledStartTargetDate = (startDate, targetDate) => {
    setIsScheduleModalOpen(false);
    if (!startDate && !targetDate) return;
    let data = {};
    if (startDate) {
      data["scheduledStartDate"] = startDate;
      // data["startDateForTimesheet"] = startDate.format("YYYY-MM-DD HH:mm:ss");
    }
    if (targetDate) {
      data["scheduledTargetDate"] = targetDate;
    }

    WOService.update({ id: order.id, data: data })
      .then((response) => {
        console.log("saveStartTargetDate: ", response);
        if (response.status == 201) {
          setOrder(response.data);
          toast("Date saved successfully");
        } else {
          toast("something went wrong!");
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const saveBilledDate = (billedDate, billedNote) => {
    setBilledDateModalShown(false);

    console.log("🚀 ~ saveBilledDate ~ typeof billedDate:", typeof billedDate);
    let billedData = order.billedData;
    if (billedData) {
      billedData.push({ date: moment(billedDate).format("MM/DD/YYYY"), note: billedNote });
    } else {
      billedData = [{ date: moment(billedDate).format("MM/DD/YYYY"), note: billedNote }];
    }

    WOService.update({ id: order.id, data: { billedData, eventUser: currentUser } })
      .then((response) => {
        console.log("saveBilledtDate: ", response);
        if (response.status == 201) {
          setOrder(response.data);
          toast("Billed date saved successfully");
        } else {
          toast("something went wrong!");
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const uploadClicked = () => {
    inputFile.current.click();
  };

  const navigatePODetail = (po) => {
    POService.getPurchaseOrdersByPoId(po.id)
      .then((response) => {
        if (response.status == 200) {
          navigate("/purchase/" + response.data.number, {
            state: { order: response.data },
          });
        }
      })
      .catch((error) => handleError(error));
  };

  const createPO = () => {
    setEditingPO(null);
    setCratePOModalShown(true);
  };

  const getWODetail = (id) => {
    WOService.getWorkOrderById(id)
      .then((response) => {
        console.log("save assigned techs: ", response);
        if (response.status == 200) {
          setOrder(response.data);
        }
      })
      .catch((error) => handleError(error));
  };

  const savePO = async (data) => {
    setCratePOModalShown(false);

    if (editingPO) {
      try {
        //upload receipts
        // for (let file of data.data.receipts) {
        //   const fileName = `PO receipt_${String(Math.floor(Date.now()))}.png`;
        //   const fileData = { id: data.id, name: fileName, base64: file.base64 };
        //   console.log(fileData);
        //   await POService.attachFile(fileData);
        // }

        //save data
        let response = await POService.update(data);
        if (response.status == 201) {
          toast("Purchase order updated successfully!");
          getWODetail(order.id);
        }
      } catch (error) {
        handleError(error);
      }
    } else {
      POService.savePurchaseOrder(data)
        .then((response) => {
          if (response.status == 201) {
            toast("Purchase order created successfully!");

            // if (order.pos.length === 0) {
            //   WOService.update({ id: order.id, data: { status: 4 } })
            //     .then((response) => {
            //       console.log("status update: ", response);
            //       if (response.status == 201) {
            //         setOrder(response.data);
            //       }
            //     })
            //     .catch((error) => {
            //       handleError(error);
            //     });
            // } else {
            WOService.getWorkOrderById(order.id)
              .then((response) => {
                console.log("response: ", response);
                if (response.status == 200) {
                  setOrder(response.data);
                }
              })
              .catch((error) => handleError(error));
            // }
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const showPreviewModal = () => {
    setStPreviewLoading(true);
    WOService.previewServiceTicket(order.id)
      .then((response) => {
        if (response.status == 200) {
          setSTPreviewModalShown(true);
          setStPreviewLoading(false);
        }
      })
      .catch((error) => {
        setStPreviewLoading(false);
        handleError(error);
      });

    WOService.attachMaterials({ id: order.id, data: {} })
      .then((response) => {
        console.log(response.status);
        if (response.status == 201) {
        }
      })
      .catch((error) => handleError(error));
  };

  const closeWorkOrder = () => {
    WOService.update({ id: order.id, data: { status: 100 } })
      .then((response) => {
        console.log("status update to CLOSE: ", response);
        if (response.status == 201) {
          setOrder(response.data);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleScheduleEdit = () => {
    setIsScheduleModalOpen(true);
  };

  const saveWO = (data) => {
    setIsWoCreateOpen(false);
    WOService.update({ id: order.id, data: { ...data, eventUser: currentUser } })
      .then((response) => {
        console.log("response: ", response);
        if (response.status == 201) {
          toast("Work order created successfully");
          setOrder(response.data);
          dispatch(setWONumber(String(response.data.number)));
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const poEditHandle = (po) => {
    console.log(po);
    setCratePOModalShown(true);
    setEditingPO(po);
  };

  const handleAssignedTechsEdit = () => {
    setIsAssignTechModalShown(true);
  };

  return (
    <div className="w-full px-5 ">
      <AssignTechModal
        shown={isAssignTechModalShown}
        onCancelClicked={() => setIsAssignTechModalShown(false)}
        onSaveClicked={saveAssigns}
        assignedTechs={order.assignedTechs}
        branch={order.branch}
      />
      <ScheduleModal
        shown={isScheduleModalOpen}
        pStartDate={order.scheduledStartDate}
        pTargetDate={order.scheduledTargetDate}
        onSaveClicked={saveScheduledStartTargetDate}
        onCancel={() => setIsScheduleModalOpen(false)}
      />
      <CreatePOModal
        shown={cratePOModalShown}
        onCancelClicked={() => setCratePOModalShown(false)}
        onSubmitClicked={savePO}
        wo={order}
        editingPO={editingPO}
      />
      <PreviewSTModal
        shown={stPreviewModalShown}
        pdfUrl={getServiceTicketUrl(order.number)}
        cancelClicked={() => setSTPreviewModalShown(false)}
      />
      <CreateModal
        order={order}
        onSubmitClicked={saveWO}
        shown={isWoCreateOpen}
        onCancelClicked={() => setIsWoCreateOpen(false)}
      />
      <Viewer
        visible={isImageViewerShown}
        onClose={() => {
          setIsImageViewerShown(false);
        }}
        images={previewImages}
        activeIndex={previewImageActiveIndex}
      />
      <PDFPreviewModal
        PDFs={previewPDFs}
        activeIndex={previewPDFActiveIndex}
        shown={isPDFViewerShown}
        onCancel={() => setIsPDFViewerShown(false)}
      />

      {/* <DatePickerModal
        shown={isDatePickerOpen}
        pSelectedDate={order.billedDate}
        cancelClicked={() => setIsDatePickerOpen(false)}
        timeVisible={false}
        dateSelected={(date) => {
          saveBilledDate(date);
        }}
      /> */}
      <BilledDateModal
        shown={billedDateModalShown}
        onCancel={() => setBilledDateModalShown(false)}
        pBilledDate={order.billedDate}
        pBilledNote={order.billedNote}
        onSaveClicked={(date, note) => {
          saveBilledDate(date, note);
        }}
      />
      <div className="w-full h-[56px] flex items-center justify-between ">
        <div className="flex items-center cursor-pointer" onClick={() => navigate(-1)}>
          <img src={getImageUrl("/images/common/back_arrow.png")} className="w-[24px]" />
          <span className="font-OpenSansRegular text-[14px] font-[700] text-subTitleColor">back</span>
        </div>
        <div className="flex items-center gap-3">
          <CreateButton title="CREATE PO" onClicked={createPO} />
          {order.status === 6 && <BlueButton title="CLOSE WORK ORDER" onClicked={closeWorkOrder} />}
        </div>
      </div>

      <div className="w-full border-solid border-[1px] border-borderColor">
        <div className="w-full h-[30px]  flex items-center px-3">
          <span className="font-OpenSansRegular font-[700] text-[16px] text-titleColor ">Customer Information</span>
        </div>
        <div className="w-full h-[50px] border-solid border-t-[1px] border-borderColor flex items-center justify-between px-3">
          <div className="h-[32px] flex flex-col gap-1">
            <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Company</p>
            <p className="font-OpenSansRegular  text-[10px] text-titleColor">{order.customer.company}</p>
          </div>
          <div className="h-[32px] flex flex-col gap-1">
            <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Email</p>
            <p className="font-OpenSansRegular text-[10px] text-titleColor">{order.customer.email}</p>
          </div>
          <div className="h-[32px] flex flex-col gap-1">
            <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Contact</p>
            <p className="font-OpenSansRegular text-[10px] text-titleColor">{order.customer.contact}</p>
          </div>
          <div className="h-[32px] flex flex-col gap-1">
            <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Phone Number</p>
            <p className="font-OpenSansRegular text-[10px] text-titleColor">{order.customer.phone}</p>
          </div>
          <div className="h-[32px] flex flex-col gap-1">
            <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Address</p>
            <p className="font-OpenSansRegular text-[10px] text-titleColor">{order.customer.address}</p>
          </div>
        </div>
      </div>

      <div className="w-full border-solid border-[1px] border-borderColor mt-3">
        <div className="w-full h-[30px]  flex items-center justify-between px-3">
          <span className="font-OpenSansRegular font-[700] text-[16px] text-titleColor ">Service Call</span>
          <WOStatusComponent value={order.status} />
        </div>
        <div className="w-full h-[50px] border-solid border-t-[1px] border-borderColor flex items-center justify-between px-3 gap-[150px]">
          <div className="w-full flex items-center justify-between">
            <div>{order.type == 0 ? <ServiceCallType /> : <QuotedType />}</div>
            <div className="flex items-center gap-1">
              <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Opened On:</span>
              <span className="font-OpenSansRegular text-[12px] text-titleColor">{formatDate(order.openDate)}</span>
            </div>

            <div className="flex items-center gap-1">
              <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Start date:</span>
              <span className="font-OpenSansRegular text-[12px] text-titleColor">
                {order.startDate && formatDate(order.startDate, "MM/DD/YYYY")}
              </span>
            </div>

            <div className="flex items-center gap-1">
              <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">NTE:</span>
              <span className="font-OpenSansRegular text-[12px] text-titleColor">${order.NTE}</span>
            </div>
            {order.status == 0 ? (
              <div className="flex items-center gap-1">
                <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Requested by:</span>
                <img
                  src={getAvatarUrl(order.requestedUser.avatar)}
                  className="w-[20px] rounded-[10px] cursor-pointer ml-2"
                />
                <span className="font-OpenSansRegular font-[400] text-[12px] text-subTitleColor ">
                  {order.requestedUser.name}
                </span>
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Issued by:</span>
                <img
                  src={getAvatarUrl(order.openUser?.avatar)}
                  className="w-[20px] rounded-[10px] cursor-pointer ml-2"
                  alt=""
                />
                <span className="font-OpenSansRegular font-[400] text-[12px] text-subTitleColor ">
                  {order.openUser?.name}
                </span>
              </div>
            )}
          </div>
          <div className=" flex items-center gap-2">
            <PencilButton clicked={() => setIsWoCreateOpen(true)} />

            {order.status >= 5 ? (
              <BlueServiceTicketButton clicked={() => navigate("/serviceticket", { state: { order: order } })} />
            ) : (
              <ServiceTicketButton clicked={showPreviewModal} isLoading={stPreviewLoading} />
            )}
          </div>
        </div>
        <div className="w-full h-[200px] flex items-center justify-between px-3 py-3">
          <div className="w-[35%] h-full">
            <div className="flex items-center gap-2 mb-2">
              <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Asset:</p>
              <p className="font-OpenSansReg ular text-[12px] text-titleColor break-all">{order.asset}</p>
            </div>
            <div className="flex items-center gap-2 mb-2">
              <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Customer Purchase Order #:</p>
              <p className="font-OpenSansReg ular text-[12px] text-titleColor break-all">{order.customerPONumber}</p>
            </div>
            <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Description</p>
            <p className="font-OpenSansReg ular text-[12px] text-titleColor break-all">{order.description}</p>
          </div>
          {order.status >= 1 && (
            <div className="w-[65%] h-full flex items-center justify-around relative">
              <div className="w-[155px] h-full">
                <div className="w-full flex items-center justify-between mb-1">
                  <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor ">Schedule</p>
                  {(order.scheduledStartDate || order.scheduledTargetDate) && (
                    <EditButton clicked={handleScheduleEdit} />
                  )}
                </div>

                {order.scheduledStartDate || order.scheduledTargetDate ? (
                  <>
                    <div className="w-full h-[25px] flex items-center gap-1">
                      <img src={getImageUrl("/images/wo/date.png")} />
                      {order.scheduledStartDate && (
                        <p className="text-[12px] font-OpenSansMedium font-[400] text-titleColor">
                          {formatDate(order.scheduledStartDate)}
                        </p>
                      )}
                    </div>

                    <div className="w-full h-[25px] flex items-center gap-1">
                      <img src={getImageUrl("/images/wo/date-target.png")} />
                      {order.scheduledTargetDate && (
                        <p className="text-[12px] font-OpenSansMedium font-[400] text-titleColor">
                          {formatDate(order.scheduledTargetDate)}
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    onClick={() => setIsScheduleModalOpen(true)}
                    className="cursor-pointer h-[30px] w-[100px] rounded-[6px] border-solid border-borderColor border-[1px] flex items-center justify-center"
                  >
                    <span className="font-OpenSansRegular font-[600] text-[10px] text-[#404A6E]">SCHEDULE</span>
                  </div>
                )}

                <div className="w-full flex items-center justify-between mb-3 mt-3">
                  <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor ">Date Billed</p>
                  {/* {order.billedDate && <EditButton clicked={() => setBilledDateModalShown(true)} />} */}
                  <div className="w-[25px]">
                    <Button onClicked={() => setBilledDateModalShown(true)} title="+" />
                  </div>
                </div>
                <div className="w-full flex-1">
                  {order.billedData &&
                    order.billedData.map((item, index) => (
                      <div className="w-[600px] h-[20px] flex gap-1 items-center" key={index}>
                        <div className="flex items-center gap-1">
                          <img src={getImageUrl("/images/wo/date.png")} />
                          <p className="text-[12px] font-OpenSansMedium font-[400] text-titleColor">{item.date}</p>
                        </div>
                        <p className="text-[12px] font-OpenSansMedium font-[400] text-titleColor">NOTE: {item.note}</p>
                      </div>
                    ))}
                </div>
              </div>
              <div className="w-[170px] h-full flex flex-col">
                <div className="w-full flex items-center justify-between mb-3">
                  <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Assigned To:</p>
                  {order.assignedTechs?.length > 0 && <EditButton clicked={handleAssignedTechsEdit} />}
                </div>
                {order.assignedTechs?.length > 0 ? (
                  <div className="w-full wo_detail_custom_height overflow-auto">
                    {order.assignedTechs.map((tech, index) => {
                      return (
                        <div className="w-full h-[25px] flex items-center gap-2" key={index}>
                          <img src={getAvatarUrl(tech.user.avatar)} className="w-[16px] h-[16px] rounded-[8px]" />
                          <span className="font-OpenSansRegular font-[400] text-[11px] text-titleColor">
                            {tech.user.name}
                          </span>
                          {tech.status === 1 ? (
                            <img src={getImageUrl("/images/common/check.png")} className="w-[20px] h-[20px]" />
                          ) : tech.status === 2 ? (
                            <img src={getImageUrl("/images/common/red_close.png")} className="w-[20px] h-[20px]" />
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    onClick={() => setIsAssignTechModalShown(true)}
                    className="cursor-pointer w-[100px] h-[30px] rounded-[6px] border-solid border-borderColor border-[1px] flex items-center justify-center"
                  >
                    <span className="font-OpenSansRegular font-[600] text-[10px] text-[#404A6E]">ASSIGN</span>
                  </div>
                )}
              </div>
              <div className="w-[400px] h-full flex flex-col">
                <input
                  onChange={fileSelected}
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  multiple={true}
                  accept="image/*,application/pdf"
                />
                <div className="w-full flex items-center justify-between h-[30px]">
                  <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Attachments:</p>
                  {order.attachments && (
                    <div className="w-[25px]">
                      <Button onClicked={uploadClicked} title="+" />
                    </div>
                  )}
                </div>
                {order.attachments ? (
                  <div className="w-full flex-auto overflow-y-auto ">
                    {order.attachments.map((file, index) => {
                      return (
                        <div className="w-full h-[25px] flex items-center gap-3 " key={index}>
                          <span
                            onClick={() => {
                              if (file.split(".").pop().toLowerCase() === "pdf") {
                                let temp = [];
                                for (let attachment of order.attachments) {
                                  if (attachment.split(".").pop().toLowerCase() === "pdf") {
                                    temp.push(attachment);
                                    if (attachment === file) {
                                      setPreviewPDFActiveIndex(temp.length - 1);
                                    }
                                  }
                                }
                                setPreviewPDFs(temp);
                                setIsPDFViewerShown(true);
                              } else {
                                let temp = [];
                                for (let attachment of order.attachments) {
                                  if (attachment.split(".").pop().toLowerCase() !== "pdf") {
                                    temp.push({ src: getUploadUrl(attachment), alt: "" });
                                    if (attachment === file) {
                                      setPreviewImageActiveIndex(temp.length - 1);
                                    }
                                  }
                                }
                                setPreviewImages(temp);
                                setIsImageViewerShown(true);
                              }
                            }}
                            className="font-OpenSansRegular font-[400] text-[11px] text-titleColor cursor-pointer"
                          >
                            {getRealFileName(file)}
                          </span>
                          <div
                            onClick={() => {
                              if (file.split(".").pop().toLowerCase() === "pdf") {
                                downloadPdf(getUploadUrl(file), getRealFileName(file));
                              } else {
                                downloadImage(getUploadUrl(file), getRealFileName(file));
                              }
                            }}
                            className="cursor-pointer"
                          >
                            <FaDownload color="#010D3D" size={13} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <WhiteUploadButton clicked={uploadClicked} />
                )}
              </div>
              {/* <div className="absolute right-2 bottom-1 flex items-center gap-2">
                <PencilButton clicked={() => setIsWoCreateOpen(true)} />

                {order.status >= 5 ? (
                  <BlueServiceTicketButton clicked={() => navigate("/serviceticket", { state: { order: order } })} />
                ) : (
                  <ServiceTicketButton clicked={showPreviewModal} isLoading={stPreviewLoading} />
                )}
              </div> */}
            </div>
          )}
          {order.status == 0 && (currentUser.roles[0].id == 1 || currentUser.roles[0].id == 2) && (
            <div className="w-[50%] h-[100px] flex items-center justify-end">
              <div className="flex flex-col items-center gap-1">
                <ApproveRequestButton onClicked={approveRequest} />
                <DenyRequestButton onClicked={denyRequest} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex mt-3 justify-between">
        <div className="w-[49%] ">
          <div className="w-full border-solid border-[1px] border-borderColor">
            <div className="w-full h-[30px]  flex items-center px-3">
              <span className="font-OpenSansRegular font-[700] text-[16px] text-titleColor ">History</span>
            </div>
            <div className="w-full border-solid border-t-[1px] border-borderColor flex items-center justify-between px-3 py-3">
              {order.history && <History historyData={order.history} />}
              {/* <div className="w-full flex items-center justify-between"></div> */}
            </div>
          </div>
        </div>
        <div className="w-[49%] ">
          {/* <div className="w-full border-solid border-[1px] border-borderColor"> */}
          {/* <div className="w-full h-[30px]  flex items-center px-3">
              <span className="font-OpenSansRegular font-[700] text-[16px] text-titleColor ">Purchase Orders</span>
            </div> */}
          {order.pos.map((po, index) => (
            <div key={index} className="w-full border-solid border-[1px] border-borderColor mb-5">
              <div className="w-full h-[30px]  flex items-center justify-between px-3">
                <div className="flex items-center gap-2">
                  <span className="font-OpenSansRegular font-[700] text-[16px] text-titleColor ">Purchase Order:</span>
                  <span
                    onClick={() => navigatePODetail(po)}
                    className="font-OpenSansRegular font-[500] text-[16px] text-titleColor underline cursor-pointer"
                  >
                    {po.number}
                  </span>
                </div>
                <POStatusComponent value={po.status} />
              </div>
              <div className="w-full  border-solid border-t-[1px] border-borderColor flex flex-col items-center gap-3 px-3 py-3 relative">
                {currentUser.roles[0].id <= 3 && (
                  <div className="absolute top-[5px] right-[5px]">
                    <PencilButton clicked={() => poEditHandle(po)} />
                  </div>
                )}

                <div className="w-[150px] absolute top-0 right-[40px] h-full py-3 flex flex-col">
                  <p className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Attachments</p>
                  <div className="w-full flex-auto overflow-auto ">
                    {po.attachments &&
                      po.attachments.map((file, index) => {
                        return (
                          <div className="w-full h-[25px] flex items-center gap-3 " key={index}>
                            <span
                              onClick={() => {
                                let temp = [];
                                for (let attachment of po.attachments) {
                                  temp.push({ src: getPOReceiptUrl(attachment), alt: "" });
                                }
                                setPreviewImages(temp);
                                setPreviewImageActiveIndex(index);
                                setIsImageViewerShown(true);
                              }}
                              className="font-OpenSansRegular font-[400] text-[11px] text-titleColor cursor-pointer"
                            >
                              {getRealFileName(file)}
                            </span>
                            <div onClick={() => downloadImage(getPOReceiptUrl(file), file)} className="cursor-pointer">
                              <FaDownload color="#010D3D" size={13} />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="w-full flex items-center pr-[50px]">
                  <div className="flex items-center gap-1">
                    <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">PO#: </span>
                    <span className="font-OpenSansRegular text-[12px] text-titleColor">{po.number}</span>
                  </div>
                  <div className="flex items-center gap-1 ml-[30px]">
                    <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Type: </span>
                    <span className="font-OpenSansRegular text-[12px] text-titleColor">
                      {paymentItems[po.paymentType]}
                    </span>
                  </div>
                  {po.requestedUser && (
                    <div className="flex items-center gap-2 ml-[30px]">
                      <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Technician: </span>
                      <div className="w-full flex items-center gap-1">
                        <img src={getAvatarUrl(po.requestedUser.avatar)} className="w-[16px] h-[16px] rounded-[8px]" />
                        <span className="font-OpenSansRegular font-[400] text-[11px] text-titleColor">
                          {po.requestedUser.name}
                        </span>
                      </div>
                    </div>
                  )}

                  {/* <div className="flex items-center gap-1">
                    <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor">Attachments: </span>
                  </div> */}
                </div>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col  gap-1">
                    <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor text-left">
                      Description
                    </span>
                    <span className="font-OpenSansRegular text-[12px] text-titleColor break-all ">
                      {po.description}
                    </span>
                  </div>
                </div>

                <div className="w-full flex flex-col justify-between">
                  <div className="flex flex-col  gap-1">
                    <span className="font-OpenSansRegular font-[700] text-[12px] text-titleColor text-left">Items</span>
                    {po.poItems.map((item, index) => (
                      <div key={index} className="w-full flex items-center justify-between h-[30px]">
                        <span className="font-OpenSansRegular font-[400] text-[12px] text-titleColor">
                          {item.description}{" "}
                        </span>
                        {item.attachment && (
                          <a
                            href={getUploadUrl(item.attachment)}
                            target="_blank"
                            download={getRealFileName(item.attachment)}
                          >
                            <span className="font-OpenSansRegular font-[400] text-[11px] text-titleColor">
                              {getRealFileName(item.attachment)}
                            </span>
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default WODetail;
