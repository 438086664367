import React, { useState, useEffect } from "react";

function TextInput({ label = "Full name", onTextChanged }) {
  const [text, setText] = useState("");

  useEffect(() => {}, []);

  const textChanged = (e) => {
    onTextChanged(e.target.value);
    setText(e.target.value);
  };

  return (
    <div className="w-[296px] h-[64px]">
      <p className=" text-[12px] font-OpenSansMedium font-[600] text-inputEmailTitle">{label}</p>
      <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
        <input
          value={text}
          type="text"
          onChange={textChanged}
          className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
          placeholder="Enter name"
        />
      </div>
    </div>
  );
}

export default TextInput;
