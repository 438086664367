import { Outlet } from "react-router-dom";
import Header from "./header";
import TabBar from "src/Pages/Setting/tabbar";

const SettingsLayout = ({ title }) => {
  return (
    <div className="flex flex-col w-full h-full ">
      <TabBar />
      <div className="px-4">
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsLayout;
