import { useEffect, useRef, useState } from "react";

import POService from "src/Services/API/po.service";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { toast } from "react-toastify";
import { getImageUrl, getRealFileName } from "src/Common/common";
import { handleError } from "src/Common/function";
import WhiteUploadButton from "src/Components/Common/whiteuploadbutton";
import UserService from "src/Services/API/user.service";
import VendorService from "src/Services/API/vendor.service";
import WOService from "src/Services/API/wo.service";
import VendorDropDown from "../dropdown/vendordropdown";
import PaymentDropdown from "./paymentdropdown";
import { alertTitleClasses } from "@mui/material";

function CreatePOModal({ shown, onCancelClicked, onSubmitClicked, wo, editingPO }) {
  const currentUser = UserService.getCurrentUser();
  const [vendorList, setVendorList] = useState([]);
  //
  const [woNumber, setWONumber] = useState(wo.number);
  const [po, setPo] = useState(null);
  // const [poNumber, setPoNumber] = useState(null);
  const [vendor, setVendor] = useState("");
  const [paymentType, setPaymentType] = useState(0);
  const [description, setDescription] = useState("");
  const [items, setItems] = useState([]);
  const [itemIndex, setItemIndex] = useState(0);
  const itemInputFile = useRef(null);
  //uploading attachment
  // const receiptInputFile = useRef(null);
  // const [receipts, setReceipts] = useState([]);

  useEffect(() => {
    if (shown) {
      setVendorList([]);
      VendorService.getAll(wo.branch.id)
        .then((response) => {
          console.log("Vendor data: ", response);
          if (response.status == 200) {
            setTimeout(() => {
              setVendorList(response.data.map((obj) => obj.name));
            }, 1000);
          }
        })
        .catch((error) => {
          console.log("Fetching Vendor List Error: ", error);
        });
    }
  }, [shown]);

  useEffect(() => {
    if (editingPO) {
      console.log("Editing po: ", po);
      setPo(editingPO);
      // setPoNumber(editingPO.number);
      setDescription(editingPO.description);
      setVendor(editingPO.vendor);
      setPaymentType(editingPO.paymentType);
      // if (editingPO.attachments) {
      //   setReceipts(
      //     editingPO.attachments.map((attachment) => ({
      //       name: getRealFileName(attachment),
      //     }))
      //   );
      // } else {
      //   setReceipts([]);
      // }
    } else {
      setItems([]);
      // setPoNumber("");
      setDescription("");
      setVendor("");
    }
  }, [editingPO]);

  const submitClicked = async () => {
    let data;
    if (editingPO) {
      let response;
      try {
        response = await WOService.getWorkOrderByNumber(woNumber);
        if (response.data.length === 0) {
          toast("Invalid Work Order Number");
          return;
        }
      } catch (error) {
        handleError(error);
        return;
      }

      data = {
        id: po.id,
        data: {
          wo: response.data.id,
          // number: poNumber,
          vendor: vendor,
          description: description,
          paymentType: paymentType,
          // receipts: receipts.filter((obj) => obj.base64),
        },
        eventUser: currentUser,
      };
    } else {
      let filtered = items.filter((item) => item.description.length > 0);

      data = {
        po: {
          wo: wo.id,
          // number: poNumber,
          vendor: vendor,
          description: description,
          paymentType: paymentType,
          requestedUser: UserService.getCurrentUser().id,
          issuedUser: UserService.getCurrentUser().id,
          issuedDate: new Date(),
          status: 2,
          // receipts: receipts,
        },
        items: filtered,
        eventUser: currentUser,
      };
    }

    console.log("data: ", data);
    onSubmitClicked(data);
  };

  const paymentTypeClicked = (index) => {
    setPaymentType(index);
  };

  const addItem = () => {
    setItems([...items, { description: "", base64: "", name: "" }]);
  };

  const uploadClicked = (index) => {
    itemInputFile.current.click();
    setItemIndex(index);
  };

  const descriptionChanged = (e, index) => {
    const newText = e.target.value;
    let temp = [...items];
    temp[index].description = newText;
    setItems(temp);
  };

  const vendorSelected = (option) => {
    console.log("selected vendor: ", option);
    setVendor(option);
  };

  /*
  const uploadReceipt = () => {
    receiptInputFile.current.click();
  };

  const receiptSelected = (e) => {
    alert("");
    const file = e.target.files[0];
    if (file.size > 167772160) {
      toast("File size cannot exceed more than 20MB");
      return;
    }

    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     const base64 = reader.result.split(",")[1];
    //     let newFile = {};
    //     newFile.base64 = base64;
    //     newFile.name = file.name;
    //     newFile.type = file.type;
    //     setReceipts([...receipts, newFile]);
    //   };
    //   reader.readAsDataURL(file);
    // }

    if (file) {
      POService.uploadFile(file, po.id)
        .then((response) => {
          if (response.status == 201) {
            toast("file uploaded successfully");
            setEditingPO(response.data);
            console.log("🚀 ~ .then ~ response.data:", response.data);
          } else {
            toast("Something went wrong");
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };
*/

  const fileSelected = (e) => {
    const file = e.target.files[0];
    if (file.size > 167772160) {
      toast("File size cannot exceed more than 20MB");
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result.split(",")[1];
        let temp = [...items];
        temp[itemIndex].base64 = base64;
        temp[itemIndex].name = file.name;
        temp[itemIndex].type = file.type;
        setItems(temp);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center z-30">
        <OutsideClickHandler onOutsideClick={() => onCancelClicked()}>
          <div className="w-[436px] bg-white rounded-[6px] p-[18px] flex flex-col relative">
            {/* upload button */}
            <div className="absolute flex flex-col top-[20px] right-[18px]">
              {/* {editingPO && <WhiteUploadButton clicked={uploadReceipt} />} */}
              {/* <input
                onChange={receiptSelected}
                type="file"
                id="receiptfile"
                ref={receiptInputFile}
                style={{ display: "none" }}
                multiple={false}
                accept="image/*,application/pdf"
              /> */}
              <input
                onChange={(e) => fileSelected(e)}
                type="file"
                id="file"
                ref={itemInputFile}
                style={{ display: "none" }}
                multiple={false}
                accept="image/*,application/pdf"
              />
              {/* {receipts.map((file, index) => {
                return (
                  <div className="w-full h-[15px] flex items-center gap-3 mt-1" key={index}>
                    <span className="font-OpenSansRegular font-[400] text-[11px] text-titleColor cursor-pointer">
                      {file.name}
                    </span>
                  </div>
                );
              })} */}
            </div>
            {editingPO ? (
              <p className="text-[16px] font-OpenSansMedium font-[600] text-titleColor mb-5">EDIT A PURCHASE ORDER</p>
            ) : (
              <p className="text-[16px] font-OpenSansMedium font-[600] text-titleColor mb-5">CREATE A PURCHASE ORDER</p>
            )}
            {/* <div>
              <span className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-5">WO# </span>
              <span className="text-[12px] font-OpenSansMedium font-[400] text-titleColor mb-5">{wo.number}</span>
            </div> */}
            <div className="flex flex-col mt-4">
              <div className="w-full ">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">WO#</p>
                <input
                  value={woNumber}
                  type="text"
                  onChange={(e) => setWONumber(e.target.value)}
                  className="w-full border-solid border-[1px] rounded-[5px] text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium px-2 py-1"
                  placeholder="Enter WO #"
                />
              </div>
              {/* <div className="w-full mt-4">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Purchase Order #</p>
                <input
                  value={poNumber}
                  type="text"
                  onChange={(e) => setPoNumber(e.target.value)}
                  className="w-full border-solid border-[1px] rounded-[5px] text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium px-2 py-1"
                  placeholder="Enter PO #"
                />
              </div> */}

              <div className="z-20 mt-4">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Payment Type</p>
                <PaymentDropdown itemClicked={paymentTypeClicked} defaultValue={paymentType} />
              </div>
              <div className="w-full mt-4">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Vendor</p>
                <VendorDropDown
                  options={vendorList}
                  defaultOption={vendor}
                  onSelect={vendorSelected}
                  onChange={(value) => setVendor(value)}
                />
                {/* <input
                  value={vendor}
                  type="text"
                  onChange={(e) => setVendor(e.target.value)}
                  className="w-full border-solid border-[1px] rounded-[5px] text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium px-2 py-1"
                  placeholder="Enter Vendor"
                /> */}
              </div>
              <div className="w-full mt-4">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Description</p>
                <textarea
                  value={description}
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  multiple={true}
                  className="w-full h-[70px] border-solid border-[1px] rounded-[5px] text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium px-2 py-1"
                  placeholder="Enter Description"
                />
              </div>
              {!editingPO && (
                <div className="w-full mt-4">
                  <div className="flex items-center justify-between">
                    <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Item List</p>
                    <p
                      onClick={addItem}
                      className="text-subTitleColor text-[11px] font-OpenSansMedium font-[400] cursor-pointer"
                    >
                      + ADD
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor">Item Description</p>
                    <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor">Attachment</p>
                  </div>
                  <div className="flex flex-col h-[110px] overflow-auto">
                    {items.map((item, index) => {
                      return (
                        <div key={index} className="flex items-center justify-between gap-2 mb-2">
                          <input
                            onChange={(e) => descriptionChanged(e, index)}
                            className="border-solid border-[1px] border-borderColor rounded-[6px] flex-1 ont-OpenSansRegular font-[600] text-[11px] h-[30px] px-2"
                          />
                          {item.name.length === 0 ? (
                            <div
                              onClick={() => uploadClicked(index)}
                              className="rounded-[6px] w-[150px] h-[30px] flex items-center justify-center gap-1 cursor-pointer border-solid border-borderColor border-[1px]"
                            >
                              <img src={getImageUrl("/images/common/upload_black.png")} />
                              <span className="font-OpenSansRegular font-[600] text-[10px] text-[#404A6E]">UPLOAD</span>
                            </div>
                          ) : (
                            <span className="font-OpenSansRegular font-[400] text-[10px] text-[#404A6E]">
                              {item.name}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full mt-4">
              <div className="flex items-center justify-between gap-4">
                <div
                  onClick={onCancelClicked}
                  className="w-[50%] h-[30px] text-[10px] rounded-[6px] border-[1px] border-subTitleColor flex items-center justify-center text-subTitleColor cursor-pointer"
                >
                  CANCEL
                </div>
                <div
                  onClick={submitClicked}
                  className="w-[50%] h-[30px] text-[10px] rounded-[6px] flex items-center justify-center bg-blueColor text-white cursor-pointer"
                >
                  SUBMIT
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
}

export default CreatePOModal;
