import { current } from "@reduxjs/toolkit";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import CancelButton from "src/Components/Common/cancelbutton";
import BlueButton from "src/Components/Common/bluebutton";
import UploadButton from "../Account/uploadbutton";
import UserService from "src/Services/API/user.service";
import { API_URL, convertFileToBase64, handleError } from "src/Common/function";
import { getImageUrl, getLogoUrl } from "src/Common/common";
import CompanyService from "src/Services/API/company.service";

function Company() {
  const navigate = useNavigate();
  const inputFile = useRef(null);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [website, setWebsite] = useState("");
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(getImageUrl("/images/common/avatar_default.png"));

  useEffect(() => {
    CompanyService.get()
      .then((response) => {
        console.log("res: ", response);
        if (response.status == 200) {
          setName(response.data.name);
          setAddress(response.data.address);
          setPhone(response.data.phone);
          setFax(response.data.fax);
          setWebsite(response.data.website);
          setPreview(getLogoUrl());
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  const saveChanges = async () => {
    let logoBase64 = null;
    if (file) {
      logoBase64 = await convertFileToBase64(file);
    }

    const data = {
      data: {
        name,
        address,
        phone,
        fax,
        website,
      },
      logoBase64,
    };

    CompanyService.save(data)
      .then((response) => {
        console.log("res: ", response);
        if (response.status == 201) {
          toast("Company saved successfully");
        } else {
          toast("Something went wrong");
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const avatarClicked = (e) => {
    inputFile.current.click();
  };

  const fileSelected = (e) => {
    const avatar = e.target.files[0];
    console.log(avatar);
    if (avatar.size > 167772160) {
      toast("File size cannot exceed more than 20MB");
      return;
    }
    setFile(avatar);
    setPreview(URL.createObjectURL(avatar));
    // UserService.uploa;
  };

  //   const uploadClicked = async () => {
  //     if (file) {
  //       const logoBase64 = await convertFileToBase64(file);
  //       console.log("logoBase64: ", logoBase64);
  //       CompanyService.upload({ logoBase64 })
  //         .then((response) => {
  //           if (response.status == 201) {
  //             toast("Logo uploaded successfully");
  //             UserService.saveCurrentUser(response.data);
  //           } else {
  //             toast("Something went wrong");
  //           }
  //         })
  //         .catch((error) => {
  //           toast(error.response.data.message);
  //         });
  //     }
  //   };

  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="font-OpenSansRegular text-[16px] font-[600] text-titleColor">COMPANY INFORMATION</span>
        <div className="flex items-center gap-5">
          <CancelButton onClicked={() => navigate(-1)} />
          <BlueButton onClicked={saveChanges} title="SAVE " />
        </div>
      </div>

      <div className="flex items-center w-full gap-4 mt-5">
        <div className="h-[60px] w-[50%]">
          <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Name</p>
          <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
            <input
              value={name}
              type="text"
              onChange={(e) => setName(e.target.value)}
              className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
              placeholder="ex: John Smith"
            />
          </div>
        </div>
        <div className="h-[60px] w-[50%]">
          <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Phone</p>
          <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
            <input
              value={phone}
              type="text"
              onChange={(e) => setPhone(e.target.value)}
              className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
              placeholder="(xx) - xxx xxxx"
            />
          </div>
        </div>
      </div>

      <div className="flex items-center w-full gap-4 mt-5">
        <div className="h-[60px] w-[50%]">
          <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Address</p>
          <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
            <input
              value={address}
              type="text"
              onChange={(e) => setAddress(e.target.value)}
              className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
              placeholder="ex: John Smith"
            />
          </div>
        </div>
        <div className="h-[60px] w-[50%]">
          <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Fax</p>
          <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
            <input
              value={fax}
              type="text"
              onChange={(e) => setFax(e.target.value)}
              className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
              placeholder="(xx) - xxx xxxx"
            />
          </div>
        </div>
      </div>

      <div className="flex items-center w-full gap-4 mt-5">
        <div className="h-[60px] w-[50%]">
          <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Website</p>
          <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
            <input
              value={website}
              type="text"
              onChange={(e) => setWebsite(e.target.value)}
              className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
              placeholder="ex: John Smith"
            />
          </div>
        </div>
      </div>

      <div className="flex items-center gap-5 mt-10">
        <img
          onClick={avatarClicked}
          src={preview}
          className="w-[200px] h-[200px] object-contain cursor-pointer rounded-[100px]"
        />
        <input
          onChange={fileSelected}
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          multiple={false}
          accept="image/*"
        />

        <div className="w-[222px] h-[65px] flex flex-col justify-between">
          {/* <UploadButton onClicked={uploadClicked} /> */}
          <p className="font-OpenSansRegular text-[13px] font-[400] text-subTitleColor">
            You can upload images up to 2MB
          </p>
        </div>
      </div>
    </div>
  );
}
export default Company;
