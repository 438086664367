import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { filter } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";

const VendorDropDown = ({ options, defaultOption, onSelect, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [listItemClicked, setListItemClicked] = useState(true);

  const handleVendorClick = (option) => {
    setIsOpen(false);
    setListItemClicked(true);
    onSelect(option);
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (listItemClicked) {
      setListItemClicked(false);
      return;
    }
    let filtered = options.filter((obj) => obj.toLowerCase().includes(defaultOption.toLowerCase()));
    console.log("defaultOption: ", defaultOption, filtered);
    if (filtered.length > 0) {
      setFilteredOptions(filtered);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [defaultOption]);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className="relative w-full">
        <div
          // onClick={() => {
          //   setIsOpen(!isOpen);
          // }}
          className="cursor-pointer w-full flex items-center h-[30px] justify-between px-2  border rounded-[6px] text-gray-800 focus:outline-none focus:border-blue-500"
        >
          <input
            value={defaultOption}
            type="text"
            onChange={(e) => onChange(e.target.value)}
            className="w-full rounded-[5px] text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium "
            placeholder="Enter Vendor"
          />
          {/* <span className="font-OpenSansRegular text-[400] text-[12px] text-titleColor">{defaultOption}</span> */}
          <FiChevronDown className="ml-2" size={15} onClick={() => setIsOpen(true)} />
        </div>
        {isOpen && (
          <ul className="absolute z-10 mt-2 w-full bg-white border rounded-md shadow-lg max-h-[200px] overflow-y-auto">
            {filteredOptions.map((option, index) => (
              <li key={index} onClick={() => handleVendorClick(option)} className="px-4 py-2 cursor-pointer hover:bg-gray-200 font-OpenSansRegular text-[12px]">
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default VendorDropDown;
