import React, { useState, useEffect, useRef } from "react";

import { TECH_TYPE_LIST } from "src/Common/contants";
import RoleDropDown from "./roledropdown";
import UserDropDown from "./userdropdown";
import { getImageUrl } from "src/Common/common";

const AssignRow = ({ userArray, onUserSelected, onRoleSelected, index, tech, remove }) => {
  const [isUserDropDownOpen, setIsUserDropDownOpen] = useState(false);
  const [isRoleDropDownOpen, setIsRoleDropDownOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    setSelectedUser(userArray.find((item) => item.id === tech.user));
    setSelectedRole(TECH_TYPE_LIST[tech.roleId]);
  }, [tech, userArray]);

  const userSelected = (i) => {
    if (i == -1) {
      setIsUserDropDownOpen(false);
    } else if (i == -2) {
      setIsUserDropDownOpen(true);
    } else {
      setSelectedUser(userArray[i]);
      onUserSelected(i, index);
      setIsUserDropDownOpen(false);
    }
  };

  const roleSelected = (i) => {
    if (i == -1) {
      setIsRoleDropDownOpen(false);
    } else if (i == -2) {
      setIsRoleDropDownOpen(true);
    } else {
      setSelectedRole(TECH_TYPE_LIST[i]);
      onRoleSelected(i, index);
      setIsRoleDropDownOpen(false);
    }
  };

  return (
    <div className="flex items-center gap-3 mb-2">
      <div className="w-[50%]">
        <UserDropDown
          options={userArray}
          selectedUser={selectedUser}
          onSelect={userSelected}
          isOpen={isUserDropDownOpen}
        />
      </div>
      <div className="w-[50%]">
        <RoleDropDown selectedRole={selectedRole} onSelect={roleSelected} isOpen={isRoleDropDownOpen} />
      </div>
      <div
        onClick={() => remove(index)}
        className="w-[30px] h-[30px] rounded-[6px] border flex items-center justify-center cursor-pointer"
      >
        <img src={getImageUrl("/images/common/black_trash.png")} className="w-[18px]" />
      </div>
    </div>
  );
};

export default AssignRow;
