import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BranchService from "src/Services/API/branch.service";
import BranchButton from "./BranchButton";
import { useDispatch } from "react-redux";
import { setBranch } from "src/Redux/Slices/BranchSlice";

const BranchBar = ({ allEnabled = true }) => {
  const dispatch = useDispatch();
  const [branches, setBranches] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    BranchService.getAll()
      .then((response) => {
        let temp = [];
        if (allEnabled) {
          temp = [{ id: 0, name: "All" }];
        }
        console.log("🚀🚀🚀🚀🚀 :", temp.concat(response.data));

        setBranches(temp.concat(response.data));
      })
      .catch((error) => {
        console.log("getting branch error: ", error);
      });
  }, []);

  useEffect(() => {
    if (branches.length > 0) {
      branchClicked(0);
    }
  }, [branches]);

  const branchClicked = (index) => {
    setSelectedIndex(index);
    if (branches[index].name === "All") {
      dispatch(setBranch(0));
    } else {
      dispatch(setBranch(branches[index].id));
    }
  };

  return (
    <div className="w-full flex items-center gap-2">
      {branches.map((branch, index) => (
        <div key={index}>
          <BranchButton
            title={branch.name}
            index={index}
            clicked={branchClicked}
            selected={index === selectedIndex ? true : false}
          />
        </div>
      ))}
    </div>
  );
};

export default BranchBar;
