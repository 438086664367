import RequestedStatus from "./requestedstatus";
import IssuedStatus from "./issuedstatus";
import PurchasedStatus from "./purchasedstatus";
import CompleteStatus from "./completestatus";

const POStatusComponent = ({ value }) => {
  if (value === 0 || value === 1) {
    return <RequestedStatus />;
  } else if (value === 2) {
    return <IssuedStatus />;
  } else if (value === 3) {
    return <PurchasedStatus />;
  } else if (value === 4) {
    return <CompleteStatus />;
  }
};

export default POStatusComponent;
