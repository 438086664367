const TOS = () => {
  return (
    <div className="w-screen flex flex-col items-center">
      <div className="w-full max-w-[1000px] pb-[50px]">
        <p className="font-OpenSansRegular text-[18px] text-center text-titleColor font-[800] mt-[50px] mb-[50px]">Terms of Use</p>

        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
          These Terms of Use ("Terms") establish a legally enforceable contract between EntreLiving, LLC (the “Company”) and you regarding your engagement with Makani.Services (the
          "Website"). By accessing the Website, you agree to these Terms. If you choose not to accept these Terms, please refrain from using the Website.
        </p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">Website Usage License</p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
          The Company authorizes you a limited, non-exclusive license to access and view content on the Website solely for your personal, non-commercial use. This license is
          individualized and cannot be transferred or sub-licensed. Unless explicitly authorized by the Company in writing, you are prohibited from altering, sharing, replicating,
          downloading, or using the Website's content for public or commercial purposes, including but not limited to text, graphics, audio, and video, without the Company’s
          explicit consent. You must also refrain from any actions that could harm or interfere with the Website. All rights not specifically granted by the Company are reserved.
        </p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
          The Website provides access to various 3rd party software. This content does not imply the Company’s endorsement, support, or approval of the mentioned products,
          activities, services, or operators. Your decision to utilize 3rd party software through the Website is at your own risk. The Company is not liable for any personal harm,
          damage, or loss incurred from your participation in any experiences derived from the Website's information.
        </p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">Privacy Protections</p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
          Our commitment to your privacy is detailed in our Privacy Policy, which is an integral part of these Terms. We encourage you to review the Privacy Policy to understand
          our practices regarding data collection, usage, and sharing.
        </p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">Image Rights</p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
          The images of people or places shown on the Website are either owned by or used with permission by the Company. These images are not for use by you or anyone else
          authorized by you, except as explicitly allowed by these Terms. Unauthorized use may infringe upon copyright, trademark, privacy and publicity laws, as well as other
          regulations.
        </p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">Trademark Information</p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
          The trademarks, logos, and service marks displayed on the Website, whether registered or unregistered, are the intellectual property of the Company and others. Nothing on
          the Website should be interpreted as granting, implicitly or otherwise, any license or right to use any displayed trademark without the Company’s or the appropriate third
          party's written consent. Misuse of any trademarks displayed on the Website, or any other content, is strictly forbidden. The Company will vigorously defend its
          intellectual property rights, including through criminal prosecution where applicable.
        </p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">Copyright Infringement Claims</p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
          Under the Digital Millennium Copyright Act of 1998 ("DMCA"), if you believe that any content on the Website infringes upon your copyright, you can submit a removal
          request. The request must include: (a) your physical or electronic signature, or that of your authorized agent; (b) identification of the copyrighted work claimed to be
          infringed on our Website; (c) details of the infringing content, including information to help us locate it on the Website; (d) your contact details; (e) a statement of
          good faith belief that the contested use is not authorized by copyright law; and (f) a declaration, under penalty of perjury, that the information in the notification is
          accurate, and that you or your agent is authorized to act on behalf of the copyright owner. For false claims under the DMCA, penalties may apply. We recommend consulting
          a legal advisor before filing any notice or counter-notice. Notices and counter-notices should be sent to EntreLiving, LLC, 1309 Coffeen Ave., Suite 9271, Sheridan, WY
          82801 or via email at support@makani.services. Our policy is to terminate, in appropriate circumstances, the access rights of repeat infringers.
        </p>
        <br />
        <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
          The Company reserves the right to modify these Terms at any time. By continuing to use the Website, you agree to be bound by such revisions and should periodically review
          the current Terms.
        </p>
      </div>
    </div>
  );
};

export default TOS;
