import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";

function ConfirmDialog({
  title,
  description,
  shown,
  onConfirmClicked,
  onCancelClicked,
}) {
  return (
    shown && (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-screen h-screen bg-opacity-30 flex items-center justify-center z-10 bg-black">
        <OutsideClickHandler onOutsideClick={onCancelClicked}>
          <div className="w-[332px] h-[168px] bg-white p-[18px] flex flex-col justify-between border-[1px] rounded-[6px]">
            <div>
              <p className="font-OpenSansRegular text-[16px] font-[600] text-titleColor">
                {title}
              </p>
              <p className="font-OpenSansRegular text-[12px] font-[400] text-subTitleColor mt-3">
                {/* Are you sure you want to delete this? */}
                {description}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <div
                onClick={onCancelClicked}
                className="w-[145px] h-[30px] text-[10px] rounded-[6px] border-[1px] border-subTitleColor flex items-center justify-center text-subTitleColor cursor-pointer"
              >
                CANCEL
              </div>
              <div
                onClick={onConfirmClicked}
                className="w-[145px] h-[30px] text-[10px] rounded-[6px] flex items-center justify-center bg-blueColor text-white cursor-pointer"
              >
                CONFIRM
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
}

export default ConfirmDialog;
