import OutsideClickHandler from "react-outside-click-handler";
import moment, { isDate } from "moment";
import { useState } from "react";
import DatePickerModal from "src/Common/datepickermodal";

import { getImageUrl, formatDate } from "src/Common/common";
import { useEffect } from "react";

const BilledDateModal = ({ shown, onCancel, onSaveClicked, pBilledDate, pBilledNote }) => {
  const [billedDate, setBilledDate] = useState(pBilledDate);
  const [billedNote, setBilledNote] = useState(pBilledNote);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  useEffect(() => {
    setBilledDate(null);
    setBilledNote(null);
  }, [shown]);
  useEffect(() => {
    setBilledDate(pBilledDate);
  }, [pBilledDate]);
  useEffect(() => {
    setBilledDate(pBilledDate);
  }, [pBilledNote]);

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center z-30">
        <OutsideClickHandler
          onOutsideClick={() => {
            onCancel();
          }}
        >
          <div className="w-[348px] h-[350px] rounded-[6px] bg-white p-[18px]">
            <p className="text-[16px] font-OpenSansMedium font-[600] text-titleColor mb-5">SCHEDULE</p>
            <div className="w-full flex gap-3 flex-col">
              <div className="w-[300px]">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-5">Billed Date</p>
                <div
                  onClick={() => setDatePickerOpen(true)}
                  className="w-full h-[30px] border-borderColor border-[1px] rounded-[6px] flex items-center px-1 gap-1"
                >
                  <img src={getImageUrl("/images/wo/date.png")} />
                  {billedDate ? (
                    <p className="text-[12px] font-OpenSansMedium font-[400] text-titleColor">
                      {formatDate(billedDate, "MM/DD/YYYY")}
                    </p>
                  ) : (
                    <p className="text-[12px] font-OpenSansMedium font-[400] text-inputFieldTextColor">Billed date</p>
                  )}
                </div>
                {datePickerOpen && (
                  <DatePickerModal
                    shown={datePickerOpen}
                    pSelectedDate={billedDate}
                    cancelClicked={() => setDatePickerOpen(false)}
                    dateSelected={(date) => {
                      setBilledDate(date);
                      setDatePickerOpen(false);
                    }}
                    timeVisible={false}
                  />
                )}
              </div>
              <div className="w-[300px]">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-5">Billed Note</p>
                <textarea
                  value={billedNote}
                  type="text"
                  onChange={(e) => setBilledNote(e.target.value)}
                  multiple={true}
                  className="w-full h-[80px] order-solid border-[1px] rounded-[5px] text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium px-2 py-1 resize-none"
                  placeholder="Enter Note"
                />
              </div>
            </div>

            <div className="w-full flex justify-between gap-3 mt-5">
              <div
                onClick={onCancel}
                className="cursor-pointer w-[50%] h-[30px] rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-inputFieldTextColor border-borderColor border-[1px]"
              >
                CANCEL
              </div>

              <div
                onClick={() => billedDate && onSaveClicked(billedDate, billedNote)}
                className="cursor-pointer w-[50%] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-white"
              >
                SAVE
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default BilledDateModal;
