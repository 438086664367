import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WOService from "src/Services/API/wo.service";
import AuthService from "src/Services/API/auth.service";
import Button from "src/Components/CreateButton";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  getImageUrl,
  getAvatarUrl,
  getServiceTicketUrl,
  formatDate,
  getUploadUrl,
  getRealFileName,
} from "src/Common/common";
// import {
//   Document,
//   Page,
//   pdfjs,
//   PDF,
//   PDFViewer,
//   PDFRenderer,
//   PDFPage,
// } from "react-pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { handleError } from "src/Common/function";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import BlueButton from "src/Components/Common/bluebutton";
import ServiceTicketEditModal from "./serviceticketeditmodal";
import ConfirmDialog from "src/Common/confirmdialog";
import UserService from "src/Services/API/user.service";

const currentUser = UserService.getCurrentUser();

function ServiceTicket() {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const navigate = useNavigate();
  const location = useLocation();
  const [order, setOrder] = useState(location.state.order);
  const [totalTime, setTotalTime] = useState(null);
  const [serviceTicketUrl, setServiceTicketUrl] = useState(null);
  const [stEditModalShown, setStEditModalShown] = useState(false);
  const [stSendConfirmModalShown, setStSendConfirmModalShown] = useState(false);

  useEffect(() => {
    WOService.getWorkOrderById(location.state.order.id)
      .then((response) => {
        if (response.status == 200) {
          console.log("ST order: ", response.data);
          setOrder(response.data);
        }
      })
      .catch((error) => handleError(error));
  }, []);

  useEffect(() => {
    console.log("service ticket order:", order);

    // if (order.timesheetTotalData) {
    //   setTotalTime(JSON.parse(order.timesheetTotalData));
    // }

    console.log("dd ", getServiceTicketUrl(order.number));

    WOService.previewServiceTicket(order.id).then((response) => {
      if (response.status == 200) {
        setServiceTicketUrl(getServiceTicketUrl(order.number));
      }
    });
  }, [order]);

  const sendServiceTicket = () => {
    console.log("sending service ticket");
    WOService.sendServiceTicket(order.id)
      .then((response) => {
        if (response.status == 200) {
          console.log(response);
          setOrder(response.data);
          toast("Service ticket sent successfully");
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const editServiceTicket = () => {
    setStEditModalShown(true);
  };

  const updateServiceTicket = (draftData, materials) => {
    if (materials) {
      let originalMaterials = JSON.parse(order.materials);
      for (let i = 0; i < materials.length; i++) {
        if (
          materials[i].description !== originalMaterials[i].description ||
          materials[i].quantity !== originalMaterials[i].quantity
        ) {
          WOService.updateMaterial(order.id, i, materials[i], UserService.getCurrentUser());
        }
      }
    }

    WOService.attachMaterials({ id: order.id, data: draftData, eventUser: UserService.getCurrentUser() })
      .then((response) => {
        console.log(response.status);
        if (response.status == 201) {
          console.log(JSON.stringify(response.data));
          setOrder(response.data);
          toast("Service ticket updated successfully");
          setStEditModalShown(false);
          WOService.previewServiceTicket(order.id)
            .then((response) => {
              if (response.status == 200) {
                setServiceTicketUrl("");
                setServiceTicketUrl(getServiceTicketUrl(order.number));
              }
            })
            .catch((error) => handleError(error));
        }
      })
      .catch((error) => handleError(error));
  };

  return (
    <div className="w-full">
      <ServiceTicketEditModal
        shown={stEditModalShown}
        pOrder={order}
        saveClicked={updateServiceTicket}
        cancelClicked={() => setStEditModalShown(false)}
      />
      <ConfirmDialog
        title="SEND SERVICE TICKET"
        description="Are you sure you want to send this service ticket?"
        shown={stSendConfirmModalShown}
        onConfirmClicked={() => {
          setStSendConfirmModalShown(false);
          sendServiceTicket();
        }}
        onCancelClicked={() => setStSendConfirmModalShown(false)}
      />
      <div className="w-full h-[56px] flex items-center justify-between cursor-pointer px-5">
        <div className="flex items-center" onClick={() => navigate(-1)}>
          <img src={getImageUrl("/images/common/back_arrow.png")} className="w-[24px]" />
          <span className="font-OpenSansRegular text-[14px] font-[700] text-subTitleColor">{`back to work order #${order.number}`}</span>
        </div>
      </div>
      <div className="w-full h-full border-t-[1px] border-b-[1px] border-borderColor flex">
        <div className="w-[50%] px-4 py-3 border-r-[1px] border-borderColor relative">
          <div className="absolute right-4 top-3">
            {((order.status !== 100 && currentUser.roles[0].id < 4) ||
              (order.status === 100 && currentUser.roles[0].id < 3)) && (
              <BlueButton onClicked={editServiceTicket} title="EDIT" />
            )}
          </div>
          <p className="font-OpenSansRegular text-[16px] font-[700] text-titleColor">Review:</p>
          <div className="flex gap-3 mt-5">
            <p className="font-OpenSansRegular text-[16px] font-[400] text-subTitleColor">Information provided by:</p>
            {order.serviceTicketProvider && (
              <>
                <img src={getAvatarUrl(order.serviceTicketProvider.avatar)} className="w-[24px] rounded-[12px]" />
                <p className="font-OpenSansRegular text-[16px] font-[400] text-titleColor w-[300px]">
                  {order.serviceTicketProvider.name}
                </p>
              </>
            )}
          </div>
          <div className="mt-5">
            <p className="font-OpenSansRegular text-[16px] font-[700] text-inputEmailTitle">Send to:</p>
            <p className="font-OpenSansRegular text-[14px] font-[400] text-titleColor">{order.ticketReceipients}</p>
          </div>
          <div className="mt-5">
            <p className="font-OpenSansRegular text-[16px] font-[700] text-inputEmailTitle">Services provided:</p>
            <p className="font-OpenSansRegular text-[14px] font-[400] text-titleColor">{order.servicesProvided}</p>
          </div>
          <div className="mt-5">
            <p className="font-OpenSansRegular text-[16px] font-[700] text-inputEmailTitle">Recommendations:</p>
            <p className="font-OpenSansRegular text-[14px] font-[400] text-titleColor">{order.recommendations}</p>
          </div>
          {/* Timesheet */}
          <div className="border-[1px] border-borderColor mt-5">
            <div className="h-[30px] w-full flex items-center pl-2">
              <p className="font-OpenSansRegular text-[16px] font-[700] text-titleColor">Timesheet</p>
            </div>
            <div className="h-[30px] w-full flex items-center bg-backgroundColor border-t-[1px] border-borderColor relative">
              <p className="font-OpenSansRegular text-[12px] font-[00] text-subTitleColor absolute right-[30%] top-[5px]">
                REGULAR TIME
              </p>
              <p className="font-OpenSansRegular text-[12px] font-[00] text-subTitleColor absolute right-[10%] top-[5px]">
                OVER TIME
              </p>
            </div>
            {order.assignedTechs.map((tech, index) => (
              <div
                key={index}
                className="w-full h-[50px] flex items-center px-3 border-t-[1px] border-borderColor relative"
              >
                <img src={getAvatarUrl(tech.user.avatar)} className="w-[24px] rounded-[12px]" />
                <p className="font-OpenSansRegular text-[16px] font-[400] text-titleColor ml-2">{tech.user.name}</p>

                <p className="font-OpenSansRegular text-[16px] font-[600] text-titleColor absolute right-[33%] top-[12px]">
                  {JSON.parse(tech.totalTimesheet)?.regularTime ? JSON.parse(tech.totalTimesheet).regularTime : "__:__"}
                </p>

                <p className="font-OpenSansRegular text-[16px] font-[600] text-titleColor  absolute right-[12%] top-[12px]">
                  {JSON.parse(tech.totalTimesheet)?.overTime ? JSON.parse(tech.totalTimesheet).overTime : "__:__"}
                </p>
              </div>
            ))}
          </div>
          {/* Other Materials */}
          {order.materials && (
            <div className="border-[1px] border-borderColor mt-5">
              <div className="h-[30px] w-full flex items-center pl-2">
                <p className="font-OpenSansRegular text-[16px] font-[700] text-titleColor">Other Materials</p>
              </div>
              <table className="w-full">
                <thead>
                  <tr className="border-t-[1px] border-b-[1px] border-borderColor">
                    <th className="border-r-[1px] border-borderColor w-[50%]">
                      <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center justify-center h-[25px] text-titleColor text-center">
                        <span>Description</span>
                      </div>
                    </th>
                    <th className="border-r-[1px] border-borderColor w-[10%]">
                      <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center justify-center   h-[25px] text-titleColor text-center">
                        <span>Quantity</span>
                      </div>
                    </th>
                    <th className="border-borderColor w-[40%]">
                      <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center justify-center h-[25px] text-titleColor text-center">
                        <span>Attachment</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(order.materials).map((material, index) => (
                    <tr key={index} className="border-b-[1px] border-borderColor h-[40px]">
                      <td className="border-r-[1px]  border-borderColor">
                        <div className="font-OpenSansRegular px-[5px] py-[5px] text-center text-[10px] font-[400] col-span-1">
                          {material.description}
                        </div>
                      </td>
                      <td className="border-r-[1px]  border-borderColor">
                        <div className="font-OpenSansRegular px-[5px] py-[5px] text-center text-[10px] font-[400] col-span-1">
                          {material.quantity}
                        </div>
                      </td>
                      <td className="border-borderColor">
                        {material.attachment && (
                          <a
                            href={getUploadUrl(material.attachment)}
                            target="_blank"
                            download={getRealFileName(material.attachment)}
                          >
                            <p className="font-OpenSansRegular font-[400] text-[11px] text-titleColor text-center">
                              {getRealFileName(material.attachment)}
                            </p>
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="w-[50%] px-4 py-3 relative">
          <div className="absolute right-4 top-3 flex items-center gap-3">
            {order.stSentDate && (
              <span className="font-OpenSansRegular text-[12px] font-[600] text-red-500">
                {"Sent on " + formatDate(order.stSentDate)}
              </span>
            )}

            {order.status === 5 || (order.status > 5 && order.isServiceTicketEdited) ? (
              <BlueButton onClicked={() => setStSendConfirmModalShown(true)} title="SEND" />
            ) : null}
          </div>
          <p className="font-OpenSansRegular text-[16px] font-[700] text-titleColor mb-5">Preview:</p>
          {serviceTicketUrl && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer fileUrl={serviceTicketUrl} plugins={[defaultLayoutPluginInstance]} />
            </Worker>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServiceTicket;
