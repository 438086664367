const ApproveRequestButton = ({ onClicked }) => {
  return (
    <div
      onClick={onClicked}
      className="cursor-pointer w-[180px] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-white"
    >
      APPROVE REQUEST
    </div>
  );
};

export default ApproveRequestButton;
