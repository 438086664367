import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddModal from "./addmodal";
import VendorService from "src/Services/API/vendor.service";
import { toast } from "react-toastify";
import VendorTable from "./vendortable";
import UserService from "src/Services/API/user.service";
import BranchBar from "src/Components/branchbar/BranchBar";
import { isAdmin } from "src/Common/function";

function Vendor() {
  const branch = useSelector((state) => state.Branch.branch);
  const currentUser = UserService.getCurrentUser();
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [editingVendor, setEditingVendor] = useState(null);

  useEffect(() => {
    getVendors();
  }, [branch]);

  const getVendors = async () => {
    try {
      const response = await VendorService.getAll(branch);
      if (response.status == 200) {
        let tempArray = [];
        for (let item of response.data) {
          const date = new Date(item.createdAt);
          item.formattedCreatedAt = date.toLocaleDateString("en-GB");
          tempArray.push(item);
        }
        setVendors(tempArray);
      } else {
        toast("Something went wrong!");
      }
    } catch (error) {
      console.log("error: ", error);
      toast(error.response.data.message);
    }
  };

  const addButtonClicked = () => {
    setIsAdd(true);
  };

  const addClicked = (data) => {
    VendorService.saveVendor(data, branch)
      .then((response) => {
        console.log(response);
        if (response.status == 201) {
          toast("Vendor saved successfully");
          getVendors();
        } else {
          toast("Something went wrong");
        }
      })
      .catch((error) => {
        console.log("Vendor save error: ", error);
        toast(error.response.data.message);
      });
    setIsAdd(false);
  };

  const deleteVendor = (index) => {
    VendorService.deleteVendor(vendors[index].id)
      .then((response) => {
        console.log(response);
        if (response.status == 201 && response.data == true) {
          toast("Vendor deleted successfully");
          getVendors();
        } else {
          toast("Something went wrong");
        }
      })
      .catch((error) => {
        console.log("Vendor save error: ", error);
        toast(error.response.data.message);
      });
  };

  const updateVendor = (index) => {
    setIsEdit(true);
    setEditingVendor(vendors[index]);
  };

  const updateClicked = (vendor) => {
    VendorService.updateVendor(vendor)
      .then((response) => {
        console.log(response);
        if (response.status == 201) {
          toast("Vendor updated successfully");
          getVendors();
        } else {
          toast("Something went wrong");
        }
      })
      .catch((error) => {
        console.log("Vendor save error: ", error);
        toast(error.response.data.message);
      });
    setIsEdit(false);
  };

  return (
    <div className="w-full h-full bg-red  px-3">
      <div className="px-7 py-2">{isAdmin() && <BranchBar />}</div>
      {isAdd && <AddModal onAddClicked={addClicked} onCancelClicked={() => setIsAdd(false)} vendor={null} />}
      {isEdit && (
        <AddModal onUpateClicked={updateClicked} onCancelClicked={() => setIsEdit(false)} vendor={editingVendor} />
      )}
      {branch !== 0 && (
        <div className="flex items-center justify-end h-[62px] border-b-[1px] border-borderColor">
          <div onClick={addButtonClicked} className="w-[92px] h-[30px] flex items-center justify-center cursor-pointer">
            <span className="text-[12px] font-[600] font-OpenSansRegular text-subTitleColor">+ Vendor</span>
          </div>
        </div>
      )}
      <VendorTable onDeleteVendor={deleteVendor} onEditClicked={updateVendor} vendors={vendors} />
    </div>
  );
}

export default Vendor;
