import { useEffect, useState } from "react";

import _ from "lodash";
import WOService from "src/Services/API/wo.service";
import UserService from "src/Services/API/user.service";
import AuthService from "src/Services/API/auth.service";
import Button from "src/Components/CreateButton";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { handleError, getAssignedTechsNameArray, isAdmin } from "src/Common/function";
import { ITEMS_PER_PAGE } from "src/Common/contants";
import LoadMoreButton from "src/Components/Common/loadmorebutton";
import ServiceCallType from "../WO/Components/Types/servicecalltype";
import QuotedType from "../WO/Components/Types/quotedtype";
import WOStatusComponent from "../WO/Components/Status/wostatuscomponent";
import BranchBar from "src/Components/branchbar/BranchBar";

function Recommendations() {
  const currentUser = UserService.getCurrentUser();
  const branch = useSelector((state) => state.Branch.branch);
  const keyword = useSelector((state) => state.Keyword.keyword);
  const [orderArray, setOrderArray] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadMoreButtonShown, setLoadMoreButtonShown] = useState(false);

  useEffect(() => {
    setPageNumber(0);
    getAllRecommendations([], 0);
  }, [branch]);

  const getAllRecommendations = (previousOrderArray, pNumber) => {
    console.log("KEYWORD: ", keyword);
    WOService.getAllRecommendations(keyword, pNumber, branch)
      .then((response) => {
        console.log("AAAAAAAA: ", response.data, "OrderArray: ", orderArray);
        if (response.status == 200) {
          let temp = [...previousOrderArray, ...response.data.orders];
          for (let i = 0; i < temp.length; i++) {
            temp[i].techNames = getAssignedTechsNameArray(temp[i].assignedTechs);
          }
          setOrderArray(temp);

          if (temp.length >= response.data.totalCount) {
            setLoadMoreButtonShown(false);
          } else {
            setLoadMoreButtonShown(true);
          }
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        handleError(error);
      });
  };

  const handleLoadMore = () => {
    getAllRecommendations(orderArray, pageNumber + 1);
    setPageNumber(pageNumber + 1);
  };

  return (
    <div className="w-full bg-red bg-backgroundColor">
      <div className="px-7">
        <div className="h-3" />
        {isAdmin() && <BranchBar />}
        <div className="h-3" />
        <table>
          <thead>
            <tr className="border-t-[1px] border-b-[1px] border-borderColor">
              <th className="border-r-[1px] border-borderColor">
                <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center justify-center pl-4 w-[100px] h-[40px] text-titleColor text-center">
                  <span>WO#</span>
                </div>
              </th>
              <th className="border-r-[1px] border-borderColor">
                <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 w-[120px] h-[40px] text-titleColor">
                  Type
                </div>
              </th>
              <th className="border-r-[1px] border-borderColor">
                <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 w-[230px] h-[40px] text-titleColor">
                  Customer
                </div>
              </th>
              {/* <th className="border-r-[1px] border-borderColor">
                <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 w-[160px] h-[40px] text-titleColor">NTE</div>
              </th> */}
              <th className="w-min-[200px] w-full border-r-[1px] border-borderColor">
                <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4  h-[40px] text-titleColor">
                  Recommendations
                </div>
              </th>
              <th className="border-r-[1px] border-borderColor">
                <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 w-[250px] h-[40px] text-titleColor">
                  Technician
                </div>
              </th>
              <th>
                <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 w-[130px] h-[40px] text-titleColor">
                  Status
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {orderArray.map((order, index) => (
              <tr
                key={index}
                // onClick={() => {
                //   dispatch(setWONumber(String(order.number)));
                //   navigate("/work/" + String(order.number), {
                //     state: { order: order },
                //   });
                // }}
                className="border-b-[1px] border-borderColor h-[40px]"
              >
                <td className="border-r-[1px]  border-borderColor">
                  <div className="font-OpenSansRegular px-[5px] py-[5px] text-center text-[12px] font-[400] col-span-1">
                    {order.number}
                  </div>
                </td>
                <td className="border-r-[1px]  border-borderColor">
                  <div className="mx-auto flex justify-center">
                    {order.type == 0 ? <ServiceCallType /> : <QuotedType />}
                  </div>
                </td>
                <td className="border-r-[1px]  border-borderColor">
                  <div className="font-OpenSansRegular px-[10px] py-[5px] text-[12px] font-[400] word-break-break-word">
                    {order.customer?.company}
                  </div>
                </td>
                <td className="border-r-[1px]  border-borderColor ">
                  <div className="font-OpenSansRegular px-[10px] py-[5px] text-[12px] text-ellipsis word-break-break-word">
                    {order.recommendations}
                  </div>
                </td>
                <td className="border-r-[1px]  border-borderColor ">
                  <div className="font-OpenSansRegular px-[10px] py-[5px] text-[12px] text-ellipsis word-break-break-word">
                    {order.techNames}
                  </div>
                </td>
                <td>
                  <div className="px-[10px] py-[5px] flex items-center gap-3">
                    <WOStatusComponent value={order.status} />
                    {/* {currentUser.roles[0].id <= 1 && (
                      <img
                        onClick={(event) => {
                          setIsDeleteConfirmDialogShown(!isDeleteConfirmDialogShown);
                          setIndexToDelete(index);
                          event.stopPropagation();
                        }}
                        src={getImageUrl("/images/common/trash.png")}
                        className="cursor-pointer"
                      />
                    )} */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="w-full mt-[10px] mb-[10px]">
          {loadMoreButtonShown && <LoadMoreButton onClicked={handleLoadMore} />}
        </div>
      </div>
    </div>
  );
}

export default Recommendations;
