import { useEffect, useState } from "react";
import UserService from "src/Services/API/user.service";

function AddModal({ branch, onAddClicked, onUpateClicked, onCancelClicked }) {
  const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [contact, setContact] = useState("");
  // const [phone, setPhone] = useState("");
  // const [address, setAddress] = useState("");
  const [yesButtonTitle, setYesButtonTitle] = useState("ADD");

  useEffect(() => {
    if (branch) {
      setName(branch.name);
      // setEmail(customer.email);
      // setContact(customer.contact);
      // setPhone(customer.phone);
      // setAddress(customer.address);
      setYesButtonTitle("UPDATE");
    } else {
      setYesButtonTitle("ADD");
    }
  }, []);

  const addClicked = () => {
    if (branch) {
      branch.name = name;
      // customer.email = email;
      // customer.contact = contact;
      // customer.phone = phone;
      // customer.address = address;

      onUpateClicked(branch);
    } else {
      const data = {
        name,
        // email,
        // contact,
        // phone,
        // address,
        // branch: UserService.getCurrentUser().branch.id,
      };
      onAddClicked(data);
    }
  };

  const cancelClicked = () => {
    onCancelClicked();
  };

  return (
    <div
      onClick={onCancelClicked}
      className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[448px] h-[200px] bg-white rounded-[6px] p-[18px] flex flex-col"
      >
        <p className="text-[16px] font-OpenSansMedium font-[600] text-titleColor mb-5">ADD BRANCH</p>

        <div className="flex flex-col justify-between h-full">
          <div className="h-[60px] w-full">
            <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Name</p>
            <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
              <input
                value={name}
                type="text"
                onChange={(e) => setName(e.target.value)}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="Enter branch name"
              />
            </div>
          </div>

          {/* <div className="h-[60px] w-full">
            <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Email</p>
            <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
              <input
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="Enter email"
              />
            </div>
          </div>

          <div className="h-[60px] w-full">
            <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Contact Person</p>
            <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
              <input
                value={contact}
                type="text"
                onChange={(e) => setContact(e.target.value)}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="Enter contact person"
              />
            </div>
          </div>

          <div className="h-[60px] w-full">
            <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Phone Number</p>
            <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
              <input
                value={phone}
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="(xx) xxxx-xxxx"
              />
            </div>
          </div>

          <div className="h-[60px] w-full">
            <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Address</p>
            <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
              <input
                value={address}
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="Enter Address"
              />
            </div>
          </div> */}

          <div className=" w-full">
            <div className="flex items-center justify-between gap-4">
              <div
                onClick={cancelClicked}
                className="w-[50%] h-[30px] text-[10px] rounded-[6px] border-[1px] border-subTitleColor flex items-center justify-center text-subTitleColor cursor-pointer"
              >
                CANCEL
              </div>
              <div
                onClick={addClicked}
                className="w-[50%] h-[30px] text-[10px] rounded-[6px] flex items-center justify-center bg-blueColor text-white cursor-pointer"
              >
                {yesButtonTitle}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddModal;
