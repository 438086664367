import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { getImageUrl } from "src/Common/common";

function BranchDropDown({ branches, branchClicked }) {
  const [isDropDownShown, setIsDropDownShown] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const branchSelected = (branch) => {
    setSelectedBranch(branch);
    branchClicked(branch);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropDownShown(false);
      }}
    >
      <div
        onClick={() => setIsDropDownShown(!isDropDownShown)}
        className="w-[310px] px-[15px] py-[5px]  col-span-1 flex items-center justify-between cursor-pointer relative"
      >
        <span className="font-OpenSansRegular text-[10px] font-[400]">
          {selectedBranch ? selectedBranch.name : "Select branch"}
        </span>
        <img src={getImageUrl("/images/common/arrow-down.png")} />

        {isDropDownShown && (
          <div className="w-full absolute px-1 py-1 max-h-[140px] overflow-auto border-[#E6E7EC] border-[1px]  top-[39px] left-0 z-10 bg-white flex flex-col justify-between">
            {branches.map((branch, index) => (
              <div
                onClick={() => branchSelected(branch)}
                key={index}
                className="w-full h-[20px] flex items-center gap-2 px-2 hover:bg-slate-400"
              >
                <p className="font-OpenSansRegular text-[10px] text-inputEmailTitle font-[400]">{branch.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}

export default BranchDropDown;
