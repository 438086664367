function ApplyButton({ onClicked }) {
  return (
    <div
      onClick={onClicked}
      className="rounded-[4px] flex items-center px-[10px] w-[98px] h-[30px] cursor-pointer justify-center border-borderColor border-[1px] bg-blueColor border-none"
    >
      <span className="font-OpenSansRegular text-[12px] font-[600] text-white">Apply</span>
    </div>
  );
}

export default ApplyButton;
