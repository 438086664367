const Privacy = () => {
  return (
    <div className="w-screen flex flex-col items-center">
      <div className="w-full max-w-[1000px] pb-[50px]">
        <p className="font-OpenSansRegular text-[18px] text-center text-titleColor font-[800] mt-[50px]">PRIVACY POLICY</p>
        <p className="font-OpenSansRegular text-[11px] text-center text-titleColor font-[400] mb-[50px]">Effective date: March 1, 2024</p>

        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">1. Introduction</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            This Privacy Policy sets forth the data protection and privacy practices of Makani Services (“Company”, “we”, “us”, or “our”). This policy applies to all personal
            information collected through our website https://Makani.services and any associated services or communications. Our commitment is to protect your personal information
            in accordance with applicable laws and regulations, including the General Data Protection Regulation (GDPR) and U.S. privacy laws.
          </p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">2. Who We Are</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            Makani Services is a provider of software services for the construction and contractor trades. With a focus on managing service calls, work orders, purchase orders,
            time sheets, and other documentation that is critical to managing the logistics of running an efficient service-based business.
          </p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">3. Information We Collect</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            We collect several types of information from and about users of our website and services, including:
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • Personal Identification Information: Your name, email address, telephone number, and payment information.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • Services Information: Your customer names, physical address, email addresses, & phone numbers. Your vendor’s names, physical address, email addresses, & phone
            numbers.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • Technical Data: Information collected automatically as you navigate through the site (e.g., usage details, IP addresses).
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • Communication Records: Correspondences, if you contact us for support or inquiries.
          </p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">4. How We Collect Information</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">Information is collected through various methods:</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • Direct Collection: When you provide information by filling out forms on our website or on our app, such as when you start a new service ticket, add a new technician,
            or other staff member.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • Automated Technologies: As you interact with our website, we may use automatic data collection technologies to gather certain information about your equipment,
            browsing actions, and patterns.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • Third-Party Sources: We may receive data about you from various third parties and public sources, including analytics providers or your interactions on social media
            platforms that are linked to our services.
          </p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">5. Use of Collected Information</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">The collected information is used for the following purposes:</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • To fulfill our service commitments, such as the direct functions of our website and app to provide you with the services that you have purchased from us.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• To provide customer support and respond to your inquiries.</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• To improve our website and personalize user experience.</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • For internal operations, including data analysis, testing, and service improvement.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • To communicate with you about our services, including providing updates and promotional offers, where permitted.
          </p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">6. Information Sharing and Disclosure</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">Your personal information may be disclosed:</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • To third-party service providers who perform services on our behalf (e.g., payment processing, data analysis).
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • To comply with any court order, law, or legal process, including responding to government or regulatory requests.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• To enforce or apply our terms of use and other agreements.</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.
          </p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">7. International Data Transfers</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            Since we operate globally, your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental
            jurisdiction where the data protection laws may differ from those of your jurisdiction. We will take all steps reasonably necessary to ensure that your data is treated
            securely and in accordance with this privacy policy.
          </p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">8. Data Security Measures</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            We have implemented appropriate technical and organizational measures designed to secure your personal information from accidental loss and from unauthorized access,
            use, alteration, and disclosure. These measures include encryption, firewalls, and secure server facilities. While we strive to use commercially acceptable means to
            protect your Personal Data, we cannot guarantee its absolute security, as no method of transmission over the Internet or method of electronic storage is 100% secure.
          </p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">9. Data Retention Policy</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            We retain personal information only for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal,
            accounting, or reporting requirements. After this period, your personal data will be irreversibly destroyed. Any data that we are obliged to keep for administrative,
            legal, or security purposes will be retained in accordance with our internal retention policies and applicable laws.
          </p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">10. Children’s Privacy</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn we have collected
            or received personal information from a child under 13 without parental consent, we will delete that information. If you believe we might have any information from or
            about a child under 13, please contact us immediately.
          </p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">11. Your Rights Under GDPR</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            For individuals within the European Union, the General Data Protection Regulation (GDPR) grants specific rights, including:
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• The right to access: You can request copies of your personal data.</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • The right to rectification: You can request correction of any incomplete or inaccurate data we hold about you.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • The right to erasure: You can request the deletion of your personal data under certain conditions.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • The right to restrict processing: You can request the restriction of processing your personal data.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • The right to object to processing: You can object to the processing of your personal data.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • The right to data portability: You can request the transfer of your data to another organization or directly to you.
          </p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">12. Your Rights Under US Privacy Laws</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">Under US privacy laws, you may have additional rights, including:</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• The right to know what personal data is being collected about you.</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• The right to know whether your personal data is sold or disclosed and to whom.</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• The right to say no to the sale of personal data.</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• The right to access your personal data.</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• The right to equal service and price, even if you exercise your privacy rights.</p>
        </div>
        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">13. Cookies and Tracking Technologies</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            We may use cookies and similar tracking technologies to track activity on our website and hold certain information. Cookies are files with a small amount of data that
            may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not
            accept cookies, you may not be able to use some portions of our website.
          </p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">14. Third-Party Services and Links</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            Our website may contain links to third-party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by us. We
            do not endorse or assume any responsibility for any such third-party sites, information, materials, products, or services. If you access any third-party website,
            service, or content from our website, you do so at your own risk and you agree that we will have no liability arising from your use of or access to any third-party
            website, service, or content.
          </p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">15. Social Media and Public Forums</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            We may provide public forums and social media features that allow you to share information, including personal data, about yourself and your activities. Please be aware
            that any information you share in these areas may be read, collected, and used by others who access them. We are not responsible for the privacy of any information that
            you choose to make public in these forums.
          </p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">16. Opt-Out Options</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            You have the option to opt-out of certain uses of your personal information. This includes opting out of receiving our marketing communications. To opt-out, you may
            follow the unsubscribe instructions provided in any marketing communication or contact us directly. Please note that it may take some time to process your request.
          </p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">17. Policy Updates and Notification</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            We may update this privacy policy from time to time. The updated policy will be indicated by an updated “Revised” date and the updated version will be effective as soon
            as it is accessible. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
          </p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">18. Contact Information</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            If you have any questions or comments about this policy, the ways in which we collect and use your information, your choices and rights regarding such use, or wish to
            exercise your rights under applicable law, please do not hesitate to contact us at:
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• Email: support@Makani.services</p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">19. Complaints and Dispute Resolution</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            If you have any complaints about our privacy practices, we encourage you to contact us first. We will do our best to resolve your complaint. If you are not satisfied
            with our response, you may have the right to lodge a complaint with your local data protection authority or another regulatory body.
          </p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">20. Governing Law and Jurisdiction</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            This privacy policy and any dispute or claim arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance
            with the law of the United States and the specific state in which the Company is headquartered, without giving effect to any choice or conflict of law provision or
            rule.
          </p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">21. Data Protection Officer</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            To ensure ongoing compliance with data protection regulations, we have appointed a Data Protection Officer (DPO). The DPO is responsible for overseeing our data
            protection strategy and implementation to ensure compliance with GDPR requirements. The DPO can be contacted at support@Makani.services
          </p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">22. California Consumer Privacy Act (CCPA) Compliance</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            In accordance with the California Consumer Privacy Act, we extend the following rights to California residents:
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• The right to know what personal data is being collected about them.</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• The right to request the deletion of their personal data.</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">• The right to opt-out of the sale of their personal information.</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • The right to non-discrimination for exercising their CCPA rights. Requests to exercise these rights can be made by contacting us using the provided contact details.
            We will verify and respond to such requests as required by the CCPA.
          </p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">23. Nevada Privacy Rights</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            Under Nevada law, Nevada residents may opt out of the sale of certain kinds of personal information. If you are a Nevada resident and wish to exercise this right,
            please contact us at the designated contact information.
          </p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">24. Data Breach Notification</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400]">
            In the event of a data breach that is likely to result in a risk to your rights and freedoms, we will notify you and the appropriate authorities without undue delay. We
            are committed to maintaining the highest levels of data security, and in case of any suspected data security breach, we will take all necessary steps to minimize harm
            and inform all affected individuals.
          </p>
        </div>

        <br />
        <div>
          <p className="font-OpenSansRegular text-[14px] text-inputEmailTitle font-[800]">25. Miscellaneous Provisions</p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • Severability: If any provision of this policy is held to be illegal, invalid, or unenforceable, the remaining provisions will remain in full force and effect.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • No Waiver: Our failure to enforce any right or provision of this policy will not be considered a waiver of those rights.
          </p>
          <p className="font-OpenSansRegular text-[12px] text-inputEmailTitle font-[400] ml-3">
            • Interpretation: Any ambiguities in the interpretation of this policy shall not be construed against the drafting party.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
