import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import EmailInput from "src/Components/Authentication/EmailInput";
import PasswordInput from "src/Components/Authentication/PasswordInput";
import BlueButton from "src/Components/Authentication/BlueButton";
import GoogleButton from "src/Components/Authentication/GoogleButton";
import { validateEmail, API_URL } from "src/Common/function";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const backButtonClicked = () => {
    navigate("/");
  };

  const forgotButtonClicked = (e) => {
    if (handleValidation()) {
      setIsLoading(true);
      axios
        .get(API_URL + "/api/auth/forgot-password/" + email)
        .then(function (response) {
          console.log(response);
          if (response.status == 200) {
            toast("Reset Password Email Sent");
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          setIsLoading(false);
          toast(error.response.data.message);
        });
    }
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!validateEmail(email)) {
      errors["email"] = "Email address is invalid";
      formIsValid = false;
    }

    setErrors(errors);
    console.log(errors);
    return formIsValid;
  };

  const emailChanged = (email) => {
    setEmail(email);
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="w-[332px] h-[411px] flex items-center justify-center">
        <div className="w-[296px] h-[400px]">
          <p className="font-OpenSansRegular text-[16px] text-titleColor text-center font-[700]">Forgot password?</p>
          <p className="font-OpenSansRegular text-[12px] text-subTitleColor text-center font-[600] mt-[5px]">
            Fill in your email and we'll send you a link to reset your password.
          </p>
          <div className="mt-[20px]" />
          <EmailInput onEmailChanged={emailChanged} />
          <BlueButton onButtonClicked={forgotButtonClicked} title={"SEND EMAIL"} isLoading={isLoading} />
          <div className="mt-[5px]" />
          <p
            onClick={backButtonClicked}
            className="font-OpenSansRegular text-[10px] text-blueColor font-[400] text-center cursor-pointer"
          >
            Back to Login
          </p>

          <p className="font-OpenSansRegular text-[10px] text-subTitleColor text-center font-[400] mt-[20px]">
            By logging into the Makani Platform, you agree with our Privacy Policy and Terms of Use
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
