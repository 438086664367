import { current } from "@reduxjs/toolkit";
import { isAdmin } from "src/Common/function";
import api from "./api";
import UserService from "./user.service";

//This function is for admin
const getAll = (branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branch.id;
  }
  console.log("🚀 ~ getAll ~ branchId:", branchId);
  return api.get("/api/vendor/get_all", { params: { branchId } });
};

//this function can be used when creating a purchase order
const getAllByBranch = (branchId) => {
  return api.get("/api/vendor/get_all", { params: { branchId } });
};

const saveVendor = (vendor, branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branch.id;
  }
  vendor["branch"] = branchId;
  return api.post("/api/vendor/save", vendor);
};

const deleteVendor = (id) => {
  return api.post("/api/vendor/delete", { vendorId: id });
};

const updateVendor = (vendor) => {
  return api.post("/api/vendor/update", { ...vendor });
};

const VendorService = {
  getAll,
  getAllByBranch,
  saveVendor,
  deleteVendor,
  updateVendor,
};

export default VendorService;
