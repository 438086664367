import api from "./api";

const getAll = () => {
  return api.get("/api/settings/role/getAll");
};

const RoleService = {
  getAll,
};

export default RoleService;
