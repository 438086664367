import { Outlet } from "react-router-dom";
import Header from "./header";

const LayoutWithTopBar = () => {
  return (
    <div className="flex flex-col w-full h-full">
      <Header />
      <Outlet />
    </div>
  );
};

export default LayoutWithTopBar;
