export const WO_TYPE_LIST = ["Service call", "Quoted job"];
export const TECH_TYPE_LIST = ["Primary technician", "Technician"];
export const paymentItems = ["Acct Credit", "Reimbursed"];
export const ITEMS_PER_PAGE = 20;

export const WOStatusList = [
  { name: "Requested", value: 0 },
  { name: "Open", value: 1 },
  { name: "Enroute", value: 2 },
  { name: "Arrived", value: 3 },
  { name: "Parts", value: 4 },
  { name: "Submitted", value: 5 },
  { name: "Sent", value: 6 },
  { name: "Closed", value: 100 },
];

export const POStatusList = [
  { name: "Requested", value: 0 },
  { name: "Issued", value: 2 },
  { name: "Purchased", value: 3 },
  { name: "Completed", value: 4 },
];
