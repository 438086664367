import { getImageUrl } from "src/Common/common";

const WhiteUploadButton = ({ clicked }) => {
  return (
    <div
      onClick={clicked}
      className="rounded-[6px] w-[100px] h-[30px] flex items-center justify-center gap-1 cursor-pointer border-solid border-borderColor border-[1px]"
    >
      <img src={getImageUrl("/images/common/upload_black.png")} />
      <span className="font-OpenSansRegular font-[600] text-[10px] text-[#404A6E]">UPLOAD</span>
    </div>
  );
};

export default WhiteUploadButton;
