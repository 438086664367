import api from "./api";

const getAll = () => {
  return api.get("/api/branch/get-all");
};

const saveBranch = (branch) => {
  return api.post("/api/branch/save", branch);
};

const deleteBranch = (id) => {
  return api.post("/api/branch/delete", { branchId: id });
};

const updateBranch = (branch) => {
  return api.post("/api/branch/update", { ...branch });
};

const BranchService = {
  getAll,
  saveBranch,
  deleteBranch,
  updateBranch,
};

export default BranchService;
