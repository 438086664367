import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";

import UserService from "src/Services/API/user.service";
import { TECH_TYPE_LIST } from "src/Common/contants";

const PONumberAddModal = ({ shown, onCancelClicked, onSaveClicked }) => {
  const [poNumber, setPoNumber] = useState("");

  useEffect(() => {
    setPoNumber("");
  }, [shown]);

  const approvePo = () => {
    if (poNumber.length === 0) return;

    onSaveClicked(poNumber);
  };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center z-30">
        <OutsideClickHandler onOutsideClick={onCancelClicked}>
          <div className="w-[450px] px-5 py-5 bg-white rounded-[6px]">
            <p className="font-OpenSansRegular font-[600] text-[16px] text-titleColor">INSERT PURCHASE ORDER NUMBER</p>
            <p className="font-OpenSansRegular font-[400] text-[12px] text-titleColor mt-[15px]">To approve this request, insert the purchase order number.</p>

            <div className="h-[60px] w-full mt-[30px]">
              <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Purchase Order #</p>
              <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
                <input
                  value={poNumber}
                  type="text"
                  onChange={(e) => setPoNumber(e.target.value)}
                  className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                  placeholder="Enter Purchase Order Number"
                />
              </div>
            </div>

            <div className="w-full flex justify-between gap-3 mt-[15px]">
              <div
                onClick={onCancelClicked}
                className="cursor-pointer w-[50%] h-[30px] rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-inputFieldTextColor border-borderColor border-[1px]"
              >
                CANCEL
              </div>

              <div
                onClick={approvePo}
                className="cursor-pointer w-[50%] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-white"
              >
                APPROVE REQUEST
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default PONumberAddModal;
