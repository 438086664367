import api from "./api";
import TokenService from "./token.service";

const register = (name, email, password, business) => {
  return api.post("/api/auth/register", {
    name,
    email,
    password,
    business,
  });
};

const login = (email, password) => {
  return api.post(
    "/api/auth/login",
    {
      email,
      password,
    },
    { withCredentials: true }
  );
};

const logout = () => {
  TokenService.removeUser();
  TokenService.removeAccessToken();
};

const AuthService = {
  register,
  login,
  logout,
};

export default AuthService;
