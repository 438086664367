import { getImageUrl } from "src/Common/common";

function UploadButton({ onClicked }) {
  return (
    <div
      onClick={onClicked}
      className="bg-blueColor rounded-[6px] w-[89px] h-[30px] flex items-center justify-center gap-1 cursor-pointer"
    >
      <img src={getImageUrl("/images/common/upload_white.png")} />

      <span className="font-OpenSansRegular font-[500] text-[10px] text-white">UPLOAD</span>
    </div>
  );
}

export default UploadButton;
