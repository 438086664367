import { useEffect, useState } from "react";
import { getImageUrl } from "src/Common/common";
import { useDispatch } from "react-redux";
import { setKeyword } from "src/Redux/Slices/KeywordSlice";
import { useDebounce } from "use-debounce";

function SearchBox({ onKewordChanged }) {
  const dispatch = useDispatch();
  const [keywordString, setKeywordString] = useState("");
  const [debouncedKeyword] = useDebounce(keywordString, 1000);

  const keywordChanged = (e) => {
    setKeywordString(e.target.value);
    onKewordChanged();
  };

  useEffect(() => {
    dispatch(setKeyword(keywordString));
  }, [debouncedKeyword]);

  return (
    <div className="flex items-center gap-1">
      <img src={getImageUrl("/images/common/search.png")} />
      <input
        value={keywordString}
        type="text"
        onChange={keywordChanged}
        className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium bg-backgroundColor"
        placeholder="Search"
      />
    </div>
  );
}

export default SearchBox;
