import { getImageUrl } from "src/Common/common";
function CreateButton({ onClicked, title = "CREATE" }) {
  return (
    <div
      onClick={onClicked}
      className=" rounded-[4px] flex items-center gap-2 px-[10px] h-[24px] cursor-pointer justify-between border-borderColor border-[1px]"
    >
      <img src={getImageUrl("/images/common/plus.png")} className="w-[10px]" />
      <span className="text-[#67737E] font-OpenSansRegular text-[12px] font-[600]">{title}</span>
    </div>
  );
}

export default CreateButton;
