import React, { useState, useEffect } from "react";

function BlueButton({ title, onButtonClicked, isLoading }) {
  const [email, setEmail] = useState("");

  useEffect(() => {}, []);

  const onClicked = (e) => {
    onButtonClicked();
  };

  return (
    <div
      onClick={onClicked}
      disabled={isLoading}
      className="w-[296px] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center cursor-pointer"
    >
      <span className="text-white text-[10px] text-OpenSansRegular font-[600]">{title}</span>
    </div>
  );
}

export default BlueButton;
