import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";

import UserService from "src/Services/API/user.service";
import AssignRow from "./assignrow";
import { TECH_TYPE_LIST } from "src/Common/contants";
import { assign } from "lodash";
import { handleError } from "src/Common/function";

const AssignTechModal = ({ shown, onCancelClicked, onSaveClicked, assignedTechs, branch }) => {
  const [userArray, setUserArray] = useState([]);
  const [techArray, setTechArray] = useState([{ user: -1, roleId: -1 }]);
  // const [assignedTechnicians, setAssignedTechnicians] = useState(assignedTechs);

  useEffect(() => {
    let temp = [];
    for (let item of assignedTechs) {
      temp.push({ user: item.user.id, roleId: item.roleId });
    }
    setTechArray(temp);
  }, [assignedTechs]);

  useEffect(() => {
    if (assignedTechs.length === 0) {
      setTechArray([{ user: -1, roleId: -1 }]);
    }

    if (!shown) return;

    UserService.getUsersByRole("Technician", branch)
      .then((response) => {
        console.log("Tech response: ", response.data);
        if (response.status == 200) {
          setUserArray(response.data);
        }
      })
      .catch((error) => handleError(error));

    // UserService.getAll()
    //   .then((response) => {
    //     console.log(response);
    //     if (response.status == 200) {
    //       console.log(response.data);
    //       setUserArray(response.data);
    //     }
    //   })
    //   .catch((error) => {
    //     toast(error.response.data.message);
    //     console.log("User List Fetching Error: ", error);
    //   });
  }, [shown]);

  const techSelected = (userIndex, index) => {
    let temp = [...techArray];
    temp[index].user = userArray[userIndex].id;
    setTechArray(temp);
  };

  const roleSelected = (roleIndex, index) => {
    let temp = [...techArray];
    temp[index].roleId = roleIndex;
    setTechArray(temp);
  };

  const addMore = () => {
    console.log("...techArray: ", ...techArray);
    setTechArray([...techArray, { user: -1, roleId: -1 }]);
  };

  const removeTech = (index) => {
    let temp = [...techArray];
    temp.splice(index, 1);
    setTechArray(temp);
  };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center z-30">
        <OutsideClickHandler
          onOutsideClick={() => {
            onCancelClicked();
          }}
        >
          <div className="w-[550px] px-5 py-5 bg-white rounded-[6px]">
            <p className="text-[16px] font-OpenSansMedium font-[600] text-titleColor mb-5">Assign</p>

            <div className="flex ">
              <p className="w-[50%] text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-5">Team:</p>
              <p className="w-[50%] text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-5">Role:</p>
            </div>
            {techArray.map((item, index) => (
              <AssignRow
                userArray={userArray}
                onUserSelected={techSelected}
                onRoleSelected={roleSelected}
                index={index}
                tech={item}
                key={index}
                remove={removeTech}
              />
            ))}

            <p onClick={addMore} className="font-OpenSansBold text-subTitleColor text-[12px] mt-2 cursor-pointer">
              + Add more
            </p>

            <div className="w-full flex justify-between gap-3 mt-5">
              <div
                onClick={onCancelClicked}
                className="cursor-pointer w-[50%] h-[30px] rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-inputFieldTextColor border-borderColor border-[1px]"
              >
                CANCEL
              </div>

              <div
                onClick={() => onSaveClicked(techArray)}
                className="cursor-pointer w-[50%] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-white"
              >
                SAVE
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default AssignTechModal;
