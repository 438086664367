import OutsideClickHandler from "react-outside-click-handler";
import moment, { isDate } from "moment";
import { useState } from "react";
import DatePickerModal from "src/Common/datepickermodal";

import { getImageUrl } from "src/Common/common";
import { useEffect } from "react";

const UserSelectModal = ({ shown, onCancel, onSave, userArray, selectedUserIdArray }) => {
  const [checkList, setCheckList] = useState([]);

  //   useEffect(() => {
  //     setCheckList([]);
  //   }, [shown]);

  useEffect(() => {
    console.log("selectedUserIdArray: ", selectedUserIdArray);
    let temp = [];
    if (selectedUserIdArray.length === 0) {
      for (let i = 0; i < userArray.length; i++) {
        temp.push(false);
      }
    } else {
      for (let i = 0; i < userArray.length; i++) {
        if (selectedUserIdArray.includes(String(userArray[i].id))) {
          temp.push(true);
        } else {
          temp.push(false);
        }
      }
    }
    setCheckList(temp);
  }, [userArray, selectedUserIdArray]);

  const handleCheckboxChange = (e, index) => {
    console.log("value: ", typeof e.target.value);
    let temp = [...checkList];

    if (e.target.checked) {
      temp[index] = true;
    } else {
      temp[index] = false;
    }

    setCheckList(temp);
  };

  const save = () => {
    let temp = [];
    for (let i = 0; i < userArray.length; i++) {
      if (checkList[i]) {
        temp.push(userArray[i]);
      }
    }
    onSave(temp);
  };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center z-30">
        <OutsideClickHandler
          onOutsideClick={() => {
            onCancel();
          }}
        >
          <div className="w-[400px] h-[500px] rounded-[6px] bg-white p-[18px] flex flex-col ">
            <div className="flex-1 overflow-auto">
              {/* <div className="flex items-center">
                <p className="font-OpenSansBold text-[12px]">Name</p>
                <p className="font-OpenSansBold text-[12px]">Role</p>
              </div> */}
              {userArray.map((user, index) => {
                return (
                  <div key={index} className="w-full h-[30px] flex items-center gap-3">
                    <input
                      type="checkbox"
                      checked={checkList[index]}
                      onChange={(value) => handleCheckboxChange(value, index)}
                    />
                    <p className="font-OpenSansRegular text-[12px]">
                      {user.name} ({user.roles[0].name})
                    </p>
                    <p className="font-OpenSansRegular text-[11px]"></p>
                  </div>
                );
              })}
            </div>

            <div className="w-full flex justify-between gap-3 mt-5">
              <div
                onClick={onCancel}
                className="cursor-pointer w-[50%] h-[30px] rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-inputFieldTextColor border-borderColor border-[1px]"
              >
                CANCEL
              </div>

              <div
                onClick={save}
                className="cursor-pointer w-[50%] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-white"
              >
                SAVE
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default UserSelectModal;
