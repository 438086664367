import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import BlueButton from "src/Components/Common/bluebutton";
import CancelButton from "src/Components/Common/cancelbutton";
import { getImageUrl } from "src/Common/common";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PreviewSTModal = ({ shown, title = "Service Ticket Preview", cancelClicked, pdfUrl }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <OutsideClickHandler onOutsideClick={cancelClicked}>
          <div className="bg-white rounded-md w-[800px] h-[95vh] px-3 py-3 relative">
            <img src={getImageUrl("/images/common/close.png")} className="w-[24px] absolute right-3 top-3 cursor-pointer" onClick={cancelClicked} />
            <p className="font-OpenSansRegular text-[16px] font-[700] text-titleColor mb-5 ml-3 mt-2">{title}</p>
            <div className="h-[calc(100%-50px)]">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
              </Worker>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default PreviewSTModal;
