import { current } from "@reduxjs/toolkit";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import CancelButton from "src/Components/Common/cancelbutton";
import BlueButton from "src/Components/Common/bluebutton";
import UploadButton from "./uploadbutton";
import UserService from "src/Services/API/user.service";
import { API_URL, handleError } from "src/Common/function";
import { getImageUrl, getAvatarUrl } from "src/Common/common";

function Account() {
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const [name, setName] = useState(UserService.getCurrentUser().name);
  const [phone, setPhone] = useState(UserService.getCurrentUser().phone);
  const [isSMSAccept, setIsSMSAccept] = useState(false);
  const [preview, setPreview] = useState(getImageUrl("/images/common/avatar_default.png"));
  const [file, setFile] = useState(null);

  useEffect(() => {
    const currentUser = UserService.getCurrentUser();

    if (currentUser.avatar) {
      setPreview(getAvatarUrl(currentUser.avatar));
      // setPreview(API_URL + "/avatars/" + currentUser.avatar);
    }
  }, []);

  const saveChanges = () => {
    const currentUser = UserService.getCurrentUser();
    currentUser.name = name;
    currentUser.phone = phone;
    UserService.updateUser(currentUser)
      .then((response) => {
        if (response.status == 201) {
          toast("saved successfully");
          UserService.saveCurrentUser(response.data);
          navigate(-1);
        } else {
          toast("Something went wrong");
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const nameChanged = (e) => {
    setName(e.target.value);
  };

  const phoneChanged = (e) => {
    setPhone(e.target.value);
  };

  const avatarClicked = (e) => {
    inputFile.current.click();
  };

  const uploadClicked = () => {
    const currentUser = UserService.getCurrentUser();
    if (file) {
      UserService.uploadAvatar(file, currentUser.id)
        .then((response) => {
          if (response.status == 201) {
            toast("Photo uploaded successfully");
            UserService.saveCurrentUser(response.data);
          } else {
            toast("Something went wrong");
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const fileSelected = (e) => {
    const avatar = e.target.files[0];
    console.log(avatar);
    if (avatar.size > 167772160) {
      toast("File size cannot exceed more than 20MB");
      return;
    }
    setFile(avatar);
    setPreview(URL.createObjectURL(avatar));
    // UserService.uploa;
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="font-OpenSansRegular text-[16px] font-[600] text-titleColor">ACCOUNT INFORMATION</span>
        <div className="flex items-center gap-5">
          <CancelButton onClicked={() => navigate(-1)} />
          <BlueButton onClicked={saveChanges} title="SAVE CHANGES" />
        </div>
      </div>

      <div className="flex items-center w-full gap-4 mt-5">
        <div className="h-[60px] w-[50%]">
          <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Full name</p>
          <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
            <input
              value={name}
              type="text"
              onChange={nameChanged}
              className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
              placeholder="ex: John Smith"
            />
          </div>
        </div>
        <div className="h-[60px] w-[50%]">
          <div className="w-full">
            <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Phone</p>
            <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
              <input
                value={phone}
                type="text"
                onChange={phoneChanged}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="(xx) - xxx xxxx"
              />
            </div>
          </div>
          <div className="w-full flex items-center mt-2 gap-2">
            <input
              value={isSMSAccept}
              type="checkbox"
              onChange={setIsSMSAccept}
              className="text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
            />
            <p className="text-[12px] font-OpenSansMedium font-[500] text-titleColor">
              I would like to receive SMS notifications
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-5 mt-10">
        <img
          onClick={avatarClicked}
          src={preview}
          className="w-[200px] h-[200px] object-contain cursor-pointer rounded-[100px]"
        />
        <input
          onChange={fileSelected}
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          multiple={false}
          accept="image/*"
        />

        <div className="w-[222px] h-[65px] flex flex-col justify-between">
          <UploadButton onClicked={uploadClicked} />
          <p className="font-OpenSansRegular text-[13px] font-[400] text-subTitleColor">
            You can upload images up to 2MB
          </p>
        </div>
      </div>
    </div>
  );
}

export default Account;
