import { getImageUrl } from "src/Common/common";

function EditButton({ clicked }) {
  return (
    <div onClick={clicked} className="flex items-center gap-1 cursor-pointer">
      <img src={getImageUrl("/images/common/edit.png")} className="w-[14px]" />
      <p className="text-[12px] font-OpenSansMedium font-[600] text-subTitleColor">
        Edit
      </p>
    </div>
  );
}

export default EditButton;
