import { useEffect, useState } from "react";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import OutsideClickHandler from "react-outside-click-handler";
import { getPOReceiptUrl, getRealFileName, getUploadUrl } from "src/Common/common";

const PDFPreviewModal = ({ shown, onCancel, PDFs, activeIndex, isReceipt = false }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [selectedURL, setSelectedURL] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(activeIndex);

  useEffect(() => {
    if (PDFs[selectedIndex]) {
      if (isReceipt) {
        setSelectedURL(getPOReceiptUrl(PDFs[selectedIndex]));
      } else {
        setSelectedURL(getUploadUrl(PDFs[selectedIndex]));
      }
    }
  }, [PDFs, selectedIndex, isReceipt]);

  useEffect(() => {
    if (shown) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [shown]);

  useEffect(() => {
    setSelectedIndex(activeIndex);
  }, [activeIndex]);

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bottom-0 right-0 bg-black bg-opacity-50 flex items-center justify-center z-[10000] ">
        <OutsideClickHandler
          onOutsideClick={() => {
            onCancel();
          }}
        >
          <div className="w-screen h-screen relative pt-[30px] pb-[95px] flex flex-col items-center">
            <div
              onClick={onCancel}
              className="w-[40px] h-[40px] rounded-bl-[40px] absolute right-0 top-0 bg-gray-700 cursor-pointer"
            >
              <div className="w-full h-full relative">
                <div className="absolute top-[5px] right-[5px]">
                  <IoClose color="#FFFFFF" size={25} />
                </div>
              </div>
            </div>
            <div className="w-[1200px] h-full rounded-[6px] bg-white p-[18px] ">
              {selectedURL && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer fileUrl={selectedURL} plugins={[defaultLayoutPluginInstance]} />
                </Worker>
              )}
            </div>
            <div className="absolute w-full h-[65px] bg-gray-600 left-0 right-0 bottom-0 flex flex-col items-center">
              <span className="font-OpenSansRegular font-[500] text-[16px] text-white ">{`${getRealFileName(
                PDFs[selectedIndex]
              )}`}</span>
              <div className="w-full flex-auto flex items-center justify-center gap-4">
                <PreviousButton onClick={() => selectedIndex > 0 && setSelectedIndex(selectedIndex - 1)} />
                <span className="font-OpenSansRegular font-[500] text-[16px] text-white ">{`${selectedIndex + 1}/${
                  PDFs.length
                }`}</span>
                <NextButton onClick={() => selectedIndex < PDFs.length - 1 && setSelectedIndex(selectedIndex + 1)} />
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default PDFPreviewModal;

const NextButton = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="w-[30px] h-[30px] rounded-[15px] flex items-center justify-center bg-gray-800 cursor-pointer"
    >
      <ImArrowRight color="#FFFFFF" size={17} />
    </div>
  );
};

const PreviousButton = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="w-[30px] h-[30px] rounded-[15px] flex items-center justify-center bg-gray-800 cursor-pointer"
    >
      <ImArrowLeft color="#FFFFFF" size={17} />
    </div>
  );
};
