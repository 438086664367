import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

import PasswordInput from "src/Components/Authentication/PasswordInput";
import ConfirmPasswordInput from "src/Components/Authentication/ConfirmPasswordInput";
import BlueButton from "src/Components/Authentication/BlueButton";
import { validatePassword, API_URL } from "src/Common/function";

function ForgotPassword() {
  const navigate = useNavigate();
  const { token } = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {}, []);
  const backButtonClicked = () => {
    navigate("/");
  };

  const passwordChanged = (p) => {
    console.log(p);
    setPassword(p);
  };

  const confirmPasswordChanged = (p) => {
    setConfirmPassword(p);
  };

  const blueButtonClicked = () => {
    if (handleValidation()) {
      setIsLoading(true);
      axios
        .post(API_URL + "/api/auth/reset-password", {
          newPasswordToken: token,
          newPassword: password,
        })
        .then(function (response) {
          console.log("response: ", response);
          if (response.status == 200) {
            toast("Password has been reset");
            navigate("/");
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          toast(error.response.data.message);

          setIsLoading(false);
        });
    } else {
      toast(errors);
    }
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (password.length < 6) {
      errors["password"] = "Password must be at least 6 letters";
      formIsValid = false;
    }

    if (password != confirmPassword) {
      errors["confirmPassword"] = "Passwords doesn't match";
      formIsValid = false;
    }

    setErrors(errors);
    console.log(errors);
    return formIsValid;
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="w-[332px] h-[411px] flex items-center justify-center">
        <div className="w-[296px] h-[400px]">
          <p className="font-OpenSansRegular text-[16px] text-titleColor text-center font-[700]">Reset password?</p>
          <p className="font-OpenSansRegular text-[12px] text-subTitleColor text-center font-[600] mt-[5px]">Fill in your new password.</p>
          <div className="mt-[20px]" />
          <PasswordInput onPasswordChanged={passwordChanged} isForgotButton={false} />
          <ConfirmPasswordInput onConfirmpasswordChanged={confirmPasswordChanged} />
          <BlueButton onButtonClicked={blueButtonClicked} title={"RESET PASSWORD"} isLoading={isLoading} />
          <div className="mt-[5px]" />
          <p onClick={backButtonClicked} className="font-OpenSansRegular text-[10px] text-blueColor font-[400] text-center cursor-pointer">
            Back to Login
          </p>

          {/* <p className="font-OpenSansRegular text-[10px] text-subTitleColor text-center font-[400] mt-[20px]">
            By login in Makani Platform, you agree with our Privacy Policy and Terms of Use.
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
