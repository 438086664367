function Button({ onClicked, title = "", isHighlighted = false }) {
  return (
    <div
      onClick={onClicked}
      className={`rounded-[4px] flex items-center px-[10px] h-[24px] cursor-pointer justify-center border-borderColor border-[1px] ${
        isHighlighted && "bg-blueColor border-none"
      }`}
    >
      <span
        className={` font-OpenSansRegular text-[12px] font-[600] ${isHighlighted ? "text-white" : "text-[#67737E]"}`}
      >
        {title}
      </span>
    </div>
  );
}

export default Button;
