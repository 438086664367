import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { API_URL, handleError, isAdmin } from "src/Common/function";
import Button from "src/Components/Button";
import DownloadButton from "src/Components/DownloadButton";
import POService from "src/Services/API/po.service";
import UserService from "src/Services/API/user.service";
import Table from "./Components/table";
import POFitlerView from "src/Components/filter/POFilterView";
import BranchBar from "src/Components/branchbar/BranchBar";

function PO() {
  const user = UserService.getCurrentUser();
  const branch = useSelector((state) => state.Branch.branch);
  const keyword = useSelector((state) => state.Keyword.keyword);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [orderArray, setOrderArray] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadMoreButtonShown, setLoadMoreButtonShown] = useState(false);
  //filter & sort
  const [filterOpen, setFilterOpen] = useState(false);
  const [criteria, setCriteria] = useState(null);
  const [sort, setSort] = useState(null);

  useEffect(() => {
    setPageNumber(0);
    getAllPurchaseOrder([], 0);
  }, [keyword, criteria, sort, branch]);

  const getAllPurchaseOrder = (previousOrderArray, pNumber) => {
    POService.getPurchaseOrder(user.id, keyword, pNumber, criteria, sort, branch)
      .then((response) => {
        console.log("All PO Response: ", response);
        if (response.status == 200) {
          let temp = [...previousOrderArray, ...response.data.orders];
          setOrderArray(temp);
          if (temp.length >= response.data.totalCount) {
            setLoadMoreButtonShown(false);
          } else {
            setLoadMoreButtonShown(true);
          }
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        handleError(error);
      });
  };
  const handleLoadMore = () => {
    getAllPurchaseOrder(orderArray, pageNumber + 1);
    setPageNumber(pageNumber + 1);
  };

  // const savePO = (data) => {
  //   setIsCreateOpen(false);
  //   WOService.createWorkOrder(data)
  //     .then((response) => {
  //       console.log("response: ", response);
  //       if (response.status == 201) {
  //         toast("Work order created successfully");
  //         getAllPurchaseOrder();
  //       } else {
  //         toast("something went wrong!");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error: ", error);
  //       toast(error.response.data.message);
  //     });
  // };

  return (
    <div className="w-full bg-red bg-backgroundColor">
      {/* <div className="h-[60px] flex items-center relative mb-12 border-b-[1px] border-borderColor">
        <span className="font-OpenSansRegular font-[700] text-[24px] ml-5 text-titleColor ">Work Orders</span>
        <div className="absolute right-7 bottom-2 flex items-center gap-8">
          <SearchBox onKewordChanged={keywordChanged} />
        </div>
      </div> */}
      {/* <div className="relative"> */}
      {/* <CreateModal onSubmitClicked={savePO} shown={isCreateOpen} onCancelClicked={() => setIsCreateOpen(false)} /> */}
      <div className="px-7 py-2">{isAdmin() && <BranchBar />}</div>
      <div className="w-full flex items-center gap-8 justify-end h-[62px] px-[24px]">
        {/* <div className="font-OpenSansRegular font-[600] text-[12px] text-subTitleColor cursor-pointer">Filters</div>
        <div className="font-OpenSansRegular font-[600] text-[12px] text-subTitleColor cursor-pointer">
          Visible Fields
        </div> */}
        {/* <Button onClicked={() => setIsCreateOpen(true)} /> */}
        {branch !== 0 && (
          <Button onClicked={() => setFilterOpen(!filterOpen)} title="Filter" isHighlighted={filterOpen} />
        )}
        {branch !== 0 && (
          <a href={`${API_URL}/rscs/reports/${user.id}/POs_report.xlsx`} download>
            <DownloadButton />
          </a>
        )}
      </div>
      {/* </div> */}
      <div className="px-7">
        {filterOpen && branch !== 0 && <POFitlerView apply={(data) => setCriteria(data)} />}
        <Table
          data={orderArray}
          loadMore={handleLoadMore}
          loadMoreButtonShown={loadMoreButtonShown}
          sort={(data) => setSort(data)}
        />
      </div>
    </div>
  );
}

export default PO;
