import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "src/Redux/Slices/AuthSlice";
import HeaderReducer from "src/Redux/Slices/HeaderSlice";
import KeywordReducer from "src/Redux/Slices/KeywordSlice";
import BranchReducer from "src/Redux/Slices/BranchSlice";

export const store = configureStore({
  reducer: {
    Auth: AuthReducer,
    Header: HeaderReducer,
    Keyword: KeywordReducer,
    Branch: BranchReducer,
  },
});
