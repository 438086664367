import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import EmailInput from "src/Components/Authentication/EmailInput";
import PasswordInput from "src/Components/Authentication/PasswordInput";
import BlueButton from "src/Components/Authentication/BlueButton";
import GoogleButton from "src/Components/Authentication/GoogleButton";
import AuthService from "src/Services/API/auth.service";
import TokenService from "src/Services/API/token.service";
import { setAuthState } from "src/Redux/Slices/AuthSlice";
import { validateEmail } from "src/Common/function";
import UserService from "src/Services/API/user.service";

function Signin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, []);
  const signupButtonClicked = () => {
    navigate("/signup");
  };
  const forgotButtonClicked = () => {
    navigate("/forgotpassword");
  };

  const emailChanged = (email) => {
    setEmail(email);
  };

  const passwordChanged = (password) => {
    setPassword(password);
  };

  const signinClicked = () => {
    if (handleValidation()) {
      setIsLoading(true);

      AuthService.login(email, password)
        .then(function (response) {
          if (response.status == 200) {
            console.log("login success: ", response.data);
            // toast("Log in success");
            TokenService.updateLocalAccessToken(response.data.accessToken);
            UserService.saveCurrentUser(response.data.user);
            dispatch(setAuthState(true));
            navigate("/work");
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          if (error.response) {
            toast(error.response.data.message);
          } else {
            toast("An error occured");
          }
          setIsLoading(false);
        });
    } else {
      toast(errors);
    }
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!validateEmail(email)) {
      errors["email"] = "Email address is invalid";
      formIsValid = false;
    }
    if (password == "") {
      errors["password"] = "Password cannot be empty";
      formIsValid = false;
    }

    setErrors(errors);
    console.log(errors);
    return formIsValid;
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="w-[332px] h-[411px] flex items-center justify-center">
        <div className="w-[296px] h-[400px]">
          <p className="font-OpenSansRegular text-[24px] text-titleColor text-center font-[700]">Welcome To Makani</p>
          <p className="font-OpenSansRegular text-[12px] text-subTitleColor text-center font-[600] mt-[5px]">
            Your go-to service management platform
          </p>
          <div className="mt-[20px]" />

          <EmailInput onEmailChanged={emailChanged} />
          <PasswordInput
            onPasswordChanged={passwordChanged}
            isForgotButton={true}
            onForgotClicked={forgotButtonClicked}
          />
          <div className="mt-[5px]" />
          <BlueButton onButtonClicked={signinClicked} title={"LOG IN"} isLoading={isLoading} />
          <div className="mt-[5px]" />
          {/* <p onClick={signupButtonClicked} className="font-OpenSansRegular text-[10px] text-blueColor font-[400] text-center cursor-pointer">
            or Create Account
          </p> */}

          <div className="w-full h-[1px] my-[20px] bg-gray-200" />

          <GoogleButton title="LOGIN WITH GOOGLE" />

          <p className="font-OpenSansRegular text-[10px] text-subTitleColor text-center font-[400] mt-[10px]">
            By logging into the Makani Platform, you agree with our{" "}
            <span className="cursor-pointer" onClick={() => navigate("/privacy")}>
              Privacy Policy
            </span>{" "}
            and{" "}
            <span className="cursor-pointer" onClick={() => navigate("/terms")}>
              Terms of Use
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Signin;
