import RequestedStatus from "./requestedstatus";
import OpenStatus from "./openstatus";
import EnrouteStatus from "./enroutestatus";
import ArrivedStatus from "./arrivedstatus";
import PartsStatus from "./partsstatus";
import ClosedStatus from "./closedstatus";
import SubmitStatus from "./submitstatus";
import SentStatus from "./sentstatus";

const WOStatusComponent = ({ value }) => {
  if (value === 0) {
    return <RequestedStatus />;
  } else if (value === 1) {
    return <OpenStatus />;
  } else if (value === 2) {
    return <EnrouteStatus />;
  } else if (value === 3) {
    return <ArrivedStatus />;
  } else if (value === 4) {
    return <PartsStatus />;
  } else if (value === 5) {
    return <SubmitStatus />;
  } else if (value === 6) {
    return <SentStatus />;
  } else if (value === 100) {
    return <ClosedStatus />;
  }
};

export default WOStatusComponent;
