import { useEffect, useState, useRef } from "react";

import CustomerDropDown from "../dropdown/customerdropdown";
import CustomerService from "src/Services/API/customer.service";
import StatusDropDown from "../dropdown/statusdropdown";
import { WO_TYPE_LIST } from "src/Common/contants";
import UserService from "src/Services/API/user.service";
import { getImageUrl } from "src/Common/common";
import { Puff } from "react-loader-spinner";

function ServiceTicketButton({ clicked, isLoading }) {
  return (
    <div
      onClick={clicked}
      className="w-[120px] h-[30px] rounded-[6px] border-solid border-[1px] border-borderColor flex items-center justify-center cursor-pointer relative"
    >
      <div className="flex gap-1">
        <img
          className="w-[18px] h-[18px]"
          src={getImageUrl("/images/common/view.png")}
        />
        <p className="font-OpenSansRegular font-[600] text-[10px] text-titleColor">
          SERVICE TICKET
        </p>
      </div>
      {isLoading && (
        <div className="flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Puff
            height="20"
            width="20"
            radius={3}
            color="#010D3D"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </div>
  );
}

export default ServiceTicketButton;
