import React, { useState, useEffect, useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";

import { getAvatarUrl } from "src/Common/common";

const UserDropDown = ({ options, selectedUser, onSelect, isOpen }) => {
  const handleUserClick = (index) => {
    onSelect(index);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        onSelect(-1);
      }}
    >
      <div className="relative w-full">
        <div
          onClick={(e) => {
            onSelect(-2);
          }}
          className="w-full flex items-center h-[30px] justify-between px-4 py-2 border rounded-[6px] text-gray-800 focus:outline-none focus:border-blue-500 cursor-pointer"
        >
          {/* <span className="font-OpenSansRegular text-[400] text-[12px] text-titleColor">{defaultOption}</span> */}
          <div>
            {selectedUser && (
              <div className=" py-2 cursor-pointer flex gap-3 items-center">
                <img src={getAvatarUrl(selectedUser.avatar)} className="w-[15px] h-[15px] rounded-[7.5px]" />
                <span className="font-OpenSansRegular text-[12px]">{selectedUser.name}</span>
              </div>
            )}
          </div>
          <FiChevronDown className="ml-2" size={15} />
        </div>
        {isOpen && (
          <ul className="absolute z-10 mt-2 w-full bg-white border rounded-md shadow-lg max-h-[300px] overflow-y-auto">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleUserClick(index)}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200 flex gap-3 items-center"
              >
                <img src={getAvatarUrl(option.avatar)} className="w-[15px] h-[15px] rounded-[7.5px]" />
                <span className="font-OpenSansRegular text-[12px]">{option.name}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default UserDropDown;
