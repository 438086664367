import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";

import BlueButton from "src/Components/Common/bluebutton";
import CancelButton from "src/Components/Common/cancelbutton";

const DatePickerModal = ({ shown, cancelClicked, dateSelected, pSelectedDate, timeVisible = true }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (pSelectedDate) {
      setSelectedDate(Date.parse(pSelectedDate));
    } else {
      setSelectedDate(new Date());
    }
  }, [pSelectedDate]);

  return (
    shown && (
      <OutsideClickHandler onOutsideClick={cancelClicked}>
        <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <div className="bg-white rounded-md ">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {timeVisible ? (
                <StaticDateTimePicker
                  orientation="landscape"
                  value={dayjs(selectedDate)}
                  onChange={(newValue) => {
                    console.log(newValue, typeof newValue);
                    setSelectedDate(newValue);
                  }}
                  onAccept={() => dateSelected(selectedDate)}
                  onClose={() => cancelClicked()}
                />
              ) : (
                <StaticDatePicker
                  orientation="landscape"
                  value={dayjs(selectedDate)}
                  onChange={(newValue) => {
                    console.log(newValue, typeof newValue);
                    setSelectedDate(newValue);
                  }}
                  onAccept={() => dateSelected(selectedDate)}
                  onClose={() => cancelClicked()}
                />
              )}
            </LocalizationProvider>

            {/* <DateTimePicker /> */}

            {/* <DatePicker
              isOpen={true}
              // onClose={() => setIsDatePickerOpen(false)}
              // defaultValue={new Date()}
              // minDate={new Date()}
              selected={selectedDate}
              // headerFormat="DD, MM dd"
              dateFormat="dd/MM/yyyy h:mm aa"
              onChange={(date) => {
                setSelectedDate(date);
                console.log("selected date: ", date);
              }}
              colorScheme="#010D3D"
              showTimeInput
              inline
            /> */}
            {/* <div className="flex items-center justify-between">
              <CancelButton onClicked={cancelClicked} />
              <BlueButton
                title="Save"
                onClicked={() => dateSelected(selectedDate)}
              />
            </div> */}
          </div>
        </div>
      </OutsideClickHandler>
    )
  );
};

export default DatePickerModal;
