import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  branch: 0,
};

export const BranchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    setBranch: (state, action) => {
      state.branch = action.payload;
    },
  },
});

export const { setBranch } = BranchSlice.actions;

export default BranchSlice.reducer;
