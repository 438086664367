import React, { useEffect, useState } from "react";
import InviteModal from "./InviteModal/invitemodal";
import BranchService from "src/Services/API/branch.service";
import { toast } from "react-toastify";
import UserService from "src/Services/API/user.service";
import InviteService from "src/Services/API/invite.service";
import RoleService from "src/Services/API/role.service";
import BlueButton from "src/Components/Common/bluebutton";
import { formatDate } from "src/Common/common";
import AcceptStatus from "./Status/acceptstatus";
import PendingStatus from "./Status/pendingstatus";
import { useSelector } from "react-redux";
import { isAdmin } from "src/Common/function";

const Invite = () => {
  const branch = useSelector((state) => state.Branch.branch);
  let currentUser = UserService.getCurrentUser();
  const [inviteModalShown, setInviteModalShown] = useState(false);
  const [branchArray, setBranchArray] = useState([]);
  const [roleArray, setRoleArray] = useState([]);
  const [inviteArray, setInviteArray] = useState([]);

  useEffect(() => {
    BranchService.getAll()
      .then((response) => {
        console.log("Role list: ", response);
        if (response.status == 200) {
          setBranchArray(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });

    RoleService.getAll()
      .then((response) => {
        console.log("Role list: ", response);
        if (response.status == 200) {
          setRoleArray(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });

    getAllInvites();
  }, [branch]);

  const getAllInvites = () => {
    InviteService.getAll(branch)
      .then((response) => {
        console.log("Invite list: ", response);
        if (response.status == 200) {
          setInviteArray(response.data);
        }
      })
      .catch((error) => {
        toast("An error occured");
      });
  };

  const sendInvite = (email, role, branch) => {
    InviteService.create({ invitedBy: currentUser, email, role, branch, business: currentUser.business })
      .then((response) => {
        getAllInvites();
        toast("User invited successfully");
      })
      .catch((error) => {
        if (error.response) {
          toast(error.response.data.message);
        } else {
          toast("An error occured");
        }
      });

    setInviteModalShown(false);
  };

  const onCancelClicked = (index) => {
    InviteService.deleteInvite(inviteArray[index].id)
      .then((response) => {
        getAllInvites();
        toast("Invite cancelled successfully");
      })
      .catch((error) => {
        toast("An error occured");
      });
  };

  return (
    <div className="w-full">
      <InviteModal
        sendInvite={sendInvite}
        shown={inviteModalShown}
        roleArray={roleArray}
        branchArray={branchArray}
        onCancelClicked={() => setInviteModalShown(false)}
      />
      <div className="w-full flex items-center relative h-[30px]">
        {currentUser.roles.some((role) => role.id === 1) && ( //show invite button only if user is admin
          <div className="absolute right-10 ">
            <BlueButton onClicked={() => setInviteModalShown(true)} title="INVITE USER" />
          </div>
        )}
      </div>
      <table className="w-full mt-3">
        <thead>
          <tr className="border-t-[1px] border-b-[1px] border-borderColor">
            <th className="border-r-[1px] border-borderColor w-[30%]">
              <div className="font-OpenSansRegular  font-[700] text-[12px] flex items-center px-4 h-[40px] text-titleColor">
                <span>Email</span>
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor w-[20%]">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 h-[40px] text-titleColor">
                Role
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor w-[25%]">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center pl-4 h-[40px] text-titleColor">
                Status
              </div>
            </th>
            <th className="w-[25%] border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center px-2 h-[40px] text-titleColor">
                InvitedAt
              </div>
            </th>
            <th className="w-[100px]">
              <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center px-2 h-[40px] text-titleColor">
                ACTION
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {inviteArray.map((invite, i) => (
            <tr key={i} className="border-b-[1px] border-borderColor h-[40px]">
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {invite.email}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {invite.role.name}
                </div>
              </td>

              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {invite.accepted ? <AcceptStatus /> : <PendingStatus />}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[15px] py-[5px] text-[11px] font-[400] col-span-1">
                  {formatDate(invite.createdAt)}
                </div>
              </td>
              <td>
                <div
                  onClick={() => onCancelClicked(i)}
                  className="w-[70px] h-[25px] text-[12px] rounded-[6px] border-[1px] border-subTitleColor flex items-center justify-center text-subTitleColor cursor-pointer"
                >
                  CANCEL
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Invite;
