import { current } from "@reduxjs/toolkit";
import api from "./api";

const get = () => {
  return api.get("/api/company/get");
};

const save = (company) => {
  return api.post("/api/company/save", company);
};

const update = (company) => {
  return api.post("/api/company/update", company);
};

const CompanyService = {
  get,
  save,
  update,
};

export default CompanyService;
