import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { formatDate, getImageUrl, toLocalDate } from "src/Common/common";
import ConfirmDialog from "src/Common/confirmdialog";
import { getAssignedTechsNameArray, handleError } from "src/Common/function";
import LoadMoreButton from "src/Components/Common/loadmorebutton";
import { setWONumber } from "src/Redux/Slices/HeaderSlice";
import UserService from "src/Services/API/user.service";
import WOService from "src/Services/API/wo.service";
import WOStatusComponent from "./Status/wostatuscomponent";
import QuotedType from "./Types/quotedtype";
import ServiceCallType from "./Types/servicecalltype";

function Table({ data, loadMore, loadMoreButtonShown, deleteSuccess, sort }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentUser] = useState(UserService.getCurrentUser());
  const [woArray, setWOArray] = useState([]);
  const [isDeleteConfirmDialogShown, setIsDeleteConfirmDialogShown] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(0);

  //sorting
  const [sortByDate, setSortByDate] = useState(0);

  useEffect(() => {
    let woData = _.cloneDeep(data);
    for (let i = 0; i < woData.length; i++) {
      // if (woData[i].assignedTechs) {
      //   let primaryTech = woData[i].assignedTechs.find((obj) => obj.roleId === 0);
      //   if (primaryTech) {
      //     let currentIndex = woData[i].assignedTechs.findIndex((obj) => obj.roleId === 0);
      //     woData[i].assignedTechs.splice(currentIndex, 1);
      //     woData[i].assignedTechs.splice(0, 0, primaryTech);
      //   }
      //   let techNames = woData[i].assignedTechs.map((obj) => obj.user.name);
      //   woData[i].techNames = techNames.join();
      // }
      woData[i].techNames = getAssignedTechsNameArray(woData[i].assignedTechs);
    }
    setWOArray(woData);
  }, [data]);

  const deleteWorkOrder = () => {
    WOService.deleteById(woArray[indexToDelete].id)
      .then((response) => {
        console.log("delete res: ", response);
        if (response.status == 200) {
          setIsDeleteConfirmDialogShown(false);
          deleteSuccess(indexToDelete);
          toast("Work order deleted successfully");
        }
      })
      .catch((error) => {
        setIsDeleteConfirmDialogShown(false);
        handleError(error);
      });
  };

  return (
    <>
      <ConfirmDialog
        title="DELETE WORK ORDER"
        description="Are you sure you want to delete this?"
        shown={isDeleteConfirmDialogShown}
        onConfirmClicked={deleteWorkOrder}
        onCancelClicked={() => setIsDeleteConfirmDialogShown(false)}
      />
      <table>
        <thead>
          <tr className="border-t-[1px] border-b-[1px] border-borderColor">
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[13px] flex items-center justify-center pl-4 w-[100px] h-[40px] text-titleColor text-center">
                <span>WO#</span>
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[13px] flex items-center justify-center w-[120px] h-[40px] text-titleColor">
                Type
              </div>
            </th>
            {/* <th className="border-r-[1px] border-borderColor">
            <div className="font-OpenSansRegular font-[700] text-[13px] flex items-center px-2 w-[120px] h-[40px] text-titleColor">
              Category
            </div>
          </th> */}
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[13px] flex items-center pl-4 w-[200px] h-[40px] text-titleColor">
                Customer
              </div>
            </th>
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[13px] flex items-center w-[60px] h-[40px] text-titleColor justify-center">
                NTE
              </div>
            </th>
            <th className="w-min-[200px] w-full border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[13px] flex items-center pl-4  h-[40px] text-titleColor ">
                Description
              </div>
            </th>
            <th
              onClick={() => {
                if (sortByDate === 1) {
                  setSortByDate(0);
                  sort({ date: 0 });
                } else if (sortByDate === 0) {
                  setSortByDate(1);
                  sort({ date: 1 });
                }
              }}
              className="w-full border-r-[1px] border-borderColor cursor-pointer flex items-center"
            >
              <div className="font-OpenSansRegular font-[700] text-[13px] flex items-center justify-center w-[140px] h-[40px] text-titleColor ">
                Date Started
              </div>
              {sortByDate === 0 ? <IoIosArrowRoundUp /> : <IoIosArrowRoundDown />}
            </th>
            <th className="border-r-[1px] border-borderColor">
              <div className="font-OpenSansRegular font-[700] text-[13px] flex items-center pl-4 w-[200px] h-[40px] text-titleColor">
                Technician
              </div>
            </th>
            <th>
              <div className="font-OpenSansRegular font-[700] text-[13px] flex items-center pl-4 w-[130px] h-[40px] text-titleColor">
                Status
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {woArray.map((order, index) => (
            <tr
              key={index}
              onClick={() => {
                dispatch(setWONumber(String(order.number)));
                navigate("/work/" + String(order.number), {
                  state: { order: order },
                });
              }}
              className="border-b-[1px] border-borderColor h-[40px] cursor-pointer"
            >
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[5px] py-[5px] text-center text-[11px] font-[400] col-span-1">
                  {order.number}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor">
                <div className="mx-auto flex justify-center">
                  {order.type == 0 ? <ServiceCallType /> : <QuotedType />}
                </div>
              </td>
              {/* <td className="border-r-[1px]  border-borderColor">
              <div className="font-OpenSansRegular px-[10px] py-[5px] text-[11px] font-[400]">{order.category}</div>
            </td> */}
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[10px] py-[5px] text-[11px] font-[400] word-break-break-word">
                  {order.customer?.company}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor">
                <div className="font-OpenSansRegular px-[10px] py-[5px] text-[11px] font-[400] text-center">
                  {order.NTE}
                </div>
              </td>

              <td className="border-r-[1px]  border-borderColor ">
                <div className="font-OpenSansRegular px-[10px] py-[5px] text-[11px] text-ellipsis word-break-break-word">
                  {order.description}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor ">
                <div className="font-OpenSansRegular px-[10px] py-[5px] text-[11px] text-ellipsis text-center">
                  {order.startDate && moment(order.startDate).format("MM/DD/YYYY")}
                </div>
              </td>
              <td className="border-r-[1px]  border-borderColor ">
                <div className="font-OpenSansRegular px-[10px] py-[5px] text-[11px] text-ellipsis">
                  {order.techNames}
                </div>
              </td>
              <td>
                <div className="px-[10px] py-[5px] flex items-center gap-3">
                  <WOStatusComponent value={order.status} />
                  {currentUser.roles[0].id <= 1 && (
                    <img
                      onClick={(event) => {
                        setIsDeleteConfirmDialogShown(!isDeleteConfirmDialogShown);
                        setIndexToDelete(index);
                        event.stopPropagation();
                      }}
                      src={getImageUrl("/images/common/trash.png")}
                      className="cursor-pointer"
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="w-full mt-[10px] mb-[10px]">{loadMoreButtonShown && <LoadMoreButton onClicked={loadMore} />}</div>
    </>
  );
}

export default Table;
