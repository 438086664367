import React, { useState, useEffect, useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";

const RoleDropDown = ({ selectedRole, onSelect, isOpen }) => {
  const handleTypeClick = (index) => {
    onSelect(index);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        onSelect(-1);
      }}
    >
      <div className="relative w-full">
        <button
          onClick={(e) => {
            onSelect(-2);
          }}
          className="w-full flex items-center h-[30px] justify-between px-4 py-2 border rounded-[6px] text-gray-800 focus:outline-none focus:border-blue-500"
        >
          <span className="font-OpenSansRegular text-[400] text-[12px] text-titleColor">{selectedRole}</span>
          <FiChevronDown className="ml-2" size={15} />
        </button>
        {isOpen && (
          <ul className="absolute z-10 mt-2 w-full bg-white border rounded-md shadow-lg">
            <li
              key={0}
              onClick={() => handleTypeClick(0)}
              className="px-4 py-2 cursor-pointer hover:bg-gray-200 font-OpenSansRegular text-[12px]"
            >
              Primary technician
            </li>

            <li
              key={1}
              onClick={() => handleTypeClick(1)}
              className="px-4 py-2 cursor-pointer hover:bg-gray-200 font-OpenSansRegular text-[12px]"
            >
              Technician
            </li>
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default RoleDropDown;
