function LoadMoreButton({ onClicked }) {
  return (
    <div
      onClick={onClicked}
      className="w-full cursor-pointer px-[20px] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-white"
    >
      <span>Load More</span>
    </div>
  );
}

export default LoadMoreButton;
