import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
// import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { StaticTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import BlueButton from "src/Components/Common/bluebutton";
import CancelButton from "src/Components/Common/cancelbutton";

const TimePickerModal = ({ shown, cancelClicked, dateSelected, pSelectedDate }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ pSelectedDate:", pSelectedDate);
    if (pSelectedDate) {
      setSelectedDate(Date.parse(pSelectedDate));
    } else {
      setSelectedDate(new Date());
    }
  }, [pSelectedDate]);

  return (
    shown && (
      <OutsideClickHandler onOutsideClick={cancelClicked}>
        <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 flex items-center justify-center z-20">
          <div className="bg-white rounded-md ">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticTimePicker
                orientation="landscape"
                value={dayjs(selectedDate)}
                onChange={(newValue) => {
                  console.log(newValue, typeof newValue);
                  setSelectedDate(newValue);
                }}
                onAccept={() => dateSelected(selectedDate)}
                onClose={() => cancelClicked()}
              />
            </LocalizationProvider>
          </div>
        </div>
      </OutsideClickHandler>
    )
  );
};

export default TimePickerModal;
