import { getImageUrl } from "src/Common/common";

function PencilButton({ clicked }) {
  return (
    <div
      onClick={clicked}
      className="cursor-pointer w-[30px] h-[30px] rounded-[6px] border-[1px] flex items-center justify-center"
    >
      <img
        src={getImageUrl("/images/common/blueedit.png")}
        className="w-[18px]"
      />
    </div>
  );
}

export default PencilButton;
