import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import _ from "lodash";
import BlueButton from "src/Components/Common/bluebutton";
import CancelButton from "src/Components/Common/cancelbutton";
import { getImageUrl, getAvatarUrl, getUploadUrl, getRealFileName } from "src/Common/common";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import DurationPickerModal from "./durationpickermodal";

const ServiceTicketEditModal = ({ shown, saveClicked, cancelClicked, pOrder }) => {
  // const [isSTEdited, setIsSTEdited] = useState(false);
  const [order, setOrder] = useState(pOrder);
  const [ticketReceipients, setTicketReceipients] = useState(order.ticketReceipients);
  const [servicesProvided, setServicesProvided] = useState(order.servicesProvided);
  const [recommendations, setRecommendations] = useState(order.recommendations);
  const [materials, setMaterials] = useState(JSON.parse(order.materials));

  //TimePicker
  const [initialDurationText, setInitialDurationText] = useState("08:00");
  const [isTimePickerVisible, setIsTimePickerVisible] = useState(false);
  const [editingTechId, setEditingTechId] = useState(0);
  const [isRegular, setIsRegular] = useState(true);

  useEffect(() => {
    setOrder(pOrder);
    setMaterials(JSON.parse(pOrder.materials));
    setTicketReceipients(pOrder.ticketReceipients);
    setServicesProvided(pOrder.servicesProvided);
    setRecommendations(pOrder.recommendations);
  }, [pOrder]);

  const save = () => {
    if (!checkIfEdited()) return;

    const draftData = {
      timesheetData: JSON.stringify(order.assignedTechs),
      servicesProvided,
      ticketReceipients,
      recommendations,
      isServiceTicketEdited: true,
    };

    saveClicked(draftData, materials);
  };

  const checkIfEdited = () => {
    let isEdited = false;

    for (let i = 0; i < order.assignedTechs.length; i++) {
      if (pOrder.assignedTechs[i].totalTimesheet !== order.assignedTechs[i].totalTimesheet) {
        isEdited = true;
      }
    }

    if (
      pOrder.ticketReceipients !== ticketReceipients ||
      pOrder.servicesProvided !== servicesProvided ||
      pOrder.recommendations !== recommendations
    ) {
      isEdited = true;
    }

    if (order.materials) {
      let originalMaterials = JSON.parse(order.materials);
      for (let i = 0; i < materials.length; i++) {
        if (
          materials[i].description !== originalMaterials[i].description ||
          materials[i].quantity !== originalMaterials[i].quantity
        ) {
          isEdited = true;
        }
      }
    }

    return isEdited;
  };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
        <OutsideClickHandler onOutsideClick={cancelClicked}>
          <DurationPickerModal
            shown={isTimePickerVisible}
            initialText={initialDurationText}
            onClose={() => setIsTimePickerVisible(false)}
            onSave={(durationText) => {
              // setIsTimePickerVisible(false);

              // let temp = _.cloneDeep(totalTime);
              // if (isRegular) {
              //   temp[editingTechId].regularTime = durationText;
              // } else {
              //   temp[editingTechId].overTime = durationText;
              // }
              // setTotalTime(temp);

              setIsTimePickerVisible(false);

              let temp = _.cloneDeep(order);
              let index = temp.assignedTechs.findIndex((obj) => obj.id === editingTechId);

              let totalTimesheet = temp.assignedTechs[index].totalTimesheet
                ? JSON.parse(temp.assignedTechs[index].totalTimesheet)
                : {};
              if (isRegular) {
                totalTimesheet.regularTime = durationText;
                temp.assignedTechs[index].totalTimesheet = JSON.stringify(totalTimesheet);
              } else {
                totalTimesheet.overTime = durationText;
                temp.assignedTechs[index].totalTimesheet = JSON.stringify(totalTimesheet);
              }
              setOrder(temp);
            }}
          />
          <div className="bg-white rounded-md w-[800px] pt-7 pb-3 px-3 relative">
            <img
              src={getImageUrl("/images/common/close.png")}
              className="w-[24px] absolute right-3 top-3 cursor-pointer"
              onClick={cancelClicked}
            />
            <p className="font-OpenSansRegular text-[24px] font-[700] text-titleColor">Edit Service Ticket:</p>
            <p className="font-OpenSansRegular text-[14px] font-[400] text-blueColor">
              You are now editing this Service Ticket.
            </p>

            <p className="font-OpenSansRegular text-[16px] font-[600] text-inputEmailTitle mt-5">Send to:</p>
            <div className="w-full h-[40px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px] mt-2">
              <input
                value={ticketReceipients}
                type="text"
                onChange={(e) => setTicketReceipients(e.target.value)}
                className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                placeholder="receipient1@gmail.com,receipient2@gmail.com..."
              />
            </div>

            <p className="font-OpenSansRegular text-[16px] font-[600] text-inputEmailTitle mt-5">Services Provided:</p>
            <div className="w-full h-[50px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px] mt-2">
              <textarea
                value={servicesProvided}
                type="text"
                onChange={(e) => setServicesProvided(e.target.value)}
                className="w-full h-[40px] text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium resize-none"
                placeholder="Lorem ipsum dolor sit amet consectetur. Consectetur varius vitae duis 
                ipsum laoreet pretium fringilla rutrum. Massa sem faucibus est lacinia quam vitae nunc in. "
              />
            </div>

            <p className="font-OpenSansRegular text-[16px] font-[600] text-inputEmailTitle mt-5">Recommendations:</p>
            <div className="w-full h-[50px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px] mt-2">
              <textarea
                value={recommendations}
                type="text"
                onChange={(e) => setRecommendations(e.target.value)}
                className="w-full h-[40px] text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium resize-none"
                placeholder="Lorem ipsum dolor sit amet consectetur. Consectetur varius vitae duis 
                ipsum laoreet pretium fringilla rutrum. Massa sem faucibus est lacinia quam vitae nunc in. "
              />
            </div>

            <div className="border-[1px] border-borderColor mt-5">
              <div className="h-[30px] w-full flex items-center pl-2">
                <p className="font-OpenSansRegular text-[16px] font-[700] text-titleColor">Timesheet</p>
              </div>
              <div className="h-[30px] w-full flex items-center bg-backgroundColor border-t-[1px] border-borderColor relative">
                <p className="font-OpenSansRegular text-[12px] font-[00] text-subTitleColor absolute right-[30%] top-[5px]">
                  REGULAR TIME
                </p>
                <p className="font-OpenSansRegular text-[12px] font-[00] text-subTitleColor absolute right-[10%] top-[5px]">
                  OVER TIME
                </p>
              </div>
              {order.assignedTechs.map((tech, index) => (
                <div
                  key={index}
                  className="w-full h-[50px] flex items-center px-3 border-t-[1px] border-borderColor relative"
                >
                  <img src={getAvatarUrl(tech.user.avatar)} className="w-[24px] rounded-[12px]" />
                  <p className="font-OpenSansRegular text-[16px] font-[400] text-titleColor ml-2">{tech.user.name}</p>

                  <p
                    onClick={() => {
                      if (tech.totalTimesheet) {
                        setInitialDurationText(JSON.parse(tech.totalTimesheet).regularTime);
                      }
                      setEditingTechId(tech.id);
                      setIsRegular(true);
                      setIsTimePickerVisible(true);
                    }}
                    className="font-OpenSansRegular text-[16px] font-[600] text-titleColor absolute right-[33%] top-[12px] cursor-pointer"
                  >
                    {JSON.parse(tech.totalTimesheet)?.regularTime
                      ? JSON.parse(tech.totalTimesheet).regularTime
                      : "__:__"}
                  </p>

                  <p
                    onClick={() => {
                      if (tech.totalTimesheet) {
                        setInitialDurationText(JSON.parse(tech.totalTimesheet).overTime);
                      }

                      setEditingTechId(tech.id);
                      setIsRegular(false);
                      setIsTimePickerVisible(true);
                    }}
                    className="font-OpenSansRegular text-[16px] font-[600] text-titleColor  absolute right-[12%] top-[12px] cursor-pointer"
                  >
                    {JSON.parse(tech.totalTimesheet)?.overTime ? JSON.parse(tech.totalTimesheet).overTime : "__:__"}
                  </p>
                </div>
              ))}
            </div>
            <div className="border-[1px] border-borderColor mt-5">
              <div className="h-[30px] w-full flex items-center pl-2">
                <p className="font-OpenSansRegular text-[16px] font-[700] text-titleColor">Other Materials</p>
              </div>
              <table className="w-full">
                <thead>
                  <tr className="border-t-[1px] border-b-[1px] border-borderColor">
                    <th className="border-r-[1px] border-borderColor w-[50%]">
                      <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center justify-center h-[25px] text-titleColor text-center">
                        <span>Description</span>
                      </div>
                    </th>
                    <th className="border-r-[1px] border-borderColor w-[10%]">
                      <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center justify-center   h-[25px] text-titleColor text-center">
                        <span>Quantity</span>
                      </div>
                    </th>
                    <th className="border-borderColor w-[40%]">
                      <div className="font-OpenSansRegular font-[700] text-[12px] flex items-center justify-center h-[25px] text-titleColor text-center">
                        <span>Attachment</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {materials &&
                    materials.map((material, index) => (
                      <tr key={index} className="border-b-[1px] border-borderColor h-[40px]">
                        <td className="border-r-[1px]  border-borderColor">
                          <input
                            value={material.description}
                            onChange={(e) => {
                              let temp = [...materials];
                              temp[index].description = e.target.value;
                              setMaterials(temp);
                            }}
                            type="text"
                            className="w-full font-OpenSansRegular px-[5px] py-[5px] text-center text-[10px] font-[400] col-span-1"
                          />
                        </td>
                        <td className="border-r-[1px]  border-borderColor">
                          <input
                            value={material.quantity}
                            onChange={(e) => {
                              let temp = [...materials];
                              temp[index].quantity = e.target.value;
                              setMaterials(temp);
                            }}
                            type="text"
                            className="w-full font-OpenSansRegular px-[5px] py-[5px] text-center text-[10px] font-[400] col-span-1"
                          />
                        </td>
                        <td className="border-borderColor">
                          {material.attachment && (
                            <a
                              href={getUploadUrl(material.attachment)}
                              target="_blank"
                              download={getRealFileName(material.attachment)}
                            >
                              <p className="font-OpenSansRegular font-[400] text-[11px] text-titleColor text-center">
                                {getRealFileName(material.attachment)}
                              </p>
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="flex items-center justify-end mt-3 gap-3">
              <div
                onClick={cancelClicked}
                className="cursor-pointer w-[198px] h-[30px] border-[#E6E7EC] border-[1px] rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-inputEmailTitle"
              >
                CANCEL
              </div>
              <div
                onClick={save}
                className="cursor-pointer w-[198px] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-white"
              >
                ACCEPT CHANGES
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default ServiceTicketEditModal;
