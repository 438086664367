import api from "./api";

const createTechnician = (data) => {
  return api.post("/api/technician/create", data);
};

const updateBulkTechnician = (data) => {
  return api.post("/api/technician/update_bulk_technician", data);
};

const deleteTechnician = (data) => {
  return api.post("/api/technician/delete", data);
};

const TechnicianService = {
  createTechnician,
  updateBulkTechnician,
  deleteTechnician,
};

export default TechnicianService;
