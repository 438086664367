import OutsideClickHandler from "react-outside-click-handler";
import moment, { isDate } from "moment";
import { useState } from "react";
import DatePickerModal from "src/Common/datepickermodal";

import { getImageUrl } from "src/Common/common";
import { useEffect } from "react";

const ScheduleModal = ({
  shown,
  onCancel,
  onSaveClicked,
  pStartDate,
  pTargetDate,
}) => {
  const [startDate, setStartDate] = useState(pStartDate);
  const [targetDate, setTargetDate] = useState(pTargetDate);
  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false);
  const [isTargetDatePickerOpen, setIsTargetDatePickerOpen] = useState(false);

  const formatDate = (dateString) => {
    const timestamp = Date.parse(dateString);
    // return moment(timestamp).format("MMM DD, YYYY - HH:MM A");
    return moment(timestamp).format("DD/MM/yyyy - h:mm A");
  };

  useEffect(() => {
    setStartDate(pStartDate);
    setTargetDate(pTargetDate);
  }, [pStartDate, pTargetDate]);

  // const formatTime = (dateString) => {
  //   const timestamp = Date.parse(dateString);
  //   return moment(timestamp).format("HH:MM TT");
  // };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center z-30">
        <OutsideClickHandler
          onOutsideClick={() => {
            onCancel();
          }}
        >
          <div className="w-[448px] h-[252px] rounded-[6px] bg-white p-[18px]">
            {/* <DatePicker
              isOpen={isDatePickerOpen}
              onClose={() => setIsDatePickerOpen(false)}
              defaultValue={new Date()}
              minDate={new Date()}
              headerFormat="DD, MM dd"
              onChange={(date) => isStart && setStartDate(date)}
              colorScheme="#010D3D"
            /> */}
            <p className="text-[16px] font-OpenSansMedium font-[600] text-titleColor mb-5">
              SCHEDULE
            </p>
            <div className="w-full flex gap-3">
              <div className="w-[200px]">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-5">
                  Start Date
                </p>
                <div
                  onClick={() => setIsStartDatePickerOpen(true)}
                  className="w-full h-[30px] border-borderColor border-[1px] rounded-[6px] flex items-center px-1 gap-1"
                >
                  <img src={getImageUrl("/images/wo/date.png")} />
                  {startDate ? (
                    <p className="text-[12px] font-OpenSansMedium font-[400] text-titleColor">
                      {formatDate(startDate)}
                    </p>
                  ) : (
                    <p className="text-[12px] font-OpenSansMedium font-[400] text-inputFieldTextColor">
                      Start date
                    </p>
                  )}
                </div>
                {isStartDatePickerOpen && (
                  <DatePickerModal
                    shown={isStartDatePickerOpen}
                    pSelectedDate={startDate}
                    cancelClicked={() => setIsStartDatePickerOpen(false)}
                    dateSelected={(date) => {
                      setStartDate(date);
                      setIsStartDatePickerOpen(false);
                    }}
                  />
                  // <OutsideClickHandler
                  //   onOutsideClick={() => {
                  //     setIsStartDatePickerOpen(false);
                  //   }}
                  // >
                  //   <DatePicker
                  //     selected={startDate}
                  //     onChange={(date) => {
                  //       setStartDate(date);
                  //       setIsStartDatePickerOpen(false);
                  //     }}
                  //     timeInputLabel="Time:"
                  //     dateFormat="MM/dd/yyyy h:mm aa"
                  //     showTimeInput
                  //     inline
                  //   />
                  // </OutsideClickHandler>
                )}
              </div>
              <div className="w-[200px]">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-5">
                  Target Date
                </p>
                <div
                  onClick={() => setIsTargetDatePickerOpen(true)}
                  className="w-full h-[30px] border-borderColor border-[1px] rounded-[6px] flex items-center px-1 gap-1"
                >
                  <img src={getImageUrl("/images/wo/date-target.png")} />

                  {targetDate ? (
                    <p className="text-[12px] font-OpenSansMedium font-[400] text-titleColor">
                      {formatDate(targetDate)}
                    </p>
                  ) : (
                    <p className="text-[12px] font-OpenSansMedium font-[400] text-inputFieldTextColor">
                      Target date
                    </p>
                  )}
                </div>
                {isTargetDatePickerOpen && (
                  <DatePickerModal
                    shown={isTargetDatePickerOpen}
                    cancelClicked={() => setIsTargetDatePickerOpen(false)}
                    pSelectedDate={targetDate}
                    dateSelected={(date) => {
                      setTargetDate(date);
                      setIsTargetDatePickerOpen(false);
                    }}
                  />
                )}
                {/* {isTargetDatePickerOpen && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setIsTargetDatePickerOpen(false);
                    }}
                  >
                    <DatePicker
                      selected={targetDate}
                      onChange={(date) => {
                        setTargetDate(date);
                        setIsTargetDatePickerOpen(false);
                      }}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      inline
                    />
                  </OutsideClickHandler>
                )} */}
              </div>
            </div>

            <div className="w-full flex justify-between gap-3 mt-5">
              <div
                onClick={onCancel}
                className="cursor-pointer w-[50%] h-[30px] rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-inputFieldTextColor border-borderColor border-[1px]"
              >
                CANCEL
              </div>

              <div
                onClick={() => onSaveClicked(startDate, targetDate)}
                className="cursor-pointer w-[50%] h-[30px] bg-blueColor rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-white"
              >
                SAVE
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
};

export default ScheduleModal;
