function CancelButton({ onClicked }) {
  return (
    <div
      onClick={onClicked}
      className="cursor-pointer w-[98px] h-[30px] border-[#E6E7EC] border-[1px] rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-inputEmailTitle"
    >
      CANCEL
    </div>
  );
}

export default CancelButton;
