import { getImageUrl } from "src/Common/common";
function DownloadButton({ onClicked, title = "Download" }) {
  return (
    <div
      onClick={onClicked}
      className="border-borderColor rounded-[4px] border-[1px] flex items-center justify-between gap-2 px-[10px] h-[24px] cursor-pointer"
    >
      <img src={getImageUrl("/images/common/download_black.png")} className="w-[10px]" />
      <span className="text-[#67737E] font-OpenSansRegular text-[12px] font-[600]">{title}</span>
    </div>
  );
}

export default DownloadButton;
