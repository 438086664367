const DenyRequestButton = ({ onClicked }) => {
  return (
    <div
      onClick={onClicked}
      className="cursor-pointer w-[180px] h-[30px] rounded-[6px] flex items-center justify-center font-OpenSansRegular font-[600] text-[10px] text-titleColor border-solid border-borderColor border-[1px]"
    >
      DENY REQUEST
    </div>
  );
};

export default DenyRequestButton;
