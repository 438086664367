import React from "react";

const BranchButton = ({ title, clicked, index, selected }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div
      onClick={() => clicked(index)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="shadow-md bg-white rounded-[17px] px-3 py-[5px] cursor-pointer"
      style={{ backgroundColor: isHovered ? "#ECF4FF" : selected ? "#0065FD" : "white" }}
    >
      <span className={`font-OpenSansMedium text-[14px] ${selected ? "text-white" : "text-titleColor"}`}>{title}</span>
    </div>
  );
};

export default BranchButton;
