import { useEffect, useState } from "react";
import { formatDate, getAvatarUrl } from "src/Common/common";

const History = ({ historyData }) => {
  const [historyArray, setHistoryArray] = useState([]);

  useEffect(() => {
    setHistoryArray(historyData);
    console.log("🚀 ~ useEffect ~ historyData:", historyData);
  }, [historyData]);

  return (
    <table className="w-full">
      <tbody>
        {historyArray.map((history, index) => (
          <tr key={index}>
            <td className="w-[30%]">
              <div className="font-OpenSansRegular px-[5px] py-[5px] text-left text-[12px] font-[400] col-span-1 text-titleColor">
                {formatDate(history.createdAt)}
              </div>
            </td>
            <td className="w-[30%] ">
              {history.user && (
                <div className="w-full flex items-center">
                  <img src={getAvatarUrl(history.user.avatar)} className="w-[20px] rounded-[10px]" />
                  <div className="font-OpenSansRegular px-[5px] py-[5px] text-left text-[12px] font-[400] col-span-1 text-titleColor">
                    {history.user.name}
                  </div>
                </div>
              )}
            </td>
            <td className="w-[40%]">
              <div className="font-OpenSansRegular px-[5px] py-[5px] text-left text-[12px] font-[400] col-span-1 text-titleColor ">
                {history.description}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default History;
