import React, { useState, useEffect, useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";

import { getAvatarUrl } from "src/Common/common";

const WODropdown = ({ options, selectedWO, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [woArray, setWoArray] = useState(options);

  useEffect(() => {
    setWoArray(options);
  }, [options]);

  const handleWOClick = (number) => {
    setIsOpen(false);
    onSelect(number);
  };

  const keywordChanged = (value) => {
    setKeyword(value);
    if (value.length === 0) {
      setWoArray(options);
    } else {
      setWoArray(options.filter((option) => option.includes(value)));
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsOpen(false);
      }}
    >
      <div className="relative w-full">
        <div
          onClick={(e) => {
            setIsOpen(true);
          }}
          className="w-full flex items-center h-[30px] justify-between px-4 py-2 border rounded-[6px] text-gray-800 focus:outline-none focus:border-blue-500 cursor-pointer"
        >
          <div>
            {selectedWO ? (
              <div className=" py-2 cursor-pointer flex gap-3 items-center">
                <span className="font-OpenSansRegular text-[12px]">{selectedWO}</span>
              </div>
            ) : (
              <span className="font-OpenSansRegular text-[12px]">Select</span>
            )}
          </div>
          <FiChevronDown className="ml-2" size={15} />
        </div>
        {isOpen && (
          <ul className="absolute z-10 mt-2 w-full bg-white border rounded-md shadow-lg max-h-[300px] overflow-y-auto">
            <li className="px-4 py-1 cursor-pointer flex gap-3 items-center border-b-[1px] border-borderColor">
              <input
                value={keyword}
                onChange={(e) => keywordChanged(e.target.value)}
                className="w-full h-[30px] font-OpenSansRegular text-[12px]"
                placeholder="Search WO #"
              />
            </li>
            {woArray.map((option, index) => (
              <li
                key={index}
                onClick={() => handleWOClick(option)}
                className="px-4 py-1 cursor-pointer hover:bg-gray-200 flex gap-3 items-center"
              >
                <span className="font-OpenSansRegular text-[11px]">{option}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default WODropdown;
