import React, { useState, useEffect } from "react";

function EmailInput({ onEmailChanged }) {
  const [email, setEmail] = useState("");

  useEffect(() => {}, []);

  const emailChanged = (e) => {
    onEmailChanged(e.target.value);
    setEmail(e.target.value);
  };

  return (
    <div className="w-[296px] h-[64px]">
      <p className=" text-[12px] font-OpenSansMedium font-[600] text-inputEmailTitle">Email</p>
      <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
        <input
          value={email}
          type="email"
          onChange={emailChanged}
          className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
          placeholder="Enter email"
        />
      </div>
    </div>
  );
}

export default EmailInput;
