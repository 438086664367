import "./App.css";
import { Route, BrowserRouter, Routes, useNavigate, Navigate, Notifications } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OneSignal from "react-onesignal";

import Signin from "src/Pages/Authentication/Signin";
import Signup from "src/Pages/Authentication/Signup";
import ForgotPassword from "src/Pages/Authentication/ForgotPassword";
import ResetPassword from "src/Pages/Authentication/ResetPassword";
// import Main from "./Pages/main";
import WO from "./Pages/WO";
import PO from "./Pages/PO";
import SideMenu from "./Layout/sidemenu";
import Header from "./Layout/header";
import Setting from "./Pages/Setting/tabbar";
import { useEffect } from "react";
import Customer from "./Pages/Customer";
import WODetail from "./Pages/WO/detail";
import PODetail from "./Pages/PO/detail";
import ServiceTicket from "./Pages/ServiceTicket/serviceticket";
import UserService from "./Services/API/user.service";
import Recommendations from "./Pages/Recommendations/recommendations";
import TOS from "./Pages/TOS/tos";
import Privacy from "./Pages/TOS/privacy";
import SetPassword from "./Pages/Authentication/SetPassword";
import LayoutWithTopBar from "./Layout/layoutwithtopbar";
import TabBar from "./Pages/Setting/tabbar";
import Account from "./Pages/Setting/Account";
import Team from "./Pages/Setting/Team/team";
import NotificationCenter from "./Pages/Setting/Notification/notificationcenter";
import Company from "./Pages/Setting/Company/company";
import Vendor from "./Pages/Setting/Vendor/vendor";
import ReportCenter from "./Pages/Setting/Report/reportcenter";
import SettingsLayout from "./Layout/settingslayout";
import Branch from "./Pages/Setting/Branch/branch";

function App() {
  const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
  const woNumber = useSelector((state) => state.Header.woNumber);
  const poNumber = useSelector((state) => state.Header.poNumber);
  const PrivateRoute = ({ auth: isAuthenticated, children }) => {
    return isAuthenticated ? children : <Navigate to="/" />;
  };
  // function PrivateRoute({ auth: isAuthenticated, children }) {
  //   return isAuthenticated ? children : <Navigate to="/" />;
  // }

  // try {
  //   OneSignal.init({
  //     appId: "6421c1e6-f5ed-4b26-8df7-dca0d80bf80f",
  //   }).then(() => {
  // OneSignal.Debug.setLogLevel("warn");
  //     OneSignal.login("rodrigo");
  //     OneSignal.User.addAlias("myAlias", "1");
  //     console.log("subscription ID: ", OneSignal.User.PushSubscription.id);
  //   });
  // } catch (e) {
  //   console.log(e);
  // }

  useEffect(() => {
    if (isAuthenticated) {
      // runOneSignal();
      const user = UserService.getCurrentUser();
      OneSignal.login(user.email);
      console.log("subscription_id: ", OneSignal.User.PushSubscription.id, user.email);
    } else {
      OneSignal.logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // console.log("Notification.permission: ", Notification.permission);
    // if (Notification.permission !== "granted") {
    //   Notification.requestPermission().then((permission) => {
    //     console.log("Permission: ", permission);
    //   });
    // }
    runOneSignal();
  }, []);

  async function runOneSignal() {
    const appID =
      process.env.REACT_APP_ENV === "production"
        ? "7b554f0c-68e1-4701-97cb-9d46554611e7"
        : process.env.REACT_APP_ENV === "staging"
        ? "78cf09be-f363-4294-838d-695eb71bdee6"
        : "6421c1e6-f5ed-4b26-8df7-dca0d80bf80f";
    // OneSignal.Debug.setLogLevel("trace");
    await OneSignal.init({ appId: appID });
    OneSignal.User.PushSubscription.addEventListener("change", pushSubscriptionChangeListener);
  }

  function pushSubscriptionChangeListener(event) {
    if (event.current.token) {
      console.log("The push subscription has received a token! ", OneSignal.User.PushSubscription.id);
      const user = UserService.getCurrentUser();
      // OneSignal.User.addAlias("Email", user.email);
      if (user) {
        OneSignal.login(user.email);
        console.log(
          "The push subscription has received a token! subscription_id: ",
          OneSignal.User.PushSubscription.id,
          user.email
        );
      }
    }
  }

  // const isSupported = OneSignal.Notifications.isPushSupported();
  // OneSignal.Slidedown.promptPush();
  // console.log("subscription ID: ", OneSignal.User.PushSubscription.id);
  // OneSignal.login("rodeli6607@armablog.com");

  // OneSignal.Notifications.addEventListener("click", (event) => {
  //   console.log("OneSignal: notification clicked:", event);
  // });

  // OneSignal.Notifications.addEventListener("foregroundWillDisplay", (event) => {
  //   console.log("OneSignal: notification foregroundWillDisplay:", event);
  // });

  // OneSignal.Slidedown.promptPush();

  return (
    <div className="flex bg-red">
      <ToastContainer />
      <Routes>
        {/* <Route path="/" element={() => null} /> */}
        <Route path="/work" element={<SideMenu />} />
        <Route path="/work/:number" element={<SideMenu />} />
        <Route path="/purchase" element={<SideMenu />} />
        <Route path="/purchase/:number" element={<SideMenu />} />
        <Route path="/recommendations" element={<SideMenu />} />
        <Route path="/customer" element={<SideMenu />} />
        <Route path="/serviceticket" element={<SideMenu />} />
        <Route path="/settings/account" element={<SideMenu />} />
        <Route path="/settings/team" element={<SideMenu />} />
        <Route path="/settings/notification" element={<SideMenu />} />
        <Route path="/settings/company" element={<SideMenu />} />
        <Route path="/settings/vendor" element={<SideMenu />} />
        <Route path="/settings/report" element={<SideMenu />} />
        <Route path="/settings/branch" element={<SideMenu />} />
      </Routes>

      <div className="contentWidth">
        {/* <>
          <Routes>
            <Route
              path="/work"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <Header title="Work Orders" />
                </PrivateRoute>
              }
            />
            <Route
              path="/work/:number"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <Header title={"Work Order #" + woNumber} />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <Header title="Purchase Orders" />
                </PrivateRoute>
              }
            />
            <Route
              path="/purchase/:number"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <Header title={"Purchase Order #" + poNumber} />
                </PrivateRoute>
              }
            />
            <Route
              path="/recommendations"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <Header title="Recommendations" />
                </PrivateRoute>
              }
            />
            <Route
              path="/setting"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <Header title="Settings" />
                </PrivateRoute>
              }
            />

            <Route
              path="/customer"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <Header title="Customers" />
                </PrivateRoute>
              }
            />

            <Route
              path="/serviceticket"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <Header title="Service Ticket" />
                </PrivateRoute>
              }
            />
          </Routes>
        </> */}
        <>
          <Routes>
            <Route path="/" element={<Signin />} />
            {/* <Route path="/signup" element={<Signup />} /> */}
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword/:token" element={<ResetPassword />} />
            <Route path="/invite" element={<SetPassword />} />
            <Route element={<LayoutWithTopBar />}>
              <Route
                path="/work"
                element={
                  <PrivateRoute auth={isAuthenticated}>
                    <WO />
                  </PrivateRoute>
                }
              />
              <Route
                path="/work/:number"
                element={
                  <PrivateRoute auth={isAuthenticated}>
                    <WODetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/purchase"
                element={
                  <PrivateRoute auth={isAuthenticated}>
                    <PO />
                  </PrivateRoute>
                }
              />
              <Route
                path="/purchase/:number"
                element={
                  <PrivateRoute auth={isAuthenticated}>
                    <PODetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/recommendations"
                element={
                  <PrivateRoute auth={isAuthenticated}>
                    <Recommendations />
                  </PrivateRoute>
                }
              />

              <Route
                path="/customer"
                element={
                  <PrivateRoute auth={isAuthenticated}>
                    <Customer />
                  </PrivateRoute>
                }
              />

              <Route
                path="/serviceticket"
                element={
                  <PrivateRoute auth={isAuthenticated}>
                    <ServiceTicket />
                  </PrivateRoute>
                }
              />

              <Route element={<SettingsLayout />}>
                <Route
                  path="/settings/account"
                  element={
                    <PrivateRoute auth={isAuthenticated}>
                      <Account />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings/team"
                  element={
                    <PrivateRoute auth={isAuthenticated}>
                      <Team />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings/notification"
                  element={
                    <PrivateRoute auth={isAuthenticated}>
                      <NotificationCenter />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings/company"
                  element={
                    <PrivateRoute auth={isAuthenticated}>
                      <Company />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings/vendor"
                  element={
                    <PrivateRoute auth={isAuthenticated}>
                      <Vendor />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings/report"
                  element={
                    <PrivateRoute auth={isAuthenticated}>
                      <ReportCenter />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/settings/branch"
                  element={
                    <PrivateRoute auth={isAuthenticated}>
                      <Branch />
                    </PrivateRoute>
                  }
                />
              </Route>
            </Route>

            <Route path="/terms" element={<TOS />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </>
      </div>
    </div>
  );
}

export default App;
