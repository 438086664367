import React, { useState, useEffect } from "react";

function ConfirmPasswordInput({ onConfirmpasswordChanged }) {
  const [password, setPassword] = useState("");

  useEffect(() => {}, []);

  const passwordChanged = (e) => {
    onConfirmpasswordChanged(e.target.value);
    setPassword(e.target.value);
  };

  const onForgotClicked = () => {};

  return (
    <div className="w-[296px] h-[64px] relative">
      <p className=" text-[12px] font-OpenSansMedium font-[600] text-inputEmailTitle">Confirm Password</p>
      <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
        <input
          value={password}
          type="password"
          onChange={passwordChanged}
          className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
          placeholder="Enter password"
        />
      </div>
    </div>
  );
}

export default ConfirmPasswordInput;
