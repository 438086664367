import { useEffect, useState } from "react";
import AddModal from "./addmodal";
import { toast } from "react-toastify";
import UserService from "src/Services/API/user.service";
import BranchService from "src/Services/API/branch.service";
import BranchTable from "./branchtable";

function Branch() {
  const currentUser = UserService.getCurrentUser();
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [branches, setBranches] = useState([]);
  const [editingBranch, setEditingBranch] = useState(null);

  useEffect(() => {
    getBranches();
  }, []);

  const getBranches = async () => {
    try {
      const response = await BranchService.getAll();
      if (response.status == 200) {
        let tempArray = [];
        for (let item of response.data) {
          const date = new Date(item.createdAt);
          item.formattedCreatedAt = date.toLocaleDateString("en-GB");
          tempArray.push(item);
        }
        setBranches(tempArray);
      } else {
        toast("Something went wrong!");
      }
    } catch (error) {
      console.log("error: ", error);
      toast(error.response.data.message);
    }
  };

  const addButtonClicked = () => {
    setIsAdd(true);
  };

  const addClicked = (data) => {
    BranchService.saveBranch(data)
      .then((response) => {
        console.log(response);
        if (response.status == 201) {
          toast("Branch saved successfully");
          getBranches();
        } else {
          toast("Something went wrong");
        }
      })
      .catch((error) => {
        console.log("Branch save error: ", error);
        toast("An error occured");
      });
    setIsAdd(false);
  };

  const deleteBranch = (index) => {
    BranchService.deleteBranch(branches[index].id)
      .then((response) => {
        console.log(response);
        if (response.status == 201 && response.data == true) {
          toast("Branch deleted successfully");
          getBranches();
        } else {
          toast("Something went wrong");
        }
      })
      .catch((error) => {
        console.log("Branch save error: ", error);
        toast(error.response.data.message);
      });
  };

  const updateBranch = (index) => {
    setIsEdit(true);
    setEditingBranch(branches[index]);
  };

  const updateClicked = (branch) => {
    BranchService.updateBranch(branch)
      .then((response) => {
        console.log(response);
        if (response.status == 201) {
          toast("Branch updated successfully");
          getBranches();
        } else {
          toast("Something went wrong");
        }
      })
      .catch((error) => {
        console.log("Branch save error: ", error);
        toast(error.response.data.message);
      });
    setIsEdit(false);
  };

  return (
    <div className="w-full h-full bg-red px-3">
      {isAdd && <AddModal onAddClicked={addClicked} onCancelClicked={() => setIsAdd(false)} branch={null} />}
      {isEdit && (
        <AddModal onUpateClicked={updateClicked} onCancelClicked={() => setIsEdit(false)} branch={editingBranch} />
      )}
      <div className="flex items-center justify-end h-[62px] border-b-[1px] border-borderColor">
        <div onClick={addButtonClicked} className="w-[92px] h-[30px] flex items-center justify-center cursor-pointer">
          <span className="text-[12px] font-[600] font-OpenSansRegular text-subTitleColor">+ Branch</span>
        </div>
      </div>
      <BranchTable onDeleteBranch={deleteBranch} onEditClicked={updateBranch} branches={branches} />
    </div>
  );
}

export default Branch;
