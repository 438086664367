import { current } from "@reduxjs/toolkit";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import StatusDropdown from "./components/statusdropdown";
import UserSelectModal from "../Notification/components/userselectmodal";
import UserService from "src/Services/API/user.service";
import { handleError, getUserNameArray, limitString } from "src/Common/function";
import { formatTime } from "src/Common/common";
import NotificationService from "src/Services/API/notification.service";
import Technicians from "./components/technicians";
import DayOfWeekDropdown from "./components/dayofweekdropdown";
import TimePickerModal from "src/Common/timepickermodal";
import DayDropdown from "./components/daydropdown";
import OpenWorkOrders from "./openworkorders";

const ReportCenter = () => {
  const [userArray, setUserArray] = useState([]);
  const [userSelectModalShown, setUserSelectModalShown] = useState(false);
  const [timePickerModalShown, setTimePickerModalShown] = useState(false);

  const [type, setType] = useState(0);
  // 0: Open WO
  // 1: Sent WO
  // 2: Submitted WO
  // 3: Completed PO
  // 4: Incompleted PO
  // const [subType, setSubType] = useState(0);
  // 0: Past 7 dyas
  // 1: Past 30 days

  const [past7DaysForOpenWO, setPast7DaysForOpenWO] = useState(false);
  const [dayOfWeekForOpenWO, setDayOfWeekForOpenWO] = useState("Monday");
  const [timeForDayOfWeekForOpenWO, setTimeForDayOfWeekForOpenWO] = useState(new Date());

  const [past30DaysForOpenWO, setPast30DaysForOpenWO] = useState(false);
  const [dayForOpenWO, setDayForOpenWO] = useState(1);
  const [timeForDayForOpenWO, setTimeForDayForOpenWO] = useState(new Date());

  const [openWORecipientIdList, setOpenWORecipientIdList] = useState([]);

  useEffect(() => {
    UserService.getAllUsers()
      .then((response) => {
        if (response.status == 200) {
          setUserArray(response.data);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  return (
    <div className="w-full">
      <OpenWorkOrders
        userArray={userArray}
        recipientIdList={openWORecipientIdList}
        setRecipientIdList={setOpenWORecipientIdList}
        past7Days={past7DaysForOpenWO}
        setPast7Days={setPast7DaysForOpenWO}
        dayOfWeek={dayOfWeekForOpenWO}
        setDayOfWeek={setDayOfWeekForOpenWO}
        timeForDayOfWeek={timeForDayOfWeekForOpenWO}
        setTimeForDayOfWeek={setTimeForDayOfWeekForOpenWO}
        past30Days={past30DaysForOpenWO}
        setPast30Days={setPast30DaysForOpenWO}
        day={dayForOpenWO}
        setDay={setDayForOpenWO}
        timeForDay={timeForDayForOpenWO}
        setTimeForDay={setTimeForDayForOpenWO}
      />
    </div>
  );
};

export default ReportCenter;
