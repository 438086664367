import { current } from "@reduxjs/toolkit";
import { isAdmin } from "src/Common/function";
import api from "./api";

const getAll = (branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branch.id;
  }
  return api.get("/api/user/getAll", { params: { branchId } });
};

const getAllUsers = (branch, roleName) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branch.id;
  }
  let params = { branchId };
  if (roleName) {
    params["role"] = roleName;
  }
  return api.get("/api/user/getAll", {
    params: params,
  });
};

const deleteUser = (id) => {
  return api.post("/api/user/delete", { userId: id });
};

const updateUsers = (userData) => {
  return api.post("/api/user/update_users", { user_data: userData });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const saveCurrentUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const updateUser = (user) => {
  return api.post("/api/user/update_user", { user });
};

const uploadAvatar = (file, id) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("file", file);

  return api.post("/api/user/file", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

const getUsers = (ids) => {
  return api.post("/api/user/get_users", { ids });
};
const getUsersByRole = (role, branch) => {
  return api.get("/api/user/getusersbyrole", { params: { role, branchId: branch.id } });
};

const UserService = {
  getAll,
  getAllUsers,
  deleteUser,
  updateUsers,
  getCurrentUser,
  uploadAvatar,
  saveCurrentUser,
  updateUser,
  getUsers,
  getUsersByRole,
};

export default UserService;
