import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserTable from "./Team/Manage/usertable";
import Account from "./Account";
import NotificationCenter from "./Notification/notificationcenter";
import UserService from "src/Services/API/user.service";
import Company from "./Company/company";
import Vendor from "./Vendor/vendor";
import ReportCenter from "./Report/reportcenter";
import Team from "./Team/team";

const tabUrlArray = [
  "/settings/account",
  "/settings/team",
  "/settings/notification",
  "/settings/company",
  "/settings/vendor",
  "/settings/report",
  "/settings/branch",
];

function TabBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [tabColorArray, setTabColorArray] = useState([
    "text-blueColor",
    "text-subTitleColor",
    "text-subTitleColor",
    "text-subTitleColor",
    "text-subTitleColor",
    "text-subTitleColor",
    "text-subTitleColor",
  ]);

  const [tabIndex, setTabIndex] = useState(0);
  const currentUser = UserService.getCurrentUser();

  const tabClicked = (index) => {
    let temp = [...tabColorArray];
    for (let i = 0; i < temp.length; i++) {
      temp[i] = "text-subTitleColor";
    }
    temp[index] = "text-blueColor";
    setTabColorArray(temp);
    setTabIndex(index);
    //navigate
    navigate(tabUrlArray[index]);
  };

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ location.pathname:", location.pathname, typeof location.pathname);
    if (location.pathname === "/settings/account") {
      tabClicked(0);
    } else if (location.pathname === "/settings/team") {
      tabClicked(1);
    } else if (location.pathname === "/settings/notification") {
      tabClicked(2);
    } else if (location.pathname === "/settings/company") {
      tabClicked(3);
    } else if (location.pathname === "/settings/vendor") {
      tabClicked(4);
    } else if (location.pathname === "/settings/report") {
      tabClicked(5);
    } else if (location.pathname === "/settings/branch") {
      tabClicked(6);
    }
  }, [location.pathname]);

  return (
    <div className="px-3">
      <div className="h-[60px] w-full border-b-[1px] border-borderColor flex flex-col justify-end pb-2 px-1 mb-4">
        <div className="flex items-center  gap-8 w-full ">
          <span
            onClick={() => tabClicked(0)}
            className={`cursor-pointer font-OpenSansRegular text-[12px] font-[600]  ${tabColorArray[0]}`}
          >
            Account
          </span>
          {currentUser.roles[0].id < 3 && (
            <>
              <span
                onClick={() => tabClicked(1)}
                className={`cursor-pointer font-OpenSansRegular text-[12px] font-[600]  ${tabColorArray[1]}`}
              >
                Team
              </span>
              <span
                onClick={() => tabClicked(2)}
                className={`cursor-pointer font-OpenSansRegular text-[12px] font-[600]  ${tabColorArray[2]}`}
              >
                Notification Center
              </span>
              <span
                onClick={() => tabClicked(3)}
                className={`cursor-pointer font-OpenSansRegular text-[12px] font-[600]  ${tabColorArray[3]}`}
              >
                Company
              </span>
              <span
                onClick={() => tabClicked(4)}
                className={`cursor-pointer font-OpenSansRegular text-[12px] font-[600]  ${tabColorArray[4]}`}
              >
                Vendor
              </span>
              <span
                onClick={() => tabClicked(5)}
                className={`cursor-pointer font-OpenSansRegular text-[12px] font-[600]  ${tabColorArray[5]}`}
              >
                Report Center
              </span>
              <span
                onClick={() => tabClicked(6)}
                className={`cursor-pointer font-OpenSansRegular text-[12px] font-[600]  ${tabColorArray[6]}`}
              >
                Branch
              </span>
            </>
          )}
        </div>
      </div>
      {/* {tabIndex === 0 && <Account />}
      {tabIndex === 1 && <Team />}
      {tabIndex === 2 && <NotificationCenter />}
      {tabIndex === 3 && <Company />}
      {tabIndex === 4 && <Vendor />}
      {tabIndex === 5 && <ReportCenter />} */}
    </div>
  );
}

export default TabBar;
