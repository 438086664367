import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TypeSelect from "./components/TypeSelect";
import { WO_TYPE_LIST } from "src/Common/contants";
import CustomerDropDown from "src/Pages/WO/Components/dropdown/customerdropdown";
import CustomerService from "src/Services/API/customer.service";
import WOStatusDropDown from "src/Pages/WO/Components/dropdown/wostatusdropdown";
import ApplyButton from "./components/ApplyButton";
import ClearButton from "./components/Clearbutton";
import UserService from "src/Services/API/user.service";
import TechDropDown from "./components/TechDropdown";
import { formatDate, getImageUrl } from "src/Common/common";
import DatePickerModal from "src/Common/datepickermodal";

const WOFitlerView = ({ apply }) => {
  const currentUser = UserService.getCurrentUser();
  const branch = useSelector((state) => state.Branch.branch);
  const [type, setType] = useState(-1);
  const [customer, setCustomer] = useState(-1);
  const [customerName, setCustomerName] = useState("");
  const [status, setStatus] = useState(-1);
  const [tech, setTech] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //for fitlerview
  const [customers, setCustomers] = useState([]);
  const [customerNames, setCustomerNames] = useState([]);
  const [techArray, setTechArray] = useState([]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [isStartDate, setIsStartDate] = useState(true);

  useEffect(() => {
    clearFilter();

    CustomerService.getAll(branch)
      .then((response) => {
        if (response.status == 200) {
          setCustomers(response.data);
          setCustomerNames(response.data.map((item) => item.company));
        }
      })
      .catch((error) => {
        console.log("customer fetching error: ", error);
      });

    UserService.getUsersByRole("Technician", branch)
      .then((response) => {
        console.log("Tech response: ", response.data);
        if (response.status == 200) {
          setTechArray(response.data);
        }
      })
      .catch((error) => {
        console.log("tech fetching error: ", error);
      });
  }, [branch]);

  const customerSelected = (index) => {
    setCustomer(customers[index].id);
    setCustomerName(customers[index].company);
  };

  const applyFilter = () => {
    apply({
      type,
      customer,
      status,
      tech: tech ? tech.id : -1,
      startDate,
      endDate,
    });
  };

  const clearFilter = () => {
    setType(-1);
    setCustomer(-1);
    setCustomerName("");
    setStatus(-1);
    setTech(null);
    setStartDate(null);
    setEndDate(null);
    apply({
      type: -1,
      customer: -1,
      status: -1,
      tech: null,
      startDate: null,
      endDate: null,
    });
  };

  const techSelected = (index) => {
    setTech(techArray[index]);
  };

  return (
    <div className="w-full flex flex-wrap gap-3 py-10 relative pr-[120px]">
      <div className="absolute top-5 right-5 flex flex-col gap-3">
        <ApplyButton onClicked={applyFilter} />
        <ClearButton onClicked={clearFilter} />
      </div>
      <div className="w-[200px] flex flex-col gap-2 ">
        <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor">by Type: </p>
        <TypeSelect option={type >= 0 ? WO_TYPE_LIST[type] : "Select"} list={WO_TYPE_LIST[type]} onSelect={setType} />
      </div>
      <div className="w-[200px] flex flex-col gap-2 ">
        <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor">by Customer: </p>
        <CustomerDropDown
          options={customerNames}
          defaultOption={customer >= 0 ? customerName : "Select"}
          onSelect={customerSelected}
        />
      </div>
      <div className="w-[200px] flex flex-col gap-2 ">
        <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor">by Status: </p>
        <WOStatusDropDown defaultOption={status} onSelect={(value) => setStatus(value)} />
      </div>
      <div className="w-[200px] flex flex-col gap-2 ">
        <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor">by Technician: </p>
        <TechDropDown options={techArray} selectedTech={tech} onSelect={techSelected} />
      </div>

      <div className="w-[200px] flex flex-col gap-2 ">
        <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor">Start date: </p>
        <div
          onClick={() => {
            setDatePickerOpen(true);
            setIsStartDate(true);
          }}
          className="w-full h-[30px] border-borderColor border-[1px] rounded-[6px] flex items-center px-1 gap-1"
        >
          <img src={getImageUrl("/images/wo/date.png")} />
          {startDate ? (
            <p className="text-[12px] font-OpenSansMedium font-[400] text-titleColor">
              {formatDate(startDate, "MM/DD/YYYY")}
            </p>
          ) : (
            <p className="text-[12px] font-OpenSansMedium font-[400] text-inputFieldTextColor">Start Date</p>
          )}
        </div>
      </div>

      <div className="w-[200px] flex flex-col gap-2 ">
        <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor">End date: </p>
        <div
          onClick={() => {
            setDatePickerOpen(true);
            setIsStartDate(false);
          }}
          className="w-full h-[30px] border-borderColor border-[1px] rounded-[6px] flex items-center px-1 gap-1"
        >
          <img src={getImageUrl("/images/wo/date.png")} />
          {endDate ? (
            <p className="text-[12px] font-OpenSansMedium font-[400] text-titleColor">
              {formatDate(endDate, "MM/DD/YYYY")}
            </p>
          ) : (
            <p className="text-[12px] font-OpenSansMedium font-[400] text-inputFieldTextColor">End Date</p>
          )}
        </div>
      </div>

      {datePickerOpen && (
        <DatePickerModal
          shown={datePickerOpen}
          pSelectedDate={isStartDate ? startDate : endDate}
          cancelClicked={() => setDatePickerOpen(false)}
          dateSelected={(date) => {
            if (isStartDate) {
              setStartDate(date);
            } else {
              setEndDate(date);
            }
            setDatePickerOpen(false);
          }}
          timeVisible={false}
        />
      )}
    </div>
  );
};

export default WOFitlerView;
