import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { getImageUrl } from "src/Common/common";

function DropDown({ user, roles, roleClicked }) {
  const [isDropDownShown, setIsDropDownShown] = useState(false);
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropDownShown(false);
      }}
    >
      <div
        onClick={() => setIsDropDownShown(!isDropDownShown)}
        className="w-[197px] px-[15px] py-[5px]  col-span-1 flex items-center justify-between cursor-pointer "
      >
        <span className="font-OpenSansRegular text-[10px] font-[400]">
          {user.roles[0].name}
        </span>
        <img src={getImageUrl("/images/common/arrow-down.png")} />

        {isDropDownShown && (
          <div className="w-full absolute h-[140px] px-1 py-1 border-[#E6E7EC] border-[1px]  top-[39px] left-0 z-10 bg-white flex flex-col justify-between">
            {roles.map((role, index) => {
              return role.id === user.roles[0].id ? (
                <div
                  key={index}
                  className="w-full h-[14px] flex items-center gap-2 bg-blueColor px-2"
                >
                  <p className="font-OpenSansRegular text-[10px] text-inputEmailTitle font-[400]">
                    {role.name}
                  </p>
                </div>
              ) : (
                <div
                  onClick={() => roleClicked(user, role)}
                  key={index}
                  className="w-full h-[14px] flex items-center gap-2 px-2"
                >
                  <p className="font-OpenSansRegular text-[10px] text-inputEmailTitle font-[400]">
                    {role.name}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}

export default DropDown;
