import React, { useState, useEffect, useRef } from "react";
import { FiChevronDown } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";

const CustomerDropDown = ({ options, defaultOption, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCustomerClick = (index) => {
    setIsOpen(false);
    onSelect(index);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className="relative w-full">
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="cursor-pointer w-full flex items-center h-[30px] justify-between px-4 py-2 border rounded-[6px] text-gray-800 focus:outline-none focus:border-blue-500"
        >
          <span className="font-OpenSansRegular text-[400] text-[12px] text-titleColor truncate">{defaultOption}</span>
          <FiChevronDown className="ml-2" size={15} />
        </div>
        {isOpen && (
          <ul className="absolute z-10 mt-2 w-full bg-white border rounded-md shadow-lg max-h-[300px] overflow-y-auto">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleCustomerClick(index)}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200 font-OpenSansRegular text-[12px]"
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default CustomerDropDown;
